import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import TopMenu from '../../components/Header/TopMenu'
import { Icon } from '@iconify/react';
import { Blue, Dark, DividerColor, Green, Maroon, TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getDataDashboardApplicant, getDataRekening, listSchedule, url } from '../../components/Config/Api';
import LoginSessionModal from '../../components/Session/LoginSessionModal';
import { yellow } from '@mui/material/colors';
import LightboxModal from '../../components/Modal/LightboxModal';
import BerkasPersyaratanS1 from '../../components/Dasboard/DashboardApplicant/BerkasPersyaratanS1';
import BerkasPersyaratanS2 from '../../components/Dasboard/DashboardApplicant/BerkasPersyaratanS2';
import BerkasPersyaratanS3 from '../../components/Dasboard/DashboardApplicant/BerkasPersyaratanS3';
import moment from 'moment';
import 'moment/locale/id';
import BantuanStudiAkhir from '../../components/Dasboard/BerkasPersyaratan/BantuanStudiAkhir';
import DataBeasiswaKeluargaMiskin from '../../components/Dasboard/BerkasPersyaratan/DataBeasiswaKeluargaMiskin';
import CategoryBantuanStudiAkhir from '../../components/Dasboard/DashboardApplicant/CategoryBantuanStudiAkhir';
import CategoryKeluargaMiskin from '../../components/Dasboard/DashboardApplicant/CategoryKeluargaMiskin';
import CategoryIkatanDinas from '../../components/Dasboard/DashboardApplicant/CategoryIkatanDinas';
import CategoryBantuanStudiAkhirS2 from '../../components/Dasboard/DashboardApplicant/CategoryBantuanStudiAkhirS2';
import CategoryBantuanStudiAkhirS3 from '../../components/Dasboard/DashboardApplicant/CategoryBantuanStudiAkhirS3';
import Aktivitas from '../../components/Dasboard/DashboardApplicant/Aktivitas';
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert';
import { Spin } from 'antd';
moment.locale('id');

const DashboardApplicant = () => {

  const navigate = useNavigate()

  const [biodata, setBiodata] = useState('')
  const [dataCompletenessOrangTua, setDataCompletenessOrangTua] = useState(0)
  const [dataCompletenessPendidikan, setDataCompletenessPendidikan] = useState(0)
  const [dataCompletenessKelengkapanData, setDataCompletenessKelengkapanData] = useState(0)
  const [dataBerkasPersyaratanCompleteness, setDataBerkasPersyaratanCompleteness] = useState(0)
  const [dataIkatanDinasCompleteness, setDataIkatanDinasCompleteness] = useState(0)
  const [dataCompletenessDataRekening, setDataCompletenessDataRekening] = useState(0)
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState(null)
  const [filterLoading, setFilterLoading] = useState(false)

  const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)

  const [isHovered, setIsHovered] = useState(false);
  const [ubahDataLoading, setUbahDataLoading] = useState(false);
  const [ubahDataLoadingPendidikan, setUbahDataLoadingPendidikan] = useState(false);
  const [ubahDataLoadingRekening, setUbahDataLoadingRekening] = useState(false);

  const [dataBank, setDataBank] = useState({
    namaPemilikRek: '',
    noRek: '',
    selectedBank: ''
  });

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const [jadwal, setJadwal] = useState({
    startPendaftaran: '',
    startSeleksiBerkas: '',
    startPengumuman: '',
    endPendaftaran: '',
    endSeleksiBerkas: '',
    endPengumuman: '',
  })
  const [activeCategory, setActiveCategory] = useState([]);

  const getData = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')
    let dataPersonalInformationId = ''
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email
      const userName = JSON.parse(getDataLogin).userName

      setUserName(userName)

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {
          console.log('complete data', response.data);
          setBiodata(response.data?.data)
          setEmail(email)
          dataPersonalInformationId = response.data?.data?.personal_information_id


          //Validasi data orang tua
          var dataOrangTua =
          {
            father_name: response.data?.data?.father_name,
            father_nik: response.data?.data?.father_nik,
            mother_name: response.data?.data?.mother_name,
            mother_nik: response.data?.data?.mother_nik,
            father_occupation: response.data?.data?.father_occupation,
            mother_occupation: response.data?.data?.mother_occupation,
            father_whatsapp: response.data?.data?.father_whatsapp,
            mother_whatsapp: response.data?.data?.mother_whatsapp,
          }

          const orangTuaKeys = [
            'father_name',
            'father_nik',
            'mother_name',
            'mother_nik',
            'father_occupation',
            'mother_occupation',
            'father_whatsapp',
            'mother_whatsapp'
          ];
          const orangTuaCompleteness = calculateCompleteness(dataOrangTua, orangTuaKeys);
          setDataCompletenessOrangTua(orangTuaCompleteness);

          //Validasi data pendidikan
          var dataPendidikan =
          {
            name_of_college: response.data?.data?.name_of_college,
            faculty_name: response.data?.data?.faculty_name,
            study_program: response.data?.data?.study_program,
            education_level: response.data?.data?.education_level,
            student_id_number: response.data?.data?.student_id_number,
            student_id_number_scan: response.data?.data?.student_id_number_scan,
            college_email: response.data?.data?.college_email,
            location_of_university: response.data?.data?.location_of_university,
            college_telephone_number: response.data?.data?.college_telephone_number,
            type_study_period: response.data?.data?.type_study_period,
            current_semester: response.data?.data?.current_semester,
            entry_month_ptn: response.data?.data?.entry_month_ptn,
            entry_year_ptn: response.data?.data?.entry_year_ptn,
            max_study_period: response.data?.data?.max_study_period,
          }

          const DataPendidikanKeys = [
            'name_of_college',
            'faculty_name',
            'study_program',
            'education_level',
            'student_id_number',
            'student_id_number_scan',
            'college_email',
            'location_of_university',
            'college_telephone_number',
            'type_study_period',
            'current_semester',
            'entry_month_ptn',
            'entry_year_ptn',
            'max_study_period',
          ];
          const dataPendidikanCompleteness = calculateCompleteness(dataPendidikan, DataPendidikanKeys);
          setDataCompletenessPendidikan(dataPendidikanCompleteness);


          //Validasi data berkas persyaratan
          if (response.data?.data?.category === 'Beasiswa Berprestasi') {
            if (response.data?.data?.education_level === 'Strata 1(S1)') {
              var dataBerkasPersyaratan =
              {
                rapor_class_x: response.data?.data?.rapor_class_x,
                rapor_class_xi: response.data?.data?.rapor_class_xi,
                rapor_class_xii: response.data?.data?.rapor_class_xii,
                scan_ijazah_sma: response.data?.data?.scan_ijazah_sma,
                scan_sertifikat_1: response.data?.data?.scan_sertifikat_1,
                scan_sertifikat_2: response.data?.data?.scan_sertifikat_2,
                scan_sertifikat_3: response.data?.data?.scan_sertifikat_3,
                scan_toefl: response.data?.data?.scan_toefl,
              }
              const DataBerkasPersyaratanKeys = [
                'rapor_class_x',
                'rapor_class_xi',
                'rapor_class_xii',
                'scan_sertifikat_1',
                'scan_sertifikat_2',
                'scan_sertifikat_3',
                'scan_toefl',
              ];
              const dataBerkasPersyaratanCompleteness = calculateCompleteness(dataBerkasPersyaratan, DataBerkasPersyaratanKeys);
              setDataBerkasPersyaratanCompleteness(dataBerkasPersyaratanCompleteness);

            } else if (response.data?.data?.education_level === 'Strata 2(S2)') {
              var dataBerkasPersyaratan =
              {
                gpa_s1: response.data?.data?.gpa_s1,
                avg_s1: response.data?.data?.avg_s1,
                scan_ijazah_sma: response.data?.data?.scan_ijazah_sma,
                scan_ijazah_s1: response.data?.data?.scan_ijazah_s1,
                scan_transkip_s1: response.data?.data?.scan_transkip_s1,
                scan_sertifikat_1: response.data?.data?.scan_sertifikat_1,
                scan_sertifikat_2: response.data?.data?.scan_sertifikat_2,
                scan_sertifikat_3: response.data?.data?.scan_sertifikat_3,
                scan_toefl: response.data?.data?.scan_toefl,
              }
              const DataBerkasPersyaratanKeys = [
                'gpa_s1',
                'avg_s1',
                'scan_ijazah_s1',
                'scan_ijazah_sma',
                'scan_transkip_s1',
                'scan_sertifikat_1',
                'scan_sertifikat_2',
                'scan_sertifikat_3',
                'scan_toefl',
              ];
              const dataBerkasPersyaratanCompleteness = calculateCompleteness(dataBerkasPersyaratan, DataBerkasPersyaratanKeys);
              setDataBerkasPersyaratanCompleteness(dataBerkasPersyaratanCompleteness);

            } else if (response.data?.data?.education_level === 'Strata 3(S3)') {
              var dataBerkasPersyaratan =
              {
                gpa_s1: response.data?.data?.gpa_s1,
                avg_s1: response.data?.data?.avg_s1,
                gpa_s2: response.data?.data?.gpa_s2,
                avg_s2: response.data?.data?.avg_s2,
                scan_ijazah_sma: response.data?.data?.scan_ijazah_sma,
                scan_ijazah_s1: response.data?.data?.scan_ijazah_s1,
                scan_transkip_s1: response.data?.data?.scan_transkip_s1,
                scan_ijazah_s2: response.data?.data?.scan_ijazah_s2,
                scan_transkip_s2: response.data?.data?.scan_transkip_s2,
                scan_sertifikat_1: response.data?.data?.scan_sertifikat_1,
                scan_sertifikat_2: response.data?.data?.scan_sertifikat_2,
                scan_sertifikat_3: response.data?.data?.scan_sertifikat_3,
                scan_toefl: response.data?.data?.scan_toefl,
              }
              const DataBerkasPersyaratanKeys = [
                'gpa_s1',
                'avg_s1',
                'gpa_s2',
                'avg_s2',
                'scan_ijazah_sma',
                'scan_ijazah_s1',
                'scan_transkip_s1',
                'scan_ijazah_s2',
                'scan_transkip_s2',
                'scan_sertifikat_1',
                'scan_sertifikat_2',
                'scan_sertifikat_3',
                'scan_toefl',
              ];
              const dataBerkasPersyaratanCompleteness = calculateCompleteness(dataBerkasPersyaratan, DataBerkasPersyaratanKeys);
              setDataBerkasPersyaratanCompleteness(dataBerkasPersyaratanCompleteness);
            }
          } else if (response.data?.data?.category === 'Beasiswa Keluarga Miskin') {
            var dataBerkasPersyaratan =
            {

              scan_kis: response.data?.data?.scan_kis,
              scan_skkd: response.data?.data?.scan_skkd,
              foto_house_1: response.data?.data?.foto_house_1,
              foto_house_2: response.data?.data?.foto_house_2,
              foto_house_3: response.data?.data?.foto_house_3,
              rapor_class_x: response.data?.data?.rapor_class_x,
              rapor_class_xi: response.data?.data?.rapor_class_xi,
              rapor_class_xii: response.data?.data?.rapor_class_xii,
              scan_ijazah_sma: response.data?.data?.scan_ijazah_sma,
              scan_transkip_sma: response.data?.data?.scan_transkip_sma,
            }
            const DataBerkasPersyaratanKeys = [
              'scan_kis',
              'scan_skkd',
              'foto_house_1',
              'foto_house_2',
              'foto_house_3',
              'rapor_class_x',
              'rapor_class_xi',
              'rapor_class_xii',
              'scan_ijazah_sma',
              'scan_transkip_sma',
            ];
            const dataBerkasPersyaratanCompleteness = calculateCompleteness(dataBerkasPersyaratan, DataBerkasPersyaratanKeys);
            setDataBerkasPersyaratanCompleteness(dataBerkasPersyaratanCompleteness);
          } else if (response.data?.data?.category === 'Bantuan Studi Akhir') {
            if (response.data?.data?.education_level === 'Strata 1(S1)') {
              var dataBerkasPersyaratan =
              {
                proposal_title: response.data?.data?.proposal_title,
                link_pddikti: response.data?.data?.link_pddikti,
                research_title: response.data?.data?.research_title,
                kkn_location: response.data?.data?.kkn_location,
                student_id_number: response.data?.data?.student_id_number,
                student_id_number_scan: response.data?.data?.student_id_number_scan,
              }
              const DataBerkasPersyaratanKeys = [
                'proposal_title',
                'link_pddikti',
                'research_title',
                'kkn_location',
                'student_id_number',
                'student_id_number_scan',
              ];

              const dataBerkasPersyaratanCompleteness = calculateCompleteness(dataBerkasPersyaratan, DataBerkasPersyaratanKeys);
              setDataBerkasPersyaratanCompleteness(dataBerkasPersyaratanCompleteness);
            } else if (response.data?.data?.education_level === 'Strata 2(S2)') {
              var dataBerkasPersyaratan =
              {
                scan_krs: response.data?.data?.scan_krs,
                scan_khs: response.data?.data?.scan_khs,
                link_pddikti: response.data?.data?.link_pddikti,
                student_id_number: response.data?.data?.student_id_number,
                student_id_number_scan: response.data?.data?.student_id_number_scan,
              }
              const DataBerkasPersyaratanKeys = [
                'scan_krs',
                'scan_khs',
                'link_pddikti',
                'student_id_number',
                'student_id_number_scan',
              ];

              const dataBerkasPersyaratanCompleteness = calculateCompleteness(dataBerkasPersyaratan, DataBerkasPersyaratanKeys);
              setDataBerkasPersyaratanCompleteness(dataBerkasPersyaratanCompleteness);
            } else if (response.data?.data?.education_level === 'Strata 3(S3)') {
              var dataBerkasPersyaratan =
              {
                link_pddikti: response.data?.data?.link_pddikti,
              }
              const DataBerkasPersyaratanKeys = [
                'link_pddikti',
              ];

              const dataBerkasPersyaratanCompleteness = calculateCompleteness(dataBerkasPersyaratan, DataBerkasPersyaratanKeys);
              setDataBerkasPersyaratanCompleteness(dataBerkasPersyaratanCompleteness);
            }
          } else if (response.data?.data?.category === 'Ikatan Dinas') {
            var dataBerkasPersyaratan =
            {
              selected_majors_1: response.data?.data?.selected_majors_1,
              selected_majors_2: response.data?.data?.selected_majors_2,
              selected_majors_3: response.data?.data?.selected_majors_3,
              rapor_class_x: response.data?.data?.rapor_class_x,
              rapor_class_xi: response.data?.data?.rapor_class_xi,
              rapor_class_xii: response.data?.data?.rapor_class_xii,
              scan_ijazah_sma: response.data?.data?.scan_ijazah_sma,
            }
            const DataBerkasPersyaratanKeys = [
              'selected_majors_1',
              'selected_majors_2',
              'selected_majors_3',
              'rapor_class_x',
              'rapor_class_xi',
              'rapor_class_xii',
              'scan_ijazah_sma',
            ];
            const dataBerkasPersyaratanCompleteness = calculateCompleteness(dataBerkasPersyaratan, DataBerkasPersyaratanKeys);
            setDataBerkasPersyaratanCompleteness(dataBerkasPersyaratanCompleteness);
          }


          //Validasi kelengkapan data
          var kelengkapanData =
          {
            full_name: response.data?.data?.full_name,
            nik_ktp: response.data?.data?.nik_ktp,
            address: response.data?.data?.address || null,
            origin_district: response.data?.data?.origin_district,
            category: response.data?.data?.category,
            personal_email: response.data?.data?.personal_email,
            orangTuaCompleteness: Math.round(orangTuaCompleteness) === 100 ? 'mantap' : null,
            dataPendidikanCompleteness: Math.round(dataPendidikanCompleteness) === 100 ? 'mantap' : null,
            agreement: response.data?.data?.agreement ? 'mantap' : null,
          }

          const kelengkapanDataKeys = [
            'full_name',
            'nik_ktp',
            'address',
            'origin_district',
            'category',
            'personal_email',
            'orangTuaCompleteness',
            'dataPendidikanCompleteness',
            'agreement'
          ];
          const kelengkapanDataCompleteness = calculateCompleteness(kelengkapanData, kelengkapanDataKeys);
          setDataCompletenessKelengkapanData(kelengkapanDataCompleteness);
          setFilterLoading(false)

        }).catch((error) => {
          console.log(error.response);
          // if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
          //   ErrorAlert({
          //     message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
          //     position: 'top-center'
          //   })
          // }
          setFilterLoading(false)
        })

      // Mendapatkan data rekening
      await axios.get(`${getDataRekening}/${dataPersonalInformationId}`)
        .then((response) => {
          // console.log('response rekening', response.data?.data[0]);
          setDataBank({
            namaPemilikRek: response.data?.data[0]?.name_account,
            noRek: response.data?.data[0]?.account_number,
            selectedBank: response.data?.data[0]?.name_bank
          })

          //Validasi kelengkapan data
          var dataRekening =
          {
            namaPemilikRek: response.data?.data[0]?.name_account,
            noRek: response.data?.data[0]?.account_number,
            selectedBank: response.data?.data[0]?.name_bank
          }

          const dataRekeningKeys = [
            'namaPemilikRek',
            'noRek',
            'selectedBank',
          ];
          const dataRekeningCompleteness = calculateCompleteness(dataRekening, dataRekeningKeys);
          setDataCompletenessDataRekening(dataRekeningCompleteness);

          // console.log('dataRekeningCompleteness',dataRekeningCompleteness);
          setFilterLoading(false)

        }).catch((error) => {
          console.log('error rekening', error.response);
          if (error.response && error.response.data && error.response.data.message) {
            if (error.response.data.message === "Unauthenticated.") {
              handleLoginSession();
            } else {
              console.log('Error Message:', error.response.data.message);
            }
          } else {
            console.log('Unexpected error occurred:', error);
          }
          setFilterLoading(false)

          // setErrorMessage(error.response?.data?.message)
        })
    }
  }

  const calculateCompleteness = (data, keys) => {
    const totalDataPoints = keys.length;
    const filledDataPoints = keys.filter(key => data[key] !== null && data[key] !== undefined).length;
    const completenessPercentage = (filledDataPoints / totalDataPoints) * 100;
    return completenessPercentage.toFixed(4);
  };

  const handleLoginSession = () => {
    const loginSessionExp = localStorage.getItem('LogInfo')
    const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
    if (res) {
      setFilterLoading(false)
      setOpenLoginSessionModal(true)
      localStorage.removeItem('LogInfo')
    }

  }

  const getSchedule = async () => {
    const categories = new Set();

    await axios.get(listSchedule)
      .then((response) => {
        console.log('schedule', response.data);
        const res = response.data

        res.forEach(data => {
          if (data.category !== "Siswa Unggul Papua" && data.category === 'Bantuan Biaya Pendidikan') {
            categories.add(JSON.stringify({
              category: data.category,
              status: data.status,
              start_time_announcement: data.start_time_announcement,
              start_time_schedule: data.start_time_schedule,
              start_time_selection: data.start_time_selection,
              end_time_announcement: data.end_time_announcement,
              end_time_schedule: data.end_time_schedule,
              end_time_selection: data.end_time_selection,
            }));
          }
        });


        const data = Array.from(categories).map(item => JSON.parse(item));
        const today = new Date();

        // Filter data yang memiliki `status = true` dan `end_time_schedule` masih berlaku 
        const activeCategories = data.filter(item => {
          if (item.status === true) {
            const endTime = new Date(item.end_time_schedule);
            return endTime >= today;
          }
          return false;
        });

        // console.log('data', data);
        // console.log('activeCategories', activeCategories);

        // console.log('activeCategories length', activeCategories.length);
        setActiveCategory(activeCategories);
        setJadwal({
          startPendaftaran: activeCategories[0].start_time_schedule,
          startSeleksiBerkas: activeCategories[0].start_time_selection,
          startPengumuman: activeCategories[0].start_time_announcement,
          endPendaftaran: activeCategories[0].end_time_schedule,
          endSeleksiBerkas: activeCategories[0].end_time_selection,
          endPengumuman: activeCategories[0].end_time_announcement
        })

        setFilterLoading(false)
      }).catch((error) => {
        console.log(error.response);
        setFilterLoading(false)
      })

  }


  useEffect(() => {
    const dataLogin = localStorage.getItem('LogInfo')
    setFilterLoading(true)
    if (dataLogin) {
      handleLoginSession()
      getData()
      getSchedule()
    } else if (!dataLogin) {
      setOpenLoginSessionModal(true)
      setTimeout(() => {
        navigate('/login');
      }, 9000);
    }
  }, [])

  const handleClickGoToDataRekening = () => {
    navigate('/data-rekening')
  }

  const handleClickGoToParentData = () => {
    navigate('/orang-tua')
  }

  const handleClickGoToEducationData = () => {
    navigate('/pendidikan')
  }

  const handleCloseLoginSessionModal = () => {
    setOpenLoginSessionModal(false)
    navigate('/login');
  }

  const currentDate = new Date().getTime();


  const handleJadwalPendaftaran = () => {
    const isPendaftaranOverdue = new Date(jadwal.endPendaftaran) < currentDate;

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '10px',
          padding: '15px',
          backgroundColor: isPendaftaranOverdue ? '#FF91A6' : '#104BE5',
          width: isMobile ? '100%' : '33.3%',
          borderRight: isPendaftaranOverdue ? undefined : '2px solid rgba(16, 116, 220, 1)',
          // cursor: 'pointer',
          // borderRadius: '5px',
          // borderTopLeftRadius: '5px'
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Jakarta Sans, sans-serif',
            color: isPendaftaranOverdue ? 'black' : 'white',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            textAlign: 'left', // Set text alignment to left
          }}
        >
          Jadwal Pendaftaran
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Jakarta Sans, sans-serif',
            color: isPendaftaranOverdue ? 'black' : '#F8D700',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: isPendaftaranOverdue ? '500' : '600',
            lineHeight: '15.12px',
            textAlign: 'left', // Set text alignment to left
          }}
        >
          {
            jadwal.startPendaftaran && jadwal.endPendaftaran ?
              <>
                {moment(jadwal.startPendaftaran).format('Do MMMM YYYY HH:mm:ss')}
                - {' '}
                {moment(jadwal.endPendaftaran).format('Do MMMM YYYY HH:mm:ss')}
              </>
              :
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: 'black',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                  textAlign: 'left', // Set text alignment to left
                }}
              >
                Belum ada jadwal
              </Typography>
          }
        </Typography>
      </Box>
    )
  }

  const handleSeleksiJadwal = () => {
    const isSeleksiBerkasOverdue = new Date(jadwal.endSeleksiBerkas) < currentDate;

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '10px',
          padding: '15px',
          backgroundColor: isSeleksiBerkasOverdue ? '#FF91A6' : '#104BE5',
          width: isMobile ? '100%' : '33.3%',
          borderRight: isSeleksiBerkasOverdue ? undefined : '2px solid rgba(16, 116, 220, 1)',
          // cursor: 'pointer',
          // borderTopLeftRadius: '5px'
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Jakarta Sans, sans-serif',
            color: isSeleksiBerkasOverdue ? 'black' : 'white',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            textAlign: 'left',
          }}
        >
          Jadwal Seleksi Berkas
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Jakarta Sans, sans-serif',
            color: isSeleksiBerkasOverdue ? 'black' : '#F8D700',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: isSeleksiBerkasOverdue ? '500' : '600',
            lineHeight: '15.12px',
            textAlign: 'left', // Set text alignment to left
          }}
        >
          {
            jadwal.startPendaftaran && jadwal.endPendaftaran ?
              <>
                {moment(jadwal.startSeleksiBerkas).format('Do MMMM YYYY HH:mm:ss')} - {' '}
                {moment(jadwal.endSeleksiBerkas).format('Do MMMM YYYY HH:mm:ss')}
              </>
              :
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: 'black',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                  textAlign: 'left', // Set text alignment to left
                }}
              >
                Belum ada jadwal
              </Typography>
          }

        </Typography>
      </Box>
    )
  }

  const handlePengumuman = () => {
    const isPengumumanOverdue = new Date(jadwal.endPengumuman) < currentDate;

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '10px',
          padding: '15px',
          backgroundColor: isPengumumanOverdue ? '#FF91A6' : '#104BE5',
          width: isMobile ? '100%' : '33.3%',
          // cursor: 'pointer',
          // borderTopLeftRadius: '5px'
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Jakarta Sans, sans-serif',
            color: isPengumumanOverdue ? 'black' : 'white',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            textAlign: 'left',
          }}
        >
          Jadwal Pengumuman
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Jakarta Sans, sans-serif',
            color: isPengumumanOverdue ? 'black' : '#F8D700',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: isPengumumanOverdue ? '500' : '600',
            lineHeight: '15.12px',
            textAlign: 'left', // Set text alignment to left
          }}
        >
          {
            jadwal.startPendaftaran && jadwal.endPendaftaran ?
              <>
                {moment(jadwal.startPengumuman).format('Do MMMM YYYY HH:mm:ss')} - {' '}
                {moment(jadwal.endPengumuman).format('Do MMMM YYYY HH:mm:ss')}
              </>
              :
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: 'black',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                  textAlign: 'left', // Set text alignment to left
                }}
              >
                Belum ada jadwal
              </Typography>
          }

        </Typography>
      </Box>
    )
  }

  return (
    <>
      <TopMenu />
      <Grid
        container
        spacing={2}
        sx={{
          padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
          backgroundColor: bgColor,
          marginBottom: '60px',
          height: '100%',
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'none',
            flexDirection: 'row',
            justifyContent: isMobile || isTablet ? 'center' : 'flex-start',
            alignItems: 'center',
            margin: isMobile || isTablet ? '10px 0px' : '10px 0px 0px 0px',
          }}
        >

          <Card
            elevation={5}
            sx={{
              display: 'flex',
              flexDirection: isMobile || isTablet ? 'column' : 'row',
              justifyContent: isMobile || isTablet ? 'center' : 'flex-start',
              alignItems: 'center',
              backgroundColor: Green,
              padding: '10px',
              gap: '10px',
              maxWidth: '872px',
              maxHeight: isMobile || isTablet ? 'auto' : '44px',
              // width:'100%'
              // margin:'10px 0px'
            }}
          >
            <Icon icon="icon-park-solid:check-one" style={{ fontSize: isMobile || isTablet ? '50px' : '20px', color: 'white' }} />
            <Typography
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                color: White,
                fontSize: isMobile || isTablet ? '13px' : '14px',
                fonStyle: 'normal',
                fontWeight: '500',
                lineHeight: '22px',
                textAlign: isMobile || isTablet ? 'center' : 'unset'
              }}
            >
              Selamat, anda berhak mendapatkan beasiswa berprestasi.
              Silakan {' '}
              <span
                style={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: Blue,
                  fontSize: isMobile || isTablet ? '13px' : '14px',
                  fonStyle: 'italic',
                  fontWeight: '600',
                  lineHeight: isMobile || isTablet ? '12px' : '22px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textAlign: isMobile || isTablet ? 'center' : 'unset'
                }}
              >
                lengkapi data
              </span>
              {' '}
              agar dapat diproses ketahap selanjutnya.
            </Typography>
          </Card>
        </Grid>

        {/* Pengumuman */}
        <Grid item xs={12} md={12} mb='-10px'  >
          <Box
            sx={{
              padding: '10px 16px',
              backgroundColor: '#F8D70033',
              borderRadius: '10px',
            }}
          >
            <Typography
              sx={{
                color: '#1E222F',
                fontFamily: 'Jakarta Sans, sans-serif',
                fontSize: isMobile ? '14px' : '18px',
                fontStyle: 'normal',
                fontWeight: 'bold',
                lineHeight: '24px',
                textAlign: 'justify',
                marginBottom: '15px'
              }}
            >
              Pengumuman Penting!
            </Typography>
            <Typography
              sx={{
                color: '#1E222F',
                fontFamily: 'Jakarta Sans, sans-serif',
                fontSize: isMobile ? '14px' : '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '24px',
                textAlign: 'justify',
              }}
            >
              Silakan lengkapi data anda, agar tim administrasi dapat melakukan verifikasi berkas pendaftaran.
            </Typography>
          </Box>
        </Grid>

        {/* Jadwal */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile || isTablet ? 'center' : 'flex-start',
            alignItems: 'center',
            margin: isMobile || isTablet ? '10px 0px' : '10px 0px 0px 0px',
            width: '100%'
          }}
        >
          {activeCategory && (activeCategory.length > 0) ?
            <Card
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                // maxWidth: isMobile ? '100%' : '1300px',
                width: '100%'
                // justifyContent:'center',
                // alignItems:'center'
              }}
            >
              {handleJadwalPendaftaran()}
              {handleSeleksiJadwal()}
              {handlePengumuman()}
            </Card>
            :
            undefined
          }
        </Grid>

        {/* Card Notifikasi */}
        {/* <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            justifyContent: isMobile || isTablet ? 'center' : 'flex-end',
            alignItems: 'center',
            margin: isMobile || isTablet ? '0px' : '20px 0px 0px 0px',
          }}
        >
          <Card
            elevation={3}
            sx={{
              maxWidth: isMobile || isTablet ? '100%' : '438px',
              maxHeight: isMobile || isTablet ? '100%' : '583px',
              width: '100%',
              height: '100%',
              border: `solid 1px ${DividerColor}`,
              // padding:'16px'
              // justifyContent:'center',
              // alignItems:'center'
            }}
          >
            <Box>
              <CardNotification
                userName={userName}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            </Box>
          </Card>
        </Grid> */}

        {/* Kelengkapan Data */}
        <Grid
          item
          xs={12}
          md={4}
          // lg={}
          sx={{
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'flex-start',
            // margin: isMobile ? '10px 0px' : '10px 0px',
            // backgroundColor: 'darkblue'
          }}
        >
          <Card
            elevation={3}
            sx={{
              maxWidth: isMobile || isTablet ? 'auto' : '500px',
              maxHeight: isMobile || isTablet ? 'auto' : '583px',
              width: 'auto',
              height: isMobile || isTablet ? 'auto' : '583px',
              border: `solid 1px ${DividerColor}`,
              // backgroundColor:'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '16px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: Dark,
                  fontSize: '16px',
                  fonStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >
                Kelengkapan Data
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: Maroon,
                  fontSize: '16px',
                  fonStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >
                {
                  dataCompletenessKelengkapanData ?
                    `${Math.round(dataCompletenessKelengkapanData)}` >= 0 &&
                      `${Math.round(dataCompletenessKelengkapanData)}` <= 50 ?
                      <Typography
                        sx={{
                          fontFamily: 'Plus Jakarta Sans',
                          color: Maroon,
                          fontSize: '16px',
                          fonStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: 'normal',
                        }}>
                        {Math.round(dataCompletenessKelengkapanData)}%
                      </Typography>
                      :
                      `${Math.round(dataCompletenessKelengkapanData)}` >= 51 &&
                        `${Math.round(dataCompletenessKelengkapanData)}` <= 90 ?
                        <Typography
                          sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: yellow[800],
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                          }}>
                          {Math.round(dataCompletenessKelengkapanData)}%
                        </Typography>
                        :
                        <Typography
                          sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: Green,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                          }}
                        >
                          {Math.round(dataCompletenessKelengkapanData)}%
                        </Typography>
                    :
                    '%0'
                }
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Divider
                variant="string"
                style={{
                  backgroundColor: DividerColor,
                  borderBottomWidth: 0.5,
                  // marginTop: "2px",
                }}
              />
            </Box>
            <Box
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: TextGray,
                    fontSize: '12px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Nama
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {biodata.full_name ? biodata.full_name : '-'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: TextGray,
                    fontSize: '12px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  NIK
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {biodata.nik_ktp ? biodata.nik_ktp : '-'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: TextGray,
                    fontSize: '12px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Alamat
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {'-'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: TextGray,
                    fontSize: '12px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Domisili
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {biodata.origin_district ? biodata.origin_district : '-'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: TextGray,
                    fontSize: '12px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Kategori Bantuan
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {biodata.category ? biodata.category : '-'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: TextGray,
                    fontSize: '12px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {biodata.personal_email ? biodata.personal_email : '-'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: TextGray,
                    fontSize: '12px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Data Orang Tua
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color:
                      biodata.father_name &&
                        biodata.father_nik &&
                        biodata.mother_name &&
                        biodata.mother_nik &&
                        biodata.father_occupation &&
                        biodata.mother_occupation &&
                        biodata.father_whatsapp &
                        biodata.mother_whatsapp ?
                        Green : Maroon,
                    fontSize: '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {
                    biodata.father_name &&
                      biodata.father_nik &&
                      biodata.mother_name &&
                      biodata.mother_nik &&
                      biodata.father_occupation &&
                      biodata.mother_occupation &&
                      biodata.father_whatsapp &
                      biodata.mother_whatsapp ?
                      'Data lengkap' : 'Belum lengkap'
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: TextGray,
                    fontSize: '12px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Data Pendidikan
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color:
                      biodata.name_of_college &&
                        biodata.faculty_name &&
                        biodata.study_program &&
                        biodata.educational_level &&
                        biodata.student_id_number &&
                        biodata.student_id_number_scan &&
                        biodata.college_email &&
                        biodata.location_of_university &&
                        biodata.college_telephone_number &&
                        biodata.type_study_period &&
                        biodata.current_semester &&
                        biodata.entry_month_ptn &&
                        biodata.entry_year_ptn &&
                        biodata.max_study_period ?
                        Green : Maroon,
                    fontSize: '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {
                    biodata.name_of_college &&
                      biodata.faculty_name &&
                      biodata.study_program &&
                      biodata.educational_level &&
                      biodata.student_id_number &&
                      biodata.student_id_number_scan &&
                      biodata.college_email &&
                      biodata.location_of_university &&
                      biodata.college_telephone_number &&
                      biodata.type_study_period &&
                      biodata.current_semester &&
                      biodata.entry_month_ptn &&
                      biodata.entry_year_ptn &&
                      biodata.max_study_period ?
                      'Data lengkap' : 'Belum lengkap'
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: TextGray,
                    fontSize: '12px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Agreement
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: biodata.agreement ? Green : Maroon,
                    fontSize: '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {biodata.agreement ? 'Sudah disetujui' : 'Belum disetujui'}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile || isTablet ? '16px' : undefined,
            // backgroundColor: 'orange',
            // alignItems: 'center',
            // margin: isMobile ? '10px 0px' : '10px 0px',
          }}
        >

          {/* Data Orang Tua */}
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              // display: 'flex',
              // flexDirection: 'row',
              marginBottom: isMobile || isTablet ? undefined : '20px',
              // backgroundColor: 'orange',
              maxHeight: `${Math.round(dataCompletenessOrangTua)}` >= 100 ? 'auto' : '146px',
              height: `${Math.round(dataCompletenessOrangTua)}` >= 100 ? 'auto' : '146px',
            }}
          >
            <Card
              elevation={3}
              sx={{
                maxWidth: isMobile || isTablet ? '100%' : '500px',
                // maxHeight: isMobile || isTablet ? '100%' : '384px',
                width: '100%',
                // height: `${Math.round(dataCompletenessOrangTua)}` >= 100 ? '384px' : '146px',
                border: `solid 1px ${DividerColor}`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  padding: '16px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Data Orang Tua
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Maroon,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {
                    dataCompletenessOrangTua ?
                      `${Math.round(dataCompletenessOrangTua)}` >= 0 &&
                        `${Math.round(dataCompletenessOrangTua)}` <= 50 ?
                        <Typography
                          sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: Maroon,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                          }}>
                          {Math.round(dataCompletenessOrangTua)}%
                        </Typography>
                        :
                        `${Math.round(dataCompletenessOrangTua)}` >= 51 &&
                          `${Math.round(dataCompletenessOrangTua)}` <= 90 ?
                          <Typography
                            sx={{
                              fontFamily: 'Plus Jakarta Sans',
                              color: yellow[800],
                              fontSize: '16px',
                              fonStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                            }}>
                            {Math.round(dataCompletenessOrangTua)}%
                          </Typography>
                          :
                          <Typography
                            sx={{
                              fontFamily: 'Plus Jakarta Sans',
                              color: Green,
                              fontSize: '16px',
                              fonStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                            }}
                          >
                            {Math.round(dataCompletenessOrangTua)}%
                          </Typography>
                      :
                      '%0'
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Divider
                  variant="string"
                  style={{
                    backgroundColor: DividerColor,
                    borderBottomWidth: 0.5,
                    // marginTop: "2px",
                  }}
                />
              </Box>
              {`${Math.round(dataCompletenessOrangTua)}` >= 100 ?
                <Box
                  sx={{
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '16px',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      Nama Ibu Kandung/Tiri
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {biodata.mother_name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      NIK Ibu Kandung/Tiri
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {biodata.mother_nik}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      Nama Ayah Kandung/Tiri
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {biodata.father_name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      NIK Ayah Kandung/Tiri
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {biodata.father_nik}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '10px',
                    }}
                  >
                    <Button
                      fullWidth
                      onClick={() => {
                        setUbahDataLoading(true)
                        setTimeout(() => {
                          navigate('/orang-tua')
                          setUbahDataLoading(false)
                        }, 1500);
                      }}
                      // type='submit'
                      variant='contained'
                      size='medium'
                      sx={{
                        backgroundColor: btnBgColor,
                        textTransform: 'none',
                        color: mainColor,
                        '&:hover': {
                          color: mainColor, // Change text color to white on hover
                          backgroundColor: btnBgColor
                        },
                      }}
                      disabled={ubahDataLoading ? true : false}
                    >
                      {ubahDataLoading ?
                        <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                        :
                        'Ubah Data'
                      }
                    </Button>
                  </Box>
                </Box>
                :
                <Box
                  sx={{
                    padding: '25px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '16px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      borderRadius: '4px',
                      // backgroundColor:'orange'

                    }}
                  >
                    <Button
                      onClick={handleClickGoToParentData}
                      // variant='contained'
                      size='medium'
                      sx={{
                        fontSize: '13px',
                        fontFamily: 'Plus Jakarta Sans',
                        padding: '8px 16px',
                        backgroundColor: bgColor,
                        textTransform: 'none',
                        fontWeight: '600',
                        color: Dark,
                        '&:hover': {
                          color: mainColor, // Change text color to white on hover
                          backgroundColor: bgColor,
                        },
                      }}
                    >
                      Lengkapi Data Orang Tua
                    </Button>
                  </Box>
                </Box>

              }
            </Card>
          </Grid>

          {/* Data Pendidikan */}
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              // display: 'flex',
              // flexDirection: 'row',
              // marginBottom: '20px',
              // backgroundColor: 'orange',
              maxHeight: `${Math.round(dataCompletenessPendidikan)}` >= 100 ? 'auto' : '146px',
              height: `${Math.round(dataCompletenessPendidikan)}` >= 100 ? 'auto' : '146px',
              // marginTop: `${Math.round(dataCompletenessPendidikan)}` >= 100 ? undefined : '-120px'
            }}
          >
            <Card
              elevation={3}
              sx={{
                maxWidth: isMobile || isTablet ? '100%' : '500px',
                // maxHeight: `${Math.round(dataCompletenessPendidikan)}` >= 100 ? '384px' : '146px',
                // height: `${Math.round(dataCompletenessPendidikan)}` >= 100 ? '384px' : '146px',
                width: '100%',
                border: `solid 1px ${DividerColor}`
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  padding: '16px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Data Pendidikan
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Maroon,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {
                    dataCompletenessPendidikan ?
                      `${Math.round(dataCompletenessPendidikan)}` >= 0 &&
                        `${Math.round(dataCompletenessPendidikan)}` <= 50 ?
                        <Typography
                          sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: yellow[800],
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                          }}>
                          {Math.round(dataCompletenessPendidikan)}%
                        </Typography>
                        :
                        `${Math.round(dataCompletenessPendidikan)}` >= 51 &&
                          `${Math.round(dataCompletenessPendidikan)}` <= 90 ?
                          <Typography
                            sx={{
                              fontFamily: 'Plus Jakarta Sans',
                              color: yellow[800],
                              fontSize: '16px',
                              fonStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                            }}>
                            {Math.round(dataCompletenessPendidikan)}%
                          </Typography>
                          :
                          <Typography
                            sx={{
                              fontFamily: 'Plus Jakarta Sans',
                              color: Green,
                              fontSize: '16px',
                              fonStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                            }}
                          >
                            {Math.round(dataCompletenessPendidikan)}%
                          </Typography>
                      :
                      '%0'
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Divider
                  variant="string"
                  style={{
                    backgroundColor: DividerColor,
                    borderBottomWidth: 0.5,
                    // marginTop: "2px",
                  }}
                />
              </Box>
              {`${Math.round(dataCompletenessPendidikan)}` >= 100 ?
                <Box
                  sx={{
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '16px',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      Nama Perguruan Tinggi
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {biodata.name_of_college}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      Fakultas
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {biodata.faculty_name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      Program Studi
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {biodata.study_program}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      Jenjang Pendidikan
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {biodata.education_level}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '10px',
                    }}
                  >
                    <Button
                      fullWidth
                      onClick={() => {
                        setUbahDataLoadingPendidikan(true)
                        setTimeout(() => {
                          navigate('/pendidikan')
                          setUbahDataLoadingPendidikan(false)
                        }, 1500);
                      }}
                      // type='submit'
                      variant='contained'
                      size='medium'
                      sx={{
                        backgroundColor: btnBgColor,
                        textTransform: 'none',
                        color: mainColor,
                        '&:hover': {
                          color: mainColor, // Change text color to white on hover
                          backgroundColor: btnBgColor
                        },
                      }}
                      disabled={ubahDataLoadingPendidikan ? true : false}
                    >
                      {ubahDataLoadingPendidikan ?
                        <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                        :
                        'Ubah Data'
                      }
                    </Button>
                  </Box>
                </Box>
                :
                <Box
                  sx={{
                    padding: '25px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '16px',
                  }}
                >

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      borderRadius: '4px',
                      // backgroundColor: 'orange'
                    }}
                  >
                    <Button
                      onClick={handleClickGoToEducationData}
                      size='medium'
                      sx={{
                        fontSize: '13px',
                        fontFamily: 'Plus Jakarta Sans',
                        padding: '8px 16px',
                        backgroundColor: bgColor,
                        textTransform: 'none',
                        fontWeight: '600',
                        color: Dark,
                        '&:hover': {
                          color: mainColor, // Change text color to white on hover
                          backgroundColor: bgColor,
                        },
                      }}
                    >
                      Lengkapi Data Pendidikan
                    </Button>
                  </Box>
                </Box>
              }
            </Card>
          </Grid>

          {/* Berkas Persyaratan */}
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              // display: 'flex',
              // flexDirection: 'row',
              // backgroundColor:'orange'
              marginTop: isMobile || isTablet ? undefined : '20px',

            }}
          >
            <Card
              elevation={3}
              sx={{
                maxWidth: isMobile || isTablet ? '100%' : '500px',
                width: '100%',
                border: `solid 1px ${DividerColor}`,
                maxHeight: `${Math.round(dataBerkasPersyaratanCompleteness)}` > 0 ? 'auto' : '263px',
                height: `${Math.round(dataBerkasPersyaratanCompleteness)}` > 0 ? 'auto' : '263px',
                // overflow: isHovered ? 'auto' : 'hidden',
                // position: 'relative', // Make the position relative for absolute positioning of scrollbar
                // '&:hover': {
                //   '&::-webkit-scrollbar': {
                //     width: '4px',
                //   },
                //   '&::-webkit-scrollbar-thumb': {
                //     backgroundColor: mainColor,
                //     borderRadius: '6px',
                //   },
                //   '&::-webkit-scrollbar-track': {
                //     backgroundColor: '#f1f1f1',
                //     borderRadius: '6px',
                //   },
                // },
              }}
            // onMouseEnter={() => setIsHovered(true)}
            // onMouseLeave={() => setIsHovered(false)}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  padding: '16px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Berkas Persyaratan
                </Typography>
                {/* <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Maroon,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {
                    dataBerkasPersyaratanCompleteness ?
                      `${Math.round(dataBerkasPersyaratanCompleteness)}` >= 0 &&
                        `${Math.round(dataBerkasPersyaratanCompleteness)}` <= 50 ?
                        <Typography
                          sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: Maroon,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                          }}>
                          {Math.round(dataBerkasPersyaratanCompleteness)}%
                        </Typography>
                        :
                        `${Math.round(dataBerkasPersyaratanCompleteness)}` >= 51 &&
                          `${Math.round(dataBerkasPersyaratanCompleteness)}` <= 90 ?
                          <Typography
                            sx={{
                              fontFamily: 'Plus Jakarta Sans',
                              color: yellow[800],
                              fontSize: '16px',
                              fonStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                            }}>
                            {Math.round(dataBerkasPersyaratanCompleteness)}%
                          </Typography>
                          :
                          <Typography
                            sx={{
                              fontFamily: 'Plus Jakarta Sans',
                              color: Green,
                              fontSize: '16px',
                              fonStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                            }}
                          >
                            {Math.round(dataBerkasPersyaratanCompleteness)}%
                          </Typography>
                      :
                      '%0'
                  }
                </Typography> */}
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Divider
                  variant="string"
                  style={{
                    backgroundColor: DividerColor,
                    borderBottomWidth: 0.5,
                    // marginTop: "2px",
                  }}
                />
              </Box>
              {biodata.category === 'Beasiswa Berprestasi' ?
                biodata.education_level === 'Strata 1(S1)' ?
                  <BerkasPersyaratanS1
                    dataBerkasPersyaratanCompleteness={dataBerkasPersyaratanCompleteness}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    biodata={biodata}
                  />
                  :
                  biodata.education_level === 'Strata 2(S2)' ?
                    <BerkasPersyaratanS2
                      dataBerkasPersyaratanCompleteness={dataBerkasPersyaratanCompleteness}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      biodata={biodata}
                    />
                    :
                    biodata.education_level === 'Strata 3(S3)' ?
                      <BerkasPersyaratanS3
                        dataBerkasPersyaratanCompleteness={dataBerkasPersyaratanCompleteness}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        biodata={biodata}
                      />
                      :
                      undefined
                :
                biodata.category === 'Beasiswa Keluarga Miskin' && biodata.education_level === 'Strata 1(S1)' ?
                  <CategoryKeluargaMiskin
                    dataBerkasPersyaratanCompleteness={dataBerkasPersyaratanCompleteness}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    biodata={biodata}
                  />
                  :
                  biodata.category === 'Bantuan Biaya Pendidikan' ?
                    biodata.education_level === 'Strata 1(S1)' ?
                      <CategoryBantuanStudiAkhir
                        dataBerkasPersyaratanCompleteness={dataBerkasPersyaratanCompleteness}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        biodata={biodata}
                      />
                      :
                      biodata.education_level === 'Strata 2(S2)' ?
                        <CategoryBantuanStudiAkhirS2
                          dataBerkasPersyaratanCompleteness={dataBerkasPersyaratanCompleteness}
                          isMobile={isMobile}
                          isTablet={isTablet}
                          biodata={biodata}
                        />
                        :
                        biodata.education_level === 'Strata 3(S3)' ?
                          <CategoryBantuanStudiAkhirS3
                            dataBerkasPersyaratanCompleteness={dataBerkasPersyaratanCompleteness}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            biodata={biodata}
                          />
                          :
                          undefined
                    :
                    biodata.category === 'Ikatan Dinas' ?
                      <CategoryIkatanDinas
                        dataIkatanDinasCompleteness={dataBerkasPersyaratanCompleteness}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        biodata={biodata}
                      />
                      :
                      undefined
              }

            </Card>
          </Grid>
        </Grid>


        <Grid
          item
          xs={12}
          md={4}
          sx={{
            // display: 'flex',
            // flexDirection: 'column',
            // gap: isMobile || isTablet ? '16px' : undefined,
            // backgroundColor: 'orange',
            // alignItems: 'center',
            // margin: isMobile ? '10px 0px' : '10px 0px',
          }}
        >

          {/* Data Rekening */}
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '20px',
              height: 'auto'
              // backgroundColor: 'orange'
            }}
          >
            <Card
              elevation={3}
              sx={{
                maxWidth: isMobile || isTablet ? '100%' : '500px',
                width: '100%',
                height: 'auto',
                border: `solid 1px ${DividerColor}`,
                // backgroundColor:'orange'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  padding: '16px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Data Rekening
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Maroon,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  {
                    dataCompletenessDataRekening ?
                      `${Math.round(dataCompletenessDataRekening)}` >= 0 &&
                        `${Math.round(dataCompletenessDataRekening)}` <= 50 ?
                        <Typography
                          sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: Maroon,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                          }}>
                          {Math.round(dataCompletenessDataRekening)}%
                        </Typography>
                        :
                        `${Math.round(dataCompletenessDataRekening)}` >= 51 &&
                          `${Math.round(dataCompletenessDataRekening)}` <= 90 ?
                          <Typography
                            sx={{
                              fontFamily: 'Plus Jakarta Sans',
                              color: yellow[800],
                              fontSize: '16px',
                              fonStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                            }}>
                            {Math.round(dataCompletenessDataRekening)}%
                          </Typography>
                          :
                          <Typography
                            sx={{
                              fontFamily: 'Plus Jakarta Sans',
                              color: Green,
                              fontSize: '16px',
                              fonStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                            }}
                          >
                            {Math.round(dataCompletenessDataRekening)}%
                          </Typography>
                      :
                      '%0'
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Divider
                  variant="string"
                  style={{
                    backgroundColor: DividerColor,
                    borderBottomWidth: 0.5,
                    // marginTop: "2px",
                  }}
                />
              </Box>
              {`${Math.round(dataCompletenessDataRekening)}` >= 100 ?
                <Box
                  sx={{
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '16px',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      Nama Pemilik Rekening
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {dataBank.namaPemilikRek}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      Nama Bank
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {dataBank.selectedBank}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: TextGray,
                        fontSize: '12px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      Nomor Rekening
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: Dark,
                        fontSize: '14px',
                        fonStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {dataBank.noRek}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '10px',
                    }}
                  >
                    <Button
                      fullWidth
                      onClick={() => {
                        setUbahDataLoadingRekening(true)
                        setTimeout(() => {
                          navigate('/data-rekening')
                          setUbahDataLoadingRekening(false)
                        }, 1500);
                      }}
                      // type='submit'
                      variant='contained'
                      size='medium'
                      sx={{
                        backgroundColor: btnBgColor,
                        textTransform: 'none',
                        color: mainColor,
                        '&:hover': {
                          color: mainColor, // Change text color to white on hover
                          backgroundColor: btnBgColor
                        },
                      }}
                      disabled={ubahDataLoadingRekening ? true : false}
                    >
                      {ubahDataLoadingRekening ?
                        <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                        :
                        'Ubah Data'
                      }
                    </Button>
                  </Box>
                </Box>
                :
                <Box
                  sx={{
                    padding: '25px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '16px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      borderRadius: '4px',
                      // backgroundColor:'orange'
                    }}
                  >
                    <Button
                      onClick={handleClickGoToDataRekening}
                      // variant='contained'
                      size='medium'
                      sx={{
                        fontSize: '13px',
                        fontFamily: 'Plus Jakarta Sans',
                        padding: '8px 16px',
                        backgroundColor: bgColor,
                        textTransform: 'none',
                        fontWeight: '600',
                        color: Dark,
                        '&:hover': {
                          color: mainColor, // Change text color to white on hover
                          backgroundColor: bgColor,
                        },
                      }}
                    >
                      Lengkapi Data Rekening
                    </Button>
                  </Box>
                </Box>
              }
            </Card>
          </Grid>

          {/* Aktivitas */}
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              // display: 'flex',
              // flexDirection: 'row',
              // backgroundColor: 'orange'
            }}
          >
            <Card
              elevation={3}
              sx={{
                maxWidth: isMobile || isTablet ? '100%' : '500px',
                maxHeight: isMobile || isTablet ? '100%' : '413px',
                height: '413px',
                width: '100%',
                border: `solid 1px ${DividerColor}`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  padding: '16px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Aktivitas
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Divider
                  variant="string"
                  style={{
                    backgroundColor: DividerColor,
                    borderBottomWidth: 0.5,
                    // marginTop: "2px",
                  }}
                />
              </Box>
              <Aktivitas
                isMobile={isMobile}
                isTablet={isTablet}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid >
      {
        filterLoading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2000
            }}
          >
            <Spin size="large" />
          </Box>
        )
      }
      <LoginSessionModal
        open={openLoginSessionModal}
        close={handleCloseLoginSessionModal}
      />

    </>
  )
}

export default DashboardApplicant