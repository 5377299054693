import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TopMenu from '../../components/Header/TopMenu'
import { getDataDashboardApplicant, updateBiodata, updateDataBiodataOrangTua } from '../../components/Config/Api';
import { Blue, Dark, DividerColor, Maroon, TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color'
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert'
import DataUniversitas from '../../components/Dasboard/FormPendidikan/DataUniversitas';
import IDMahasiswa from '../../components/Dasboard/FormPendidikan/IDMahasiswa';
import MasaStudi from '../../components/Dasboard/FormPendidikan/MasaStudi';
import KontakUniversitas from '../../components/Dasboard/FormPendidikan/KontakUniversitas';
import { ToastContainer } from 'react-toastify';
import LoginSessionModal from '../../components/Session/LoginSessionModal';

const Pendidikan = () => {

  const navigate = useNavigate()

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');
  const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)
  const [biodata, setBiodata] = useState('')
  const [dataCompletenessUniversitas, setDataCompletenessUniversitas] = useState(100)
  const [dataCompletenessIDMahasiswa, setDataCompletenessIDMahasiswa] = useState(100)
  const [dataCompletenessKontak, setDataCompletenessKontak] = useState(100)
  const [dataCompletenessMasaStudi, setDataCompletenessMasaStudi] = useState(100)



  const getData = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {
          // console.log(response.data);
          setBiodata(response.data)

          // console.log('cek education level', response.data);

          //Validasi presentase kelengkapan data universitas
          var listDataUniversitas =
          {
            name_of_college: response.data?.data?.name_of_college,
            faculty_name: response.data?.data?.faculty_name,
            study_program: response.data?.data?.study_program,
            educational_level: response.data?.data?.education_level,
          }

          const dataUniversitasKeys = ['name_of_college', 'faculty_name', 'study_program', 'educational_level'];
          const dataUniversitasCompleteness = calculateCompleteness(listDataUniversitas, dataUniversitasKeys);
          setDataCompletenessUniversitas(dataUniversitasCompleteness);

          //Validasi presentase kelengkapan data ID Mahasiswa
          var listDataIDMahasiswa =
          {
            student_id_number: response?.data?.data?.student_id_number,
            student_id_number_scan: response?.data?.data?.student_id_number_scan,
          }

          const dataIDMahasiswaKeys = ['student_id_number', 'student_id_number_scan'];
          const dataIDMahasiswaCompleteness = calculateCompleteness(listDataIDMahasiswa, dataIDMahasiswaKeys);
          setDataCompletenessIDMahasiswa(dataIDMahasiswaCompleteness);

          //Validasi presentase kelengkapan data ID Mahasiswa
          var listDataKontak =
          {
            college_email: response?.data?.data?.college_email,
            location_of_university: response?.data?.data?.location_of_university,
            college_telephone_number: response?.data?.data?.college_telephone_number,
          }

          const dataKontakKeys = ['college_email', 'location_of_university', 'college_telephone_number'];
          const dataKontakCompleteness = calculateCompleteness(listDataKontak, dataKontakKeys);
          setDataCompletenessKontak(dataKontakCompleteness);


          //Validasi presentase kelengkapan data Masa Studi
          var listDataMasaStudi =
          {
            type_study_period: response?.data?.data?.type_study_period,
            current_semester: response?.data?.data?.current_semester,
            entry_month_ptn: response?.data?.data?.entry_month_ptn,
            entry_year_ptn: response?.data?.data?.entry_year_ptn,
            max_study_period: response?.data?.data?.max_study_period,
          }

          const dataMasaStudiKeys = ['type_study_period', 'current_semester', 'entry_month_ptn', 'entry_year_ptn', 'max_study_period'];
          const dataMasaStudiCompleteness = calculateCompleteness(listDataMasaStudi, dataMasaStudiKeys);
          setDataCompletenessMasaStudi(dataMasaStudiCompleteness);

        }).catch((error) => {
          console.log(error.response);
        })
    }
  }

  const handleValidasiDataUniversitas = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {
          // console.log('cek education level',response.data);

          // if(props === 'IDMahasiswa')
          var listDataUniversitas =
          {
            name_of_college: response.data?.data?.name_of_college,
            faculty_name: response.data?.data?.faculty_name,
            study_program: response.data?.data?.study_program,
            educational_level: response.data?.data?.educational_level,
          }

          const dataUniversitasKeys = ['name_of_college', 'faculty_name', 'study_program', 'educational_level'];
          const dataUniversitasCompleteness = calculateCompleteness(listDataUniversitas, dataUniversitasKeys);
          setDataCompletenessUniversitas(dataUniversitasCompleteness);

        }).catch((error) => {
          console.log(error.response);
        })
    }
  }

  const handleValidasiDataIDMahasiswa = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {
          
          //Validasi presentase kelengkapan data ID Mahasiswa
          var listDataIDMahasiswa =
          {
            student_id_number: response?.data?.data?.student_id_number,
            student_id_number_scan: response?.data?.data?.student_id_number_scan,
          }

          const dataIDMahasiswaKeys = ['student_id_number', 'student_id_number_scan'];
          const dataIDMahasiswaCompleteness = calculateCompleteness(listDataIDMahasiswa, dataIDMahasiswaKeys);
          setDataCompletenessIDMahasiswa(dataIDMahasiswaCompleteness);

        }).catch((error) => {
          console.log(error.response);
        })
    }
  }

  const handleValidasiDataKontak = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {

          //Validasi presentase kelengkapan data ID Mahasiswa
          var listDataKontak =
          {
            college_email: response?.data?.data?.college_email,
            location_of_university: response?.data?.data?.location_of_university,
            college_telephone_number: response?.data?.data?.college_telephone_number,
          }

          const dataKontakKeys = ['college_email', 'location_of_university', 'college_telephone_number'];
          const dataKontakCompleteness = calculateCompleteness(listDataKontak, dataKontakKeys);
          setDataCompletenessKontak(dataKontakCompleteness);

        }).catch((error) => {
          console.log(error.response);
        })
    }
  }

  const handleValidasiDataMasaStudi = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {

          //Validasi presentase kelengkapan data Masa Studi
          var listDataMasaStudi =
          {
            type_study_period: response?.data?.data?.type_study_period,
            current_semester: response?.data?.data?.current_semester,
            entry_month_ptn: response?.data?.data?.entry_month_ptn,
            entry_year_ptn: response?.data?.data?.entry_year_ptn,
            max_study_period: response?.data?.data?.max_study_period,
          }

          const dataMasaStudiKeys = ['type_study_period', 'current_semester', 'entry_month_ptn', 'entry_year_ptn', 'max_study_period'];
          const dataMasaStudiCompleteness = calculateCompleteness(listDataMasaStudi, dataMasaStudiKeys);
          setDataCompletenessMasaStudi(dataMasaStudiCompleteness);

        }).catch((error) => {
          console.log(error.response);
        })
    }
  }

  const calculateCompleteness = (data, keys) => {
    const totalDataPoints = keys.length;
    const filledDataPoints = keys.filter(key => data[key] !== null && data[key] !== undefined).length;
    const completenessPercentage = (filledDataPoints / totalDataPoints) * 100;
    // console.log('completenessPercentage', completenessPercentage);
    // console.log('data', data);
    return completenessPercentage.toFixed(4);
  };

  const handleLoginSession = () => {
    const loginSessionExp = localStorage.getItem('LogInfo')
    const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
    if (res) {
      setOpenLoginSessionModal(true)
      localStorage.removeItem('LogInfo')
    }

  }

  useEffect(() => {

    const dataLogin = localStorage.getItem('LogInfo')

    if (dataLogin) {
      handleLoginSession()
      getData()
    } else if (!dataLogin) {
      setOpenLoginSessionModal(true)
      setTimeout(() => {
        navigate('/login');
      }, 9000);
    }

  }, [])

  const handleClickBackToDashboard = () => {
    navigate('/dashboard-applicant')
  }

  const handleCloseLoginSessionModal = () => {
    setOpenLoginSessionModal(false)
    navigate('/login');
  }

  return (
    <>
      <TopMenu />
      <Grid
        container
        spacing={2}
        sx={{
          padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
          backgroundColor: bgColor,
          paddingBottom: '52px',
          height: '100%'
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '20px 0px 5px 0px' : '15px 0px 0px 0px',
          }}
        >
          <Box
            sx={{
              width: '100%'
            }}
          >
            <Button
              onClick={handleClickBackToDashboard}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                textTransform: 'none',
                padding: '0px',
                gap: '10px',
                // backgroundColor:'orange'
              }}
            >
              <Icon icon='ic:baseline-arrow-back' style={{ fontSize: '20px', color: mainColor }} />
              <Typography
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: mainColor,
                  fontSize: isMobile ? '13px' : '14px',
                  fonStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                Kembali ke Dashboard
              </Typography>
            </Button>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '0px 0px 10px 0px' : '10px 0px 0px 0px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Plus Jakarta Sans',
              color: Dark,
              fontSize: isMobile ? '18px' : '20px',
              fonStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Data Pendidikan
          </Typography>
        </Grid>

        {/* {
          Math.round(dataCompletenessUniversitas) &&
            Math.round(dataCompletenessIDMahasiswa) &&
            Math.round(dataCompletenessKontak) &&
            Math.round(dataCompletenessMasaStudi) >= 100 ?
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
                // alignItems: 'center',
                margin: isMobile ? '0px' : '10px 0px 0px 0px',
              }}
            >

              <Card
                elevation={5}
                sx={{
                  display: 'none',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                  alignItems: 'center',
                  backgroundColor: Maroon,
                  padding: '10px',
                  gap: '10px',
                  maxWidth: '872px',
                  maxHeight: isMobile ? 'auto' : '44px',
                  // width:'100%'
                  // margin:'10px 0px'
                }}
              >
                <Icon icon="ph:x-circle-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: White,
                    fontSize: isMobile ? '13px' : '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '22px',
                    textAlign: isMobile ? 'center' : 'unset'
                  }}
                >

                </Typography>
              </Card>
            </Grid>
            : <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
                // alignItems: 'center',
                margin: isMobile ? '0px' : '10px 0px 0px 0px',
              }}
            >

              <Card
                elevation={5}
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                  alignItems: 'center',
                  backgroundColor: Maroon,
                  padding: '10px',
                  gap: '10px',
                  maxWidth: '872px',
                  maxHeight: isMobile ? 'auto' : '44px',
                  // width:'100%'
                  // margin:'10px 0px'
                }}
              >
                <Icon icon="ph:x-circle-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: White,
                    fontSize: isMobile ? '13px' : '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '22px',
                    textAlign: isMobile ? 'center' : 'unset'
                  }}
                >
                  Data Pendidikan anda belum lengkap, segera lengkapi sebelum 1 November 2023
                </Typography>
              </Card>
            </Grid>
        } */}


        {/* Data Universitas */}
        <Grid
          item
          xs={12}
          md={4}
          // lg={}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            // margin: isMobile ? '10px 0px' : '10px 0px',
            // backgroundColor: 'darkblue'
          }}
        >
          <DataUniversitas
            biodata={biodata}
            validasiData={dataCompletenessUniversitas}
            getDataValidasi={() => handleValidasiDataUniversitas()}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile || isTablet ? '16px' : '24px',
            // backgroundColor: 'orange',
            // alignItems: 'flex-start',
            // margin: isMobile ? '10px 0px' : '10px 0px',
          }}
        >
          {/* ID Mahasiswa */}
          <Grid
            item
            xs={12}
            md={12}
            // lg={}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: isMobile ? 'center' : 'flex-start',
              // alignItems: 'center',
              // margin: isMobile ? '10px 0px' : '10px 0px',
              // backgroundColor: 'darkblue'
            }}
          >
            <IDMahasiswa
              biodata={biodata}
              validasiData={dataCompletenessIDMahasiswa}
              getDataValidasi={() => handleValidasiDataIDMahasiswa()}
            />
          </Grid>

          {/* Masa Studi */}
          <Grid
            item
            xs={12}
            md={12}
            // lg={}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: isMobile ? 'center' : 'flex-start',
              // alignItems: 'center',
              // margin: isMobile ? '10px 0px' : '10px 0px',
              // backgroundColor: 'darkblue'
            }}
          >

            <MasaStudi
              biodata={biodata}
              validasiData={dataCompletenessMasaStudi}
              getDataValidasi={() => handleValidasiDataMasaStudi()}
            />
          </Grid>
        </Grid>

        {/* Kontak Universitas*/}
        <Grid
          item
          xs={12}
          md={4}
          // lg={}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            // margin: isMobile ? '10px 0px' : '10px 0px',
            // backgroundColor: 'darkblue'
          }}
        >
          <KontakUniversitas
            biodata={biodata}
            validasiData={dataCompletenessKontak}
            getDataValidasi={() => handleValidasiDataKontak()}
          />
        </Grid>
      </Grid>
      {/* <ToastContainer style={{ width: 'auto' }} /> */}
      <LoginSessionModal
        open={openLoginSessionModal}
        close={handleCloseLoginSessionModal}
      />
    </>
  )
}

export default Pendidikan