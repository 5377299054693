import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { mainColor } from '../../Config/Color';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
const JadwalModal = ({
    open,
    close,
    isMobile,
    isTablet,
    activeCategory,
    formStatusOpen
}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? 290 : 350,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        p: 2,
    };

    return (
        <div>
            <Modal
                open={open}
            // onClose={close}
            >
                <Box sx={style}>
                    {activeCategory && (activeCategory.length > 0) ?
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}
                            >
                                <EventAvailableIcon style={{
                                    fontSize: isMobile ? '80px' : '90px',
                                    color: mainColor
                                }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: isMobile ? '16px' : '18px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                        color: mainColor
                                    }}
                                >
                                    Pendaftaran Dibuka
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: isMobile ? '14px' : '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '24px',
                                        color: 'black',
                                        textAlign: 'center',
                                        margin: '10px 0px',
                                    }}
                                >
                                    Selamat datang calon penerima bantuan pendidikan, saat ini beasiswa/bantuan pendidikan yang tersedia adalah {' '}
                                    {activeCategory && activeCategory.map((data, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: 'bold',
                                                color: mainColor
                                            }}
                                        >
                                            {data.category}
                                            {index < activeCategory.length - 2 ? ', ' :
                                                index === activeCategory.length - 2 ? ' dan ' : ''}
                                        </span>
                                    ))}.
                                    {' '}
                                    Silahkan tekan tombol dibawah ini untuk melanjutkan pendaftaran.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: '20px'
                                }}
                            >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => {
                                        formStatusOpen();
                                        close();
                                    }}
                                    style={{
                                        backgroundColor: mainColor,
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: mainColor,
                                            color: 'white'
                                        }
                                    }}
                                >
                                    Daftar Sekarang
                                </Button>
                            </Box>
                        </>
                        :
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px'
                            }}
                        >
                            <EventBusyIcon style={{
                                fontSize: isMobile ? '80px' : '90px',
                                color: 'maroon'
                            }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '16px' : '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                    color: 'maroon'
                                }}
                            >
                                Pendaftaran Ditutup
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '14px' : '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                    color: 'black',
                                    textAlign: 'center',
                                    margin: '10px 0px',
                                }}
                            >
                                Maaf Untuk saat ini belum ada pendaftaran beasiswa/bantuan pendidikan yang dibuka.
                            </Typography>
                        </Box>
                    }
                </Box>
            </Modal>
        </div >
    );
}

export default JadwalModal