import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextareaAutosize, TextField, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { bgColor, DividerColor, mainColor, TextGray } from '../../Config/Color';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { updateAgreement, updateDataPersyaratan, url } from '../../Config/Api';
import axios from 'axios';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import { red } from '@mui/material/colors';



const UbahDataAgreement = ({
    open,
    close,
    biodata,
    getData,
    completeData
}) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isMobile ? '320px' : '400px',
        width: '100%',
        maxHeight: '90vh', // Membatasi tinggi modal maksimal 90% dari viewport height
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        gap: '5px',
        p: 2,
        overflowY: 'auto', // Membuat modal bisa di-scroll

        /* Custom scrollbar styling */
        '&::-webkit-scrollbar': {
            width: 'none', // Lebar scroll bar
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1', // Warna track scroll (background scroll)
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#fff', // Warna scroll bar
            borderRadius: '10px', // Sudut scroll bar
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#fff', // Warna scroll saat hover
        },
        scrollbarWidth: 'thin', // Untuk Firefox (atur lebar scroll)
        scrollbarColor: `${'#fff'} #f1f1f1`, // Warna scroll dan track untuk Firefox
    };

    const minCharactersAnswer1 = 100;
    const minCharactersAnswer2 = 100;

    const [answer1, setAnswer1] = useState('');
    const [answer2, setAnswer2] = useState('');

    const [selectedImageTTD, setSelectedImageTTD] = useState(null);
    const [imageUrlTTD, setImageUrlTTD] = useState(null);
    const [KTPError, setKTPError] = useState('');

    const [isDragging, setIsDragging] = useState({
        isDraggingTTD: false,
    });

    const fileInputRefTTD = useRef(null);
    const MAX_IMAGE_SIZE_MB = 1;


    const [loadingUbahData, setLoadingUbahData] = useState(false)

    // var data = localStorage.getItem('data')

    useEffect(() => {
        if (open) {
            setAnswer1(completeData?.answer_1)
            setAnswer2(completeData?.answer_2)
            // setSelectedImageTTD(completeData?.signature)
            // setImageUrlTTD(`${url}/${completeData?.signature}`)
        }
    }, [open])


    const handleTextareaChange = (event) => {
        setAnswer1(event.target.value);
    };

    const handleTextareaChange2 = (event) => {
        setAnswer2(event.target.value);
    };


    const handleClearFormData = () => {
        setAnswer1('');
        setAnswer2('');

        setSelectedImageTTD(null);

        setImageUrlTTD(null);
    }
    const handleImageUploadTTD = () => {
        fileInputRefTTD.current.click();
    };

    const handleRemoveImageTTD = () => {
        setSelectedImageTTD(null);
        setImageUrlTTD(null);
    };


    const handleFileInputChangeTTD = (file) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    setSelectedImageTTD(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlTTD(imageUrl);

                } else if (allowedPdfTypes.includes(fileType)) {
                    setSelectedImageTTD(file)
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlTTD(imageUrl);
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
        fileInputRefTTD.current.value = null;
    };

    const handleShowPDF = (fileName) => {

        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }

    const handleDragOverTTD = (event) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        setIsDragging({
            isDraggingTTD: true
        })
    };

    const handleDropTTD = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChangeTTD(file);
        setIsDragging({
            isDraggingTTD: false
        })
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingTTD: false,
        }) // Set state menjadi false saat dragleave
    };

    const logInfo = localStorage.getItem('LogInfo')

    const handleUbahData = async (e) => {
        e.preventDefault()
        setLoadingUbahData(true)
        if (completeData && logInfo) {
            const formData = new FormData();
            formData.append('personal_information_id', completeData.personal_information_id);
            formData.append('answer_1', answer1);
            formData.append('answer_2', answer2);
            formData.append('agreement', true);
            formData.append('signature', selectedImageTTD);

            // for (const pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }

            const { Id, token } = JSON.parse(logInfo);

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            if (answer1.length >= minCharactersAnswer1 && answer2.length >= minCharactersAnswer2 && selectedImageTTD) {
                await axios.post(`${updateAgreement}`, formData)
                    .then((res) => {
                        console.log('res', res.data);
                        SuccessAlert({
                            message: 'Data berhasil diperbarui',
                            position: 'top-center'
                        })
                        getData()
                        close()
                        handleClearFormData()
                        setLoadingUbahData(false)
                    }).catch((err) => {
                        console.log(err.response)
                        if (err.code === 'ERR_NETWORK' || err.message.includes('ERR_CONNECTION_REFUSED')) {
                            ErrorAlert({
                                message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                                position: 'top-center'
                            })
                        }
                        setLoadingUbahData(false)
                    })
            } else if (answer1.length < minCharactersAnswer1) {
                ErrorAlert({
                    message: 'Jawaban pertanyaan pertama minimal ' + minCharactersAnswer1 + ' kata',
                    position: 'top-center'
                })
                setLoadingUbahData(false)

            } else if (answer2.length < minCharactersAnswer2) {
                ErrorAlert({
                    message: 'Jawaban pertanyaan kedua minimal ' + minCharactersAnswer2 + ' kata',
                    position: 'top-center'
                })
                setLoadingUbahData(false)
            } else if (selectedImageTTD === null) {
                ErrorAlert({
                    message: 'Silahkan upload foto TTD Digital',
                    position: 'top-center'
                })
                setLoadingUbahData(false)
            }
            setLoadingUbahData(false)
        }
        setLoadingUbahData(false)

    }


    return (
        <div>
            <Modal
                open={open}
            // onClose={close}
            >
                <Box sx={style}>
                    <form
                        onSubmit={handleUbahData}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '10px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: mainColor,
                                    fontSize: '18px',
                                    fonStyle: 'normal',
                                    fontWeight: 'bolder',
                                    lineHeight: 'normal',
                                }}
                            >
                                Ubah Data Agreement
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginTop: '30px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '14px',
                                    textAlign: 'justify',
                                    lineHeight: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginBottom: '10px',
                                }}
                            >
                                Kenapa saudara(i) adalah kandidat yang tepat untuk menerima beasiswa atau bantuan ini?
                            </Typography>
                            <TextareaAutosize
                                value={answer1}
                                // maxLength={maxCharactersAnswer1}
                                minLength={minCharactersAnswer1}
                                onChange={handleTextareaChange}
                                placeholder="Tulis jawaban Anda di sini..."
                                style={{
                                    width: isMobile ? '94%' : '98%',
                                    height: '137px',
                                    padding: '8px',
                                    fontSize: '14px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    resize: 'vertical',
                                    lineHeight: '20px'
                                    // transition: `${mainColor} 0.3s ease-in-out`
                                }}
                                rowsMin={4} // You can adjust the number of rows as needed
                            // required
                            />
                            {answer1 && answer1.length ?
                                answer1.length < minCharactersAnswer1 && (
                                    <Box
                                        sx={{
                                            textAlign: 'left', fontSize: '12px', color: red[700]
                                        }}
                                    >
                                        Minimal {minCharactersAnswer1 - answer1.length} karakter
                                    </Box>
                                )
                                :
                                undefined
                            }

                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '14px',
                                    textAlign: 'justify',
                                    lineHeight: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginBottom: '10px',
                                    marginTop: '20px',
                                }}
                            >
                                Apa yang hendak saudara(i) kontribusikan untuk Papua Tengah setelah menyelesaikan kewajiban studi saudara(i) jika terpilih?
                            </Typography>
                            <TextareaAutosize
                                value={answer2}
                                // maxLength={maxCharactersAnswer1}
                                minLength={minCharactersAnswer2}
                                onChange={handleTextareaChange2}
                                placeholder="Tulis jawaban Anda di sini..."
                                style={{
                                    width: isMobile ? '94%' : '98%',
                                    height: '137px',
                                    padding: '8px',
                                    fontSize: '14px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    resize: 'vertical',
                                    lineHeight: '20px'
                                    // transition: `${mainColor} 0.3s ease-in-out`
                                }}
                                rowsMin={4} // You can adjust the number of rows as needed
                            // required
                            />

                            {answer2 && answer2.length ?
                                answer2.length < minCharactersAnswer2 && (
                                    <Box
                                        sx={{
                                            textAlign: 'left', fontSize: '12px', color: red[700]
                                        }}
                                    >
                                        Minimal {minCharactersAnswer2 - answer2.length} karakter
                                    </Box>
                                )
                                :
                                undefined
                            }


                            {/* Upload Gambar KTP */}
                            <Box mb='15px' mt='-15px'>
                                <Typography
                                    sx={{
                                        color: '#1E222F',
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        marginBottom: '10px',
                                        marginTop: '34px'
                                    }}
                                >
                                    Foto Tanda Tangan Digital (Maks 1 MB)
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '24px',
                                        width: '100%',
                                        // backgroundColor: 'orange'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            maxWidth: '350px',
                                            maxHeight: '160px',
                                            width: '100%',
                                            height: '160px',
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '12px',
                                            position: 'relative',
                                            border: `solid 2px ${DividerColor}`,
                                            borderStyle: 'dashed',
                                        }}
                                        onDragOver={handleDragOverTTD}
                                        onDrop={handleDropTTD}
                                        onDragLeave={handleDragLeave}
                                    >
                                        {isDragging.isDraggingTTD && !isMobile ?
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 'bold',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center',
                                                    color: mainColor
                                                }}
                                            >
                                                Lepaskan untuk mengunggah
                                            </Typography>
                                            :
                                            selectedImageTTD && selectedImageTTD.type === 'application/pdf' ? (
                                                // Handle PDF display logic
                                                <>
                                                    {handleShowPDF(selectedImageTTD.name)}
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '4px',
                                                            right: '4px',
                                                            backgroundColor: '#fff',
                                                            borderRadius: '50%',
                                                            padding: '2px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={handleRemoveImageTTD}
                                                    >
                                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                    </Box>
                                                </>
                                            ) : imageUrlTTD ? (
                                                // Handle image display logic
                                                <>
                                                    <img
                                                        src={imageUrlTTD}
                                                        alt="selected-image"
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'contain',
                                                            borderRadius: '4px',
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '4px',
                                                            right: '4px',
                                                            backgroundColor: '#fff',
                                                            borderRadius: '50%',
                                                            padding: '2px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={handleRemoveImageTTD}
                                                    >
                                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                    </Box>
                                                </>
                                            ) : (
                                                // Display upload options
                                                <>
                                                    {!isMobile ?
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '13px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '17,64px',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                {selectedImageTTD ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '11px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '17,64px',
                                                                    color: TextGray
                                                                }}
                                                            >
                                                                atau
                                                            </Typography>
                                                        </>
                                                        :
                                                        undefined
                                                    }
                                                    <div
                                                        onClick={handleImageUploadTTD}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '6px 12px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '4px',
                                                            display: 'inline-block',
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '12px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            backgroundColor: bgColor
                                                        }}
                                                    >
                                                        Pilih {selectedImageTTD ? 'Gambar' : 'Gambar/PDF'}
                                                    </div>
                                                </>
                                            )
                                        }
                                        <input
                                            type="file"
                                            accept="image/*,.pdf"  // Accept both image and PDF files
                                            onChange={(event) => handleFileInputChangeTTD(event.target.files[0])}
                                            ref={fileInputRefTTD}
                                            style={{ display: 'none' }}
                                        />
                                    </Box>
                                </Box>
                            </Box>


                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '40px',
                                gap: '10px'
                            }}
                        >
                            <Button
                                variant='outlined'
                                color='error'
                                onClick={() => {
                                    handleClearFormData()
                                    close()
                                }}
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                }}
                                disabled={loadingUbahData ? true : false}
                            >
                                Kembali
                            </Button>
                            <Button
                                variant='contained'
                                type='submit'
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    backgroundColor: mainColor,
                                    '&:hover': {
                                        backgroundColor: mainColor
                                    }
                                }}
                                disabled={loadingUbahData ? true : false}
                            >
                                {loadingUbahData ? 'Loading...' : 'Simpan Data'}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default UbahDataAgreement