import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { bgColor, DividerColor, mainColor, TextGray } from '../../Config/Color';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { updateDataPersyaratan } from '../../Config/Api';
import axios from 'axios';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';



const UbahDataBerkasPersyaratan = ({
    open,
    close,
    biodata,
    getData
}) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isMobile ? '320px' : '400px',
        width: '100%',
        maxHeight: '90vh', // Membatasi tinggi modal maksimal 90% dari viewport height
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        gap: '5px',
        p: 2,
        overflowY: 'auto', // Membuat modal bisa di-scroll

        /* Custom scrollbar styling */
        '&::-webkit-scrollbar': {
            width: '0px', // Lebar scroll bar
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1', // Warna track scroll (background scroll)
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#fff', // Warna scroll bar
            borderRadius: '10px', // Sudut scroll bar
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#fff', // Warna scroll saat hover
        },
        scrollbarWidth: 'thin', // Untuk Firefox (atur lebar scroll)
        scrollbarColor: `${'#fff'} #f1f1f1`, // Warna scroll dan track untuk Firefox
    };


    const [newData, setNewData] = useState({
        nik: '',
        kk: '',
        buktiAktifKuliah: '',
    })

    const [selectedImageNIK, setSelectedImageNIK] = useState(null);
    const [imageUrlNIK, setImageUrlNIK] = useState(null);
    const [KTPError, setKTPError] = useState('');
    const [KKError, setKKError] = useState('');

    const [selectedImageKK, setSelectedImageKK] = useState(null);
    const [imageUrlKK, setImageUrlKK] = useState(null);

    const [isDragging, setIsDragging] = useState({
        isDraggingNIK: false,
        isDraggingKK: false,
    });

    const fileInputRefNIK = useRef(null);
    const fileInputRefKK = useRef(null);
    const MAX_IMAGE_SIZE_MB = 1;


    const [loadingUbahData, setLoadingUbahData] = useState(false)

    // var data = localStorage.getItem('data')

    const handleClearFormData = () => {
        setNewData({
            nik: '',
            kk: '',
            buktiAktifKuliah: '',
        })

        setSelectedImageNIK(null);
        setSelectedImageKK(null);

        setImageUrlNIK(null);
        setImageUrlKK(null);

        setKTPError('');
        setKKError('');
    }
    const handleImageUploadNIK = () => {
        fileInputRefNIK.current.click();
    };

    const handleImageUploadKK = () => {
        fileInputRefKK.current.click();
    };

    const handleRemoveImageNIK = () => {
        setSelectedImageNIK(null);
        setImageUrlNIK(null);
    };

    const handleRemoveImageKK = () => {
        setSelectedImageKK(null);
        setImageUrlKK(null);
    };


    const handleFileInputChangeNIK = (file) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    setSelectedImageNIK(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlNIK(imageUrl);

                } else if (allowedPdfTypes.includes(fileType)) {
                    setSelectedImageNIK(file)
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlNIK(imageUrl);
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
        fileInputRefNIK.current.value = null;
    };

    const handleFileInputChangeKK = (file) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    setSelectedImageKK(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlKK(imageUrl);
                } else if (allowedPdfTypes.includes(fileType)) {
                    setSelectedImageKK(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlKK(imageUrl);
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
        fileInputRefKK.current.value = null;
    };

    const handleShowPDF = (fileName) => {

        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }

    const handleDragOverKK = (event) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        setIsDragging({
            isDraggingKK: true,
        })
    };

    const handleDropKK = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChangeNIK(file);
        setIsDragging({
            isDraggingKK: false
        })
    };

    const handleDragOverNIK = (event) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        setIsDragging({
            isDraggingNIK: true
        })
    };

    const handleDropNIK = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChangeNIK(file);
        setIsDragging({
            isDraggingNIK: false
        })
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingNIK: false,
            isDraggingKK: false
        }) // Set state menjadi false saat dragleave
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewData(prev => {
            return { ...prev, [name]: value };
        });

        // Validate NIK format
        if (name === 'nik') {
            if (value !== '' && value.length < 16) {
                setKTPError('Nomor KTP minimal 16 digit');
            } else {
                setKTPError('');
            }
        }

        if (name === 'kk') {
            if (value !== '' && value.length < 16) {
                setKKError('Nomor KK minimal 16 digit');
            } else {
                setKKError('');
            }
        }
    };

    const logInfo = localStorage.getItem('LogInfo')

    const handleUbahData = async (e) => {
        e.preventDefault()
        setLoadingUbahData(true)
        if (logInfo) {
            const { Id, token } = JSON.parse(logInfo);
            const formData = new FormData();
            formData.append('user_id', Id);
            formData.append('nik_ktp', newData.nik);
            formData.append('family_card_number', newData.kk);
            formData.append('link_pddikti', newData.buktiAktifKuliah)
            if (selectedImageKK) {
                formData.append('family_card_scan', selectedImageKK)
            }
            
            if (selectedImageNIK) {
                formData.append('ktp_scan', selectedImageNIK)
            }

            for (const pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            if (newData.nik.length >= 16 && newData.kk.length >= 16) {
                await axios.post(updateDataPersyaratan, formData)
                    .then((res) => {
                        console.log('res', res.data);
                        SuccessAlert({
                            message: 'Data berhasil diperbarui',
                            position: 'top-center'
                        })
                        getData()
                        close()
                        handleClearFormData()
                        setLoadingUbahData(false)
                    }).catch((err) => {
                        console.log(err.response)
                        if (err.code === 'ERR_NETWORK' || err.message.includes('ERR_CONNECTION_REFUSED')) {
                            ErrorAlert({
                                message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                                position: 'top-center'
                            })
                        }
                        setLoadingUbahData(false)
                    })
            } else if (newData.nik.length < 16) {
                ErrorAlert({
                    message: 'Nomor KTP minimal 16 digit',
                    position: 'top-center'
                })
                setLoadingUbahData(false)

            } else if (newData.kk.length < 16) {
                ErrorAlert({
                    message: 'Nomor KK minimal 16 digit',
                    position: 'top-center'
                })
                setLoadingUbahData(false)
            }
        }

    }


    return (
        <div>
            <Modal
                open={open}
            // onClose={close}
            >
                <Box sx={style}>
                    <form
                        onSubmit={handleUbahData}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '10px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: mainColor,
                                    fontSize: '18px',
                                    fonStyle: 'normal',
                                    fontWeight: 'bolder',
                                    lineHeight: 'normal',
                                }}
                            >
                                Ubah Data Berkas Persyaratan
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginTop: '30px',
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="standard"
                                type="text"
                                label="Nomor Induk Kependudukan(NIK)"
                                placeholder='Masukkan NIK'
                                value={newData.nik}
                                name="nik"
                                onChange={(e) => handleChange(e)}
                                onInput={(e) => {
                                    // Filter out non-numeric characters
                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                }}
                                autoComplete="off"
                                error={newData.nik === '' ? undefined : !!KTPError}
                                helperText={KTPError}
                                required
                                sx={{
                                    // marginBottom: isMobile ? '24px' : '34px',
                                    fontSize: isMobile ? '14px' : '13px',
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "white",
                                    },
                                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                                        "& fieldset": {
                                            borderBottomColor: mainColor,
                                        },
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: '#B3B3D4',
                                        fontWeight: 'bold',
                                        fontSize: '13px'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: mainColor,
                                    },
                                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                    },
                                    "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                    },
                                }}
                            />

                            {/* Upload Gambar KTP */}
                            <Box mb='15px' mt='-15px'>
                                <Typography
                                    sx={{
                                        color: '#1E222F',
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        marginBottom: '10px',
                                        marginTop: '34px'
                                    }}
                                >
                                    Gambar Scan/Foto atau PDF NIK (Maks 1 MB)
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '24px',
                                        width: '100%',
                                        // backgroundColor: 'orange'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            maxWidth: '350px',
                                            maxHeight: '160px',
                                            width: '100%',
                                            height: '160px',
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '12px',
                                            position: 'relative',
                                            border: `solid 2px ${DividerColor}`,
                                            borderStyle: 'dashed',
                                        }}
                                        onDragOver={handleDragOverNIK}
                                        onDrop={handleDropNIK}
                                        onDragLeave={handleDragLeave}
                                    >
                                        {isDragging.isDraggingNIK && !isMobile ?
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 'bold',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center',
                                                    color: mainColor
                                                }}
                                            >
                                                Lepaskan untuk mengunggah
                                            </Typography>
                                            :
                                            selectedImageNIK && selectedImageNIK.type === 'application/pdf' ? (
                                                // Handle PDF display logic
                                                <>
                                                    {handleShowPDF(selectedImageNIK.name)}
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '4px',
                                                            right: '4px',
                                                            backgroundColor: '#fff',
                                                            borderRadius: '50%',
                                                            padding: '2px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={handleRemoveImageNIK}
                                                    >
                                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                    </Box>
                                                </>
                                            ) : imageUrlNIK ? (
                                                // Handle image display logic
                                                <>
                                                    <img
                                                        src={imageUrlNIK}
                                                        alt="selected-image"
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'contain',
                                                            borderRadius: '4px',
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '4px',
                                                            right: '4px',
                                                            backgroundColor: '#fff',
                                                            borderRadius: '50%',
                                                            padding: '2px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={handleRemoveImageNIK}
                                                    >
                                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                    </Box>
                                                </>
                                            ) : (
                                                // Display upload options
                                                <>
                                                    {!isMobile ?
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '13px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '17,64px',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                {selectedImageNIK ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '11px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '17,64px',
                                                                    color: TextGray
                                                                }}
                                                            >
                                                                atau
                                                            </Typography>
                                                        </>
                                                        :
                                                        undefined
                                                    }
                                                    <div
                                                        onClick={handleImageUploadNIK}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '6px 12px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '4px',
                                                            display: 'inline-block',
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '12px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            backgroundColor: bgColor
                                                        }}
                                                    >
                                                        Pilih {selectedImageNIK ? 'Gambar' : 'Gambar/PDF'}
                                                    </div>
                                                </>
                                            )
                                        }
                                        <input
                                            type="file"
                                            accept="image/*,.pdf"  // Accept both image and PDF files
                                            onChange={(event) => handleFileInputChangeNIK(event.target.files[0])}
                                            ref={fileInputRefNIK}
                                            style={{ display: 'none' }}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            {/* Textinput KK */}
                            <TextField
                                fullWidth
                                variant="standard"
                                type="text"
                                label="Nomor Kartu Keluarga(KK)"
                                placeholder='Masukkan NIK'
                                value={newData.kk}
                                name="kk"
                                onChange={(e) => handleChange(e)}
                                onInput={(e) => {
                                    // Filter out non-numeric characters
                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                }}
                                autoComplete="off"
                                error={newData.kk === '' ? undefined : !!KKError}
                                helperText={KKError}
                                required
                                sx={{
                                    // marginBottom: isMobile ? '15px' : '14px',
                                    fontSize: isMobile ? '14px' : '13px',
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "white",
                                    },
                                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                                        "& fieldset": {
                                            borderBottomColor: mainColor,
                                        },
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: '#B3B3D4',
                                        fontWeight: 'bold',
                                        fontSize: '13px'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: mainColor,
                                    },
                                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                    },
                                    "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                    },
                                }}
                            />

                            {/* Upload Gambar KK */}
                            <Box mb='15px' mt='-15px' >
                                <Typography
                                    sx={{
                                        color: '#1E222F',
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        marginBottom: '10px',
                                        marginTop: '34px'
                                    }}
                                >
                                    Gambar Scan/Foto atau PDF KK (Maks 1 MB)
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '24px',
                                        width: '100%',
                                        // backgroundColor: 'orange'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            maxWidth: '350px',
                                            maxHeight: '160px',
                                            width: '100%',
                                            height: '160px',
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '12px',
                                            position: 'relative',
                                            border: `solid 2px ${DividerColor}`,
                                            borderStyle: 'dashed',
                                        }}
                                        onDragOver={handleDragOverKK}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDropKK}
                                    >
                                        {isDragging.isDraggingKK && !isMobile ?
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 'bold',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center',
                                                    color: mainColor
                                                }}
                                            >
                                                Lepaskan untuk mengunggah
                                            </Typography>
                                            :
                                            selectedImageKK && selectedImageKK.type === 'application/pdf' ? (
                                                // Handle PDF display logic
                                                <>
                                                    {handleShowPDF(selectedImageKK.name)}
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '4px',
                                                            right: '4px',
                                                            backgroundColor: '#fff',
                                                            borderRadius: '50%',
                                                            padding: '2px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={handleRemoveImageKK}
                                                    >
                                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                    </Box>
                                                </>
                                            ) : imageUrlKK ? (
                                                // Handle image display logic
                                                <>
                                                    <img
                                                        src={imageUrlKK}
                                                        alt="selected-image"
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'contain',
                                                            borderRadius: '4px',
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '4px',
                                                            right: '4px',
                                                            backgroundColor: '#fff',
                                                            borderRadius: '50%',
                                                            padding: '2px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={handleRemoveImageKK}
                                                    >
                                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                    </Box>
                                                </>
                                            ) : (
                                                // Display upload options
                                                <>
                                                    {!isMobile ?
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '13px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '17,64px',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                {selectedImageKK ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '11px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '17,64px',
                                                                    color: TextGray
                                                                }}
                                                            >
                                                                atau
                                                            </Typography>
                                                        </>
                                                        :
                                                        undefined
                                                    }
                                                    <div
                                                        onClick={handleImageUploadKK}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '6px 12px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '4px',
                                                            display: 'inline-block',
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '12px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            backgroundColor: bgColor
                                                        }}
                                                    >
                                                        Pilih {selectedImageKK ? 'Gambar' : 'Gambar/PDF'}
                                                    </div>
                                                </>
                                            )
                                        }
                                        <input
                                            type="file"
                                            accept="image/*,.pdf"  // Accept both image and PDF files
                                            onChange={(event) => handleFileInputChangeKK(event.target.files[0])}
                                            ref={fileInputRefKK}
                                            style={{ display: 'none' }}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            {/* Link PDDikti */}
                            <TextField
                                fullWidth
                                variant="standard"
                                type="text"
                                label="URL/Link data mahasiswa dari website PDDikti"
                                placeholder='contoh : https://pddikti.kemdikbud.go.id/data_mahasiswa/MDIwQzAwNTQtQkNFNS00NzNGLThEQUItMjhGQjdENTU0NDhE'
                                value={newData.buktiAktifKuliah}
                                name="buktiAktifKuliah"
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                required
                                sx={{
                                    marginBottom: isMobile ? '15px' : '24px',
                                    fontSize: isMobile ? '14px' : '13px',
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "white",
                                    },
                                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                                        "& fieldset": {
                                            borderBottomColor: mainColor,
                                        },
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: '#B3B3D4',
                                        fontWeight: 'bold',
                                        fontSize: '13px',
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: mainColor,
                                    },
                                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                    },
                                    "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '20px',
                                gap: '10px'
                            }}
                        >
                            <Button
                                variant='outlined'
                                color='error'
                                onClick={() => {
                                    handleClearFormData()
                                    close()
                                }}
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                }}
                                disabled={loadingUbahData ? true : false}
                            >
                                Kembali
                            </Button>
                            <Button
                                variant='contained'
                                type='submit'
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    backgroundColor: mainColor,
                                    '&:hover': {
                                        backgroundColor: mainColor
                                    }
                                }}
                                disabled={loadingUbahData ? true : false}
                            >
                                {loadingUbahData ? 'Loading...' : 'Simpan Data'}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default UbahDataBerkasPersyaratan