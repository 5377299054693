import React, { useEffect, useState } from 'react';
import { Typography, useMediaQuery, Grid, Box, Divider, Button, Popover, MenuItem } from '@mui/material';
import Logo from '../../assets/Logo/Logo.png';
import { Dark, Maroon, mainColor } from '../Config/Color';
import { Icon } from '@iconify/react';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Profile from '../Modal/PopoverModal/Profile';
import ChangePasswordModal from '../Modal/PopoverModal/ChangePasswordModal';
import axios from 'axios';
import { getDataDashboardApplicant } from '../Config/Api';
import LoginSessionModal from '../Session/LoginSessionModal';

const TopMenu = () => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const [userName, setuserName] = useState('')
  const [isLoadingLogOut, setIsLoadingLogOut] = useState(false)
  const [openProfileModal, setOpenProfileModal] = useState(false)
  const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)
  const [biodata, setBiodata] = useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getInitials = (name) => {
    const words = name.split(' ');
    return words.map((word) => word[0]).join('');
  };

  const handleLoginSession = (dataLogin) => {
    // const loginSessionExp = localStorage.getItem('LogInfo')
    if (dataLogin) {
      const res = (new Date()).getTime() > JSON.parse(dataLogin).LogSession
      if (res) {
        setOpenLoginSessionModal(true)
        localStorage.removeItem('LogInfo')
      }
    }
  }

  const handleCloseLoginSessionModal = () => {
    setOpenLoginSessionModal(false)
    navigate('/login');
  }
  const dataLogin = localStorage.getItem('LogInfo')

  useEffect(() => {
    if (dataLogin) {
      getData()
      handleLoginSession(dataLogin)
    } else if (!dataLogin) {
      setOpenLoginSessionModal(true)
      setTimeout(() => {
        navigate('/login');
      }, 9000);
    }
  }, [])

  const getData = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email
      const username = JSON.parse(getDataLogin).userName
      setuserName(username)

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {


          setBiodata(response.data.data)

        }).catch((error) => {
          console.log(error.response);
          if (error.response && error.response.data && error.response.data.message) {
            if (error.response.data.message === "Unauthenticated.") {
              handleLoginSession();
            } else {
              console.log('Error Message:', error.response.data.message);
            }
          } else {
            console.log('Unexpected error occurred:', error);
          }
        })
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsLoadingLogOut(true)
    setTimeout(() => {
      setAnchorEl(null)
      localStorage.removeItem('LogInfo')
      navigate('/login')
      setIsLoadingLogOut(false)
    }, 1500);
  }


  return (
    <>
      <Grid
        container
        sx={{
          padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
          backgroundColor: '#fff',
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          // lg={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'space-between' : 'flex-start',
            alignItems: 'center',
            margin: isMobile ? '10px 0px' : '10px 0px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              // backgroundColor: 'orange',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: isMobile ? '10px' : isTablet ? '10px' : '16px',
              }}
            >
              <img
                src={Logo}
                alt="logo-dinas-pendidikan-&-kebudayaan-papua-tengah"
                style={{
                  width: isMobile ? '28px' : isTablet ? '24px' : '34px',
                  height: isMobile ? '36px' : isTablet ? '28px' : '48px',
                }}
              />
              <Box

              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: mainColor,
                    fontSize: isMobile ? '10px' : isTablet ? '12px' : '12px',
                    fonStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                    letterSpacing: '-0.06px',
                    marginBottom: '2px',
                  }}
                >
                  Dinas Pendidikan & Kebudayaan
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: mainColor,
                    fontSize: isMobile ? '13px' : isTablet ? '14px' : '18px',
                    fonStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal',
                    letterSpacing: '4.23px',
                  }}
                >
                  PAPUA TENGAH
                </Typography>
              </Box>
            </Box>


            {isMobile ?

              undefined
              :
              <Button
                onClick={handleClick}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                  gap: '12px',
                  textTransform: 'none',
                  padding: '0px'
                }}
              >
                <Icon icon="iconamoon:arrow-down-2-fill" style={{ fontSize: '24px', alignSelf: 'center', color: 'black' }} />
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      color: '#606571',
                      fontSize: '12px',
                      fonStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                      marginBottom: '4px',
                      textAlign: 'left'
                    }}
                  >
                    Selamat Datang
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      color: '#1E222F',
                      fontSize: '14px',
                      fonStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                      textAlign: 'left'
                    }}
                  >
                    {userName}
                  </Typography>
                </Box>
                {/* Avatar with initials */}
                <Avatar
                  sx={{
                    // backgroundColor: mainColor,
                    // opacity:'0.5'
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      color: '#1E222F',
                      fontSize: '18px',
                      fonStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                      opacity: 'unset'
                    }}
                  >
                    {getInitials(userName)}
                  </Typography>
                </Avatar>
              </Button>
            }
          </Box>
        </Grid>
      </Grid >
      <Box
        sx={{
          width: '100%'
        }}
      >
        <Divider
          variant="string"
          style={{
            backgroundColor: "#fff",
            borderBottomWidth: 0.5,
            // marginTop: "2px",
          }}
        />
      </Box>
      <Navbar />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{
            marginBottom: '3px'
          }}
          onClick={() => {
            setAnchorEl(null)
            setOpenProfileModal(true)
          }}
        >
          <Icon icon="carbon:user-profile"
            style={{
              fontSize: '18px',
              alignSelf: 'center',
              color: Dark
            }}
          />
          <Typography
            sx={{
              color: Dark,
              fontWeight: 'bold',
              fontSize: '12px',
              marginLeft: '10px',
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Profile
          </Typography>
        </MenuItem>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Divider
            variant="string"
            style={{
              backgroundColor: "#fff",
              borderBottomWidth: 0.5,
              // marginTop: "2px",
            }}
          />
        </Box>
        <MenuItem
          sx={{
            marginBottom: '3px',
            marginTop: '3px'
          }}
          onClick={() => {
            setAnchorEl(null)
            setOpenChangePasswordModal(true)
          }}
        >
          <Icon icon="carbon:password"
            style={{
              fontSize: '18px',
              alignSelf: 'center',
              color: Dark
            }}
          />
          <Typography
            sx={{
              color: Dark,
              fontWeight: 'bold',
              fontSize: '12px',
              marginLeft: '10px',
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Ubah Password
          </Typography>
        </MenuItem>
        <Box
          sx={{
            width: '100%'
          }}
        >
          <Divider
            variant="string"
            style={{
              backgroundColor: "#fff",
              borderBottomWidth: 0.5,
              // marginTop: "2px",
            }}
          />
        </Box>
        <MenuItem
          sx={{
            marginTop: '3px'
          }}
          onClick={handleLogout}
        >
          {isLoadingLogOut ?
            <>
              <Icon icon="svg-spinners:3-dots-fade"
                style={{
                  fontSize: '18px',
                  alignSelf: 'center',
                  color: Maroon
                }}
              />
              <Typography
                sx={{
                  color: Maroon,
                  fontWeight: 'bold',
                  fontSize: '12px',
                  marginLeft: '10px',
                  fontFamily: 'Plus Jakarta Sans',
                }}
              >
                Loading
              </Typography>
            </>
            :
            <>
              <Icon icon="solar:logout-3-bold"
                style={{
                  fontSize: '18px',
                  alignSelf: 'center',
                  color: Maroon
                }}
              />
              <Typography
                sx={{
                  color: Maroon,
                  fontWeight: 'bold',
                  fontSize: '12px',
                  marginLeft: '10px',
                  fontFamily: 'Plus Jakarta Sans',
                }}
              >
                Keluar Akun
              </Typography>
            </>
          }
        </MenuItem>
      </Popover>
      <Profile
        open={openProfileModal}
        close={() => setOpenProfileModal(false)}
        biodata={biodata}
      />
      <ChangePasswordModal
        open={openChangePasswordModal}
        close={() => setOpenChangePasswordModal(false)}
        biodata={biodata}
      />
      <LoginSessionModal
        open={openLoginSessionModal}
        close={handleCloseLoginSessionModal}
      />
    </>
  );
};

export default TopMenu;
