import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, Button, TextField, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../Config/Color';
import { useFormContext } from '../../../pages/Registrasi/FormContext';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import TooltipUploadGambar from '../../../assets/tooltip/uploadgambarkelas_x.png'
import CustomTooltip from '../../Modal/Tooltip/CustomTooltip';
import { decrypt } from 'n-krypta';
import { secretKey } from '../../Config/SecretKey';
import FileToBase64 from "../../FileToBase64/FileToBase64";
import Base64ToFile from '../../Base64ToFile/Base64ToFile';
import moment from 'moment';
import axios from 'axios';
import { simpanDataTahap2 } from '../../Config/Api';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import { useNavigate } from 'react-router-dom';


const KuisionerS3 = ({
  isMobile,
  isTablet,
  selectedCategory,
  selectedJenjangPendidikan,
  newData,
  selectedUniverityNew,
  monthAndYear,
  selectedImageNIM,
  imageUrlNIM,
  dataPersonalInformation,
  isPageLoadingTrue,
  isPageLoadingFalse
}) => {

  const navigate = useNavigate();

  const [newData2, setNewData2] = useState({
    buktiAktifKuliah: '',
    // NIM: ''
  })
  const [loadingSimpanData, setLoadingSimpanData] = useState(false)

  const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

  const [selectedImageKHS, setSelectedImageKHS] = useState(null);
  const [imageUrlKHS, setImageUrlKHS] = useState(null);
  const fileInputRefKHS = useRef(null);

  const [selectedImageKRS, setSelectedImageKRS] = useState(null);
  const [imageUrlKRS, setImageUrlKRS] = useState(null);
  const fileInputRefKRS = useRef(null);


  const steps = [
    'Akses website PDDikti di',
    'Pada kolom pencarian masukkan nama lengkap anda.',
    'Setelah hasil pencarian muncul silakan pilih nama anda dan universitas yang sesuai dengan tempat anda menempuh pendidikan saat ini.',
    'Setelah diarahkan ke halaman detail mahasiswa, copy URL/link pada address bar browser yang Anda gunakan.',
    'Paste URL/link yang Anda copy ke isian di atas.',
  ];

  const [isDragging, setIsDragging] = useState({
    isDraggingNIM: false,
    isDraggingKHS: false,
    isDraggingKRS: false,
  });

  const data = localStorage.getItem('data')



  const handleImageUpload = (fileInput) => {
    fileInput.current.click();
  };

  const handleRemoveImage = (removeImage) => {
    if (removeImage === 'khs') {
      setSelectedImageKHS(null)
      setImageUrlKHS(null);
    } else if (removeImage === 'krs') {
      setSelectedImageKRS(null)
      setImageUrlKRS(null);
    }
  };

  const handleDragOver = (event, option) => {
    event.preventDefault();
    // console.log('handleDragOver', event);
    if (option === 'khs') {
      setIsDragging({
        isDraggingKHS: true
      })
    } else if (option === 'krs') {
      setIsDragging({
        isDraggingKRS: true
      })
    }
  };

  const handleDrop = (event, option) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    // console.log('handleDrop', file);
    handleFileInputChange(file, option);
    if (option === 'nim') {
      setIsDragging({
        isDraggingNIM: false
      })
    } else if (option === 'khs') {
      setIsDragging({
        isDraggingKHS: false
      })
    } else if (option === 'krs') {
      setIsDragging({
        isDraggingKRS: false
      })
    }
  };

  const handleDragLeave = () => {
    setIsDragging({
      isDraggingNIM: false,
      isDraggingKHS: false,
      isDraggingKRS: false,
    }) // Set state menjadi false saat dragleave
  };

  const handleFileInputChange = (file, fileInputChange) => {
    // const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
      if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
        ErrorAlert({
          message: 'Ukuran Maksimal Foto/Pdf 1 MB',
          position: 'top-center'
        })
        // // Optionally, clear the file input
        // event.target.value = null;
        // return;
      } else {
        // Check the file type
        const fileType = file.type;
        const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const allowedPdfTypes = ['application/pdf'];

        if (allowedImageTypes.includes(fileType)) {
          // Handle Image File
          if (fileInputChange === 'khs') {
            setSelectedImageKHS(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlKHS(imageUrl);
          } else if (fileInputChange === 'krs') {
            setSelectedImageKRS(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlKRS(imageUrl);
          }
        } else if (allowedPdfTypes.includes(fileType)) {
          // Handle PDF file
          if (fileInputChange === 'khs') {
            setSelectedImageKHS(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlKHS(imageUrl);
          } else if (fileInputChange === 'krs') {
            setSelectedImageKRS(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlKRS(imageUrl);
          }
        } else {
          // Unsupported file type
          console.error('Unsupported file type');
          ErrorAlert({
            message: 'Hanya menerima file jpeg, png, gif dan pdf.',
            position: 'top-center'
          })
          return;
        }
      }


    }
  };

  const handleShowPDF = (fileName) => {

    return (
      <>
        <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
        <Typography
          sx={{
            fontFamily: 'Plus Jakarta Sans',
            color: mainColor,
            fontSize: '11px',
            fonStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: 'normal',
            cursor: 'pointer',
            textAlign: 'center',
            marginTop: '-10px'
          }}
        >
          {fileName}
        </Typography>
      </>
    )
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData2(prev => {
      return { ...prev, [name]: value };
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSimpanData(true)
    isPageLoadingTrue()
    const buktiAktifKuliah = newData.buktiAktifKuliah
    const NIM = newData.NIM

    if (newData2.buktiAktifKuliah === '') {
      ErrorAlert({
        message: 'Url/Link PDDikti wajib diisi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
      isPageLoadingFalse()
    } else if (selectedImageNIM === null) {
      ErrorAlert({
        message: 'Silahkan Upload Foto Kartu Mahasiswa.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
      isPageLoadingFalse()
    } else if (newData.NIM === '') {
      ErrorAlert({
        message: 'NIM wajib diisi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
      isPageLoadingFalse()
    } else if (selectedUniverityNew === '') {
      ErrorAlert({
        message: 'Silahkan pilih universitas.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
      isPageLoadingFalse()
    } else if (newData.semesterSekarang === '') {
      ErrorAlert({
        message: 'Semester Sekarang wajib diisi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
      isPageLoadingFalse()
    } else if (newData.fakultas === '') {
      ErrorAlert({
        message: 'Fakultas wajib diisi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
      isPageLoadingFalse()
    } else if (newData.programStudi === '') {
      ErrorAlert({
        message: 'Program Studi wajib diisi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
      isPageLoadingFalse()
    } else if (monthAndYear === '') {
      ErrorAlert({
        message: 'Tahun Masuk wajib diisi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
      isPageLoadingFalse()
    } else {

      const formData = new FormData();
      formData.append('personal_information_id', dataPersonalInformation.personal_information_id)
      formData.append('user_id', dataPersonalInformation.user_id)
      formData.append('category', selectedCategory)
      formData.append('education_level', selectedJenjangPendidikan)
      formData.append('student_id_number', newData.NIM)
      formData.append('name_of_college', selectedUniverityNew)
      formData.append('faculty_name', newData.fakultas)
      formData.append('study_program', newData.programStudi)
      formData.append('entry_month_ptn', moment(monthAndYear).format('MM'))
      formData.append('entry_year_ptn', moment(monthAndYear).format('YYYY'))
      formData.append('link_pddikti', newData2.buktiAktifKuliah)
      formData.append('current_semester', newData.semesterSekarang)
      formData.append('student_id_number_scan', selectedImageNIM)

      try {
        if (newData.NIM === '') {
          ErrorAlert({
            message: 'NIM wajib diisi.',
            position: 'top-center'
          })
          setLoadingSimpanData(false)
          isPageLoadingFalse()
        } else if (selectedUniverityNew === '') {
          ErrorAlert({
            message: 'Pilih universitas terlebih dahulu.',
            position: 'top-center'
          })
          setLoadingSimpanData(false)
          isPageLoadingFalse()
        } else if (selectedImageNIM === null) {
          ErrorAlert({
            message: 'Silahkan Upload Foto Kartu Mahasiswa.',
            position: 'top-center'
          })
          setLoadingSimpanData(false)
          isPageLoadingFalse()
        } else if (newData2.buktiAktifKuliah === '') {
          ErrorAlert({
            message: 'Url/Link PDDikti wajib diisi.',
            position: 'top-center'
          })
          setLoadingSimpanData(false)
          isPageLoadingFalse()
        } else if (newData.semesterSekarang === '') {
          ErrorAlert({
            message: 'Semester wajib diisi.',
            position: 'top-center'
          })
          setLoadingSimpanData(false)
          isPageLoadingFalse()
        } else if (newData.fakultas === '') {
          ErrorAlert({
            message: 'Fakultas wajib diisi.',
            position: 'top-center'
          })
          setLoadingSimpanData(false)
          isPageLoadingFalse()
        } else if (newData.programStudi === '') {
          ErrorAlert({
            message: 'Program Studi wajib diisi.',
            position: 'top-center'
          })
          setLoadingSimpanData(false)
          isPageLoadingFalse()
        } else if (monthAndYear === '') {
          ErrorAlert({
            message: 'Tahun Masuk wajib diisi.',
            position: 'top-center'
          })
          setLoadingSimpanData(false)
          isPageLoadingFalse()
        } else {
          const response = await axios.post(`${simpanDataTahap2}`, formData)
          console.log('Kuisioner 1', response.data);

          SuccessAlert({
            message: 'Data berhasil disimpan.',
            position: 'top-center'
          })

          setTimeout(() => {
            navigate('/registrasi-tahap-3')
            setLoadingSimpanData(false)
            isPageLoadingFalse()
          }, 1500);
        }
      } catch (error) {
        console.log(error.response);
        if (error.response.data.messages) {
          if (error.response.data.messages.link_pddikti) {
            ErrorAlert({
              message: error.response.data.messages.link_pddikti,
              position: 'top-center'
            })
          }
        }
        if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
          ErrorAlert({
            message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
            position: 'top-center'
          })

        }
        setLoadingSimpanData(false)
        isPageLoadingFalse()
      }

    }
  }


  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>

        {/* Gambar KHS */}
        {/* <Grid item xs={12} sm={12} mb='15px'>
          <Typography
            sx={{
              color: '#1E222F',
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
              textAlign: 'justify',
              marginBottom: '10px',
              // marginTop: '34px'
            }}
          >
            Foto Kartu Hasil Studi (Maks 1 MB)
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
            }}
          >
            <Box
              sx={{
                maxWidth: '486px',
                minWidth: '40%',
                width: isMobile ? '100%' : '40%',
                height: '190px',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                position: 'relative',
                border: `solid 2px ${DividerColor}`,
                borderStyle: 'dashed',
              }}
              onDragOver={(e) => handleDragOver(e, 'khs')}
              onDrop={(e) => handleDrop(e, 'khs')}
              onDragLeave={handleDragLeave}
            >
              {isDragging.isDraggingKHS && !isMobile ?
                <Typography
                  sx={{
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    lineHeight: '17,64px',
                    textAlign: 'center',
                    color: mainColor
                  }}
                >
                  Lepaskan untuk mengunggah
                </Typography>
                :
                selectedImageKHS && selectedImageKHS.type === 'application/pdf' ? (
                  // Handle PDF display logic
                  <>
                    {handleShowPDF(selectedImageKHS.name)}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('khs')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : imageUrlKHS ? (
                  // Handle image display logic
                  <>
                    <img
                      src={imageUrlKHS}
                      alt="selected-image"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '4px',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('khs')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : (
                  // Display upload options
                  <>
                    {!isMobile ?
                      <>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            textAlign: 'center'
                          }}
                        >
                          {imageUrlKHS ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            color: TextGray
                          }}
                        >
                          atau
                        </Typography>
                      </>
                      :
                      undefined
                    }
                    <div
                      onClick={() => handleImageUpload(fileInputRefKHS)}
                      style={{
                        cursor: 'pointer',
                        padding: '6px 12px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        display: 'inline-block',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '17,64px',
                        backgroundColor: bgColor
                      }}
                    >
                      Pilih {imageUrlKHS ? 'Gambar' : 'Gambar/PDF'}
                    </div>
                  </>
                )}
              <input
                type="file"
                accept="image/*,.pdf"  // Accept both image and PDF files
                onChange={(e) => handleFileInputChange(e.target.files[0], 'khs')}
                ref={fileInputRefKHS}
                style={{ display: 'none' }}
              />
            </Box>
            {!isMobile && (
              <CustomTooltip
                message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                padding='8px'
                customWidth='281px'
              />
            )}
          </Box>
        </Grid> */}

        {/* Gambar KRS */}
        {/* <Grid item xs={12} sm={12} mb='15px'>
          <Typography
            sx={{
              color: '#1E222F',
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
              textAlign: 'justify',
              marginBottom: '10px',
              // marginTop: '34px'
            }}
          >
            Foto Kartu Rencana Studi (Maks 1 MB)
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
            }}
          >
            <Box
              sx={{
                maxWidth: '486px',
                minWidth: '40%',
                width: isMobile ? '100%' : '40%',
                height: '190px',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                position: 'relative',
                border: `solid 2px ${DividerColor}`,
                borderStyle: 'dashed',
              }}
              onDragOver={(e) => handleDragOver(e, 'krs')}
              onDrop={(e) => handleDrop(e, 'krs')}
              onDragLeave={handleDragLeave}
            >
              {isDragging.isDraggingKRS && !isMobile ?
                <Typography
                  sx={{
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    lineHeight: '17,64px',
                    textAlign: 'center',
                    color: mainColor
                  }}
                >
                  Lepaskan untuk mengunggah
                </Typography>
                :
                selectedImageKRS && selectedImageKRS.type === 'application/pdf' ? (
                  // Handle PDF display logic
                  <>
                    {handleShowPDF(selectedImageKRS.name)}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('krs')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : imageUrlKRS ? (
                  // Handle image display logic
                  <>
                    <img
                      src={imageUrlKRS}
                      alt="selected-image"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '4px',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('krs')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : (
                  // Display upload options
                  <>
                    {!isMobile ?
                      <>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            textAlign: 'center'
                          }}
                        >
                          {imageUrlKRS ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            color: TextGray
                          }}
                        >
                          atau
                        </Typography>
                      </>
                      :
                      undefined
                    }
                    <div
                      onClick={() => handleImageUpload(fileInputRefKRS)}
                      style={{
                        cursor: 'pointer',
                        padding: '6px 12px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        display: 'inline-block',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '17,64px',
                        backgroundColor: bgColor
                      }}
                    >
                      Pilih {imageUrlKRS ? 'Gambar' : 'Gambar/PDF'}
                    </div>
                  </>
                )}
              <input
                type="file"
                accept="image/*,.pdf"  // Accept both image and PDF files
                onChange={(e) => handleFileInputChange(e.target.files[0], 'krs')}
                ref={fileInputRefKRS}
                style={{ display: 'none' }}
              />
            </Box>
            {!isMobile && (
              <CustomTooltip
                message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                padding='8px'
                customWidth='281px'
              />
            )}
          </Box>
        </Grid> */}

        {/* URL/Link PDDikti */}
        <Grid item xs={12} sm={12}>
          <Typography
            variant="body1"
            sx={{
              color: '#1E222F',
              fontFamily: 'Jakarta Sans, sans-serif',
              marginBottom: 1,
              fontSize: '14px',
              lineHeight: '18px',
              textAlign: 'justify'
            }}
          >
            Bukti aktif kuliah semester akhir. (URL/Link data mahasiswa dari website PDDikti).
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            // label="Bukti aktif kuliah semester akhir. (URL/Link data mahasiswa dari website PDDikti)."
            placeholder='contoh : https://pddikti.kemdikbud.go.id/data_mahasiswa/MDIwQzAwNTQtQkNFNS00NzNGLThEQUItMjhGQjdENTU0NDhE'
            value={newData.buktiAktifKuliah}
            name="buktiAktifKuliah"
            onChange={(e) => handleChange(e)}
            autoComplete="off"
            required
            sx={{
              marginBottom: isMobile ? '15px' : '24px',
              fontSize: isMobile ? '14px' : '13px',
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "white",
              },
              "& .MuiFilledInput-root.Mui-disabled:hover": {
                "& fieldset": {
                  borderBottomColor: mainColor,
                },
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: mainColor,
              },
              "& .MuiFilledInput-underline:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiInputLabel-root": {
                color: '#B3B3D4',
                fontWeight: 'bold',
                fontSize: '13px',
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: mainColor,
              },
              "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                color: mainColor,
              },
              "& .MuiFilledInput-underline:hover:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:hover:after": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus:after": {
                borderBottomColor: mainColor,
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
            }}
          />
          <Box sx={{ marginBottom: 2 }}>

            <Box
              sx={{
                backgroundColor: '#F8D70033',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="body1"
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Jakarta Sans, sans-serif',
                  fontWeight: 500, textAlign: isMobile ? 'center' : undefined,
                  marginBottom: '10px'
                }}>
                Cara mendapatkan URL/Link PDDikti.
              </Typography>
              {steps.map((step, index) => (
                <Typography
                  key={index + 1}
                  variant="body1"
                  sx={{
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontWeight: 500,
                    fontSize: '14px',
                    fontStyle: 'normal',
                    lineHeight: '21px',
                    textAlign: 'justify'
                  }}
                >
                  {index + 1}. {index === 0 ? (
                    <>
                      Akses website PDDikti di{' '}
                      <a href="http://pddikti.kemdikbud.go.id" target="_blank">
                        <span style={{ color: mainColor, textDecoration: 'underline', fontWeight: 'bold' }}>
                          https://pddikti.kemdikbud.go.id
                        </span>
                      </a>
                      {' '}
                      menggunakan Google Chrome/Firefox/Edge ataupun web browser lainnya.
                    </>
                  ) : (
                    step
                  )}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box
            align='center'
            sx={{
              width: '100%',
              marginTop: '24px',
              marginBottom: '26px',
            }}>
            <Button
              // fullWidth
              variant='contained'
              size='medium'
              type='submit'
              sx={{
                minWidth: isMobile ? '100%' : '350px',
                fontSize: isMobile ? '12px' : undefined,
                backgroundColor: btnBgColor,
                color: mainColor,
                padding: '10px 76px',
                // fontWeight:'bold',
                '&:hover': {
                  color: 'white', // Change text color to white on hover
                  backgroundColor: mainColor,
                  // fontWeight:'bold',
                },
              }}
            // disabled={loadingSimpanData ? true : false}
            >
              {loadingSimpanData ?
                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                :
                'Simpan Data & Lanjutkan'
              }
            </Button>
          </Box>
        </Grid>

      </Grid>
    </form>
  )
}

export default KuisionerS3