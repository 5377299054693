import React, { useEffect, useState } from 'react'
import { Blue, Dark, DividerColor, Maroon, TextGray, White, btnBgColor, mainColor } from '../../Config/Color'
import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { getDataDashboardApplicant, listDataKabupaten, updateBiodata } from '../../Config/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ErrorAlert from '../../ToastyAlert/ErrorAlert'
import InfoAlert from '../../ToastyAlert/InfoAlert';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const Biodata = ({ biodata, validasiData, getDataValidasi }) => {

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [tanggalLahir, setTanggalLahir] = useState(null)
    const [switchButton, setSwitchButton] = useState(false)
    const [asalKabupaten, setAsalKabupaten] = useState('Kab. Nabire')
    const [listKabupaten, setListKabupaten] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [newData, setNewData] = useState({
        fullName: '',
        tempatLahir: '',
        tanggalLahir: '',
        bulanLahir: '',
        tahunLahir: '',
    })

    const getData = async () => {
        let uniqueKabName = new Set()
        const currentDateOfBirth = new Date(biodata?.data?.date_of_birth)
  

        setNewData({
            fullName: biodata?.data?.full_name,
            tempatLahir: biodata?.data?.place_of_birth,
        });

        setAsalKabupaten(biodata?.data?.origin_district)
        setTanggalLahir(currentDateOfBirth)

        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const token = JSON.parse(getDataLogin).token
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            await axios.get(`${listDataKabupaten}/${94}`)
                .then((response) => {
                    // console.log('list', response.data);
                    const res = response?.data?.data
                    res.forEach((data) => {
                        uniqueKabName.add(data.name);
                    });

                    // console.log('uniqueKabName', uniqueKabName);

                    // Konversi Set kembali ke array
                    const uniqueKabNameArray = Array.from(uniqueKabName);
                    setListKabupaten(uniqueKabNameArray)
                }).catch((error) => {
                    console.log(error.response);
                })
        }
    };

    useEffect(() => {
        getData();
    }, [biodata]);



    const handleSwitchButton = () => {
        setIsLoading(true)
        setTimeout(() => {
            setSwitchButton(!switchButton)
            setIsLoading(false)
        }, 1500);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };

    const handleSelectKabupaten = (e) => {
        setAsalKabupaten(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log(newData.fullName);
        // console.log(asalKabupaten);
        // console.log(newData.tempatLahir);
        // console.log(newData.tanggalLahir);
        // console.log(newData.bulanLahir);
        // console.log(newData.tahunLahir);

        // Menggabungkan tahun, bulan, dan tanggal menjadi format "YYYY-MM-DD"
        // const birthDate = `${newData.tahunLahir}-${newData.bulanLahir}-${newData.tanggalLahir}`;

        // console.log('asd', tanggalLahir);

        setIsSubmitting(true)


        if (newData.tahunLahir <= 0) {
            ErrorAlert({
                message: 'Tahun Lahir Tidak Valid',
                position: 'top-center'
            })
        } else if (newData.bulanLahir <= 0) {
            ErrorAlert({
                message: 'Bulan Lahir Tidak Valid',
                position: 'top-center'
            })
        } else if (newData.tanggalLahir <= 0) {
            ErrorAlert({
                message: 'Tanggal Lahir Tidak Valid',
                position: 'top-center'
            })
        } else {
            const getDataLogin = localStorage.getItem('LogInfo')
            if (getDataLogin) {
                const user_id = JSON.parse(getDataLogin).Id
                const token = JSON.parse(getDataLogin).token
                const email = JSON.parse(getDataLogin).email

                // console.log(ser_id);
                const newDate = moment(tanggalLahir).format('YYYY-MM-DD')
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

                await axios.put(`${updateBiodata}/${biodata.data?.personal_information_id}`, {
                    origin_district: asalKabupaten,
                    full_name: newData.fullName,
                    place_of_birth: newData.tempatLahir,
                    date_of_birth: newDate
                }).then((response) => {
                    console.log(response.data);
                    setIsSubmitting(false)
                    InfoAlert({
                        message: 'Berhasil Update Data.',
                        position: 'top-center'
                    })
                    getDataValidasi()
                    setSwitchButton(false)
                }).catch((error) => {
                    console.log(error.response);
                    setIsSubmitting(false)
                    if (error.response.data.messages) {
                        ErrorAlert({
                            message: error.response.data.messages.origin_district || error.response.data.messages.date_of_birth ? 'Tanggal lahir bukanlah tanggal yang sah.' : undefined,
                            position: 'top-center'
                        })
                    }
                })
            }

        }
    }

    return (
        <>
            {/* Biodata */}
            <Card
                elevation={3}
                sx={{
                    maxWidth: isMobile || isTablet ? '100%' : '384px',
                    maxHeight: isMobile || isTablet ? '100%' : '450px',
                    width: '100%',
                    height: '100%',
                    border: `1px solid ${DividerColor}`
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            Biodata
                        </Typography>
                        <Box
                            sx={{
                                padding: '4px 8px',
                                backgroundColor: 'rgba(248, 215, 0, 0.20)',
                                borderRadius: '4px',
                                border: `1px solid ${DividerColor}`
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: '16px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                {validasiData ? `${Math.round(validasiData)}%` : '0%'}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        onClick={handleSwitchButton}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: switchButton ? TextGray : mainColor,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textTransform: 'none',
                            padding: '0px'
                        }}
                    >
                        {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: DividerColor,
                            borderBottomWidth: 0.5,
                            // marginTop: "2px",
                        }}
                    />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Nama Lengkap"
                            placeholder='Masukkan nama lengkap'
                            value={newData.fullName}
                            name="fullName"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '5px' : '10px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        <FormControl required variant="standard" fullWidth disabled={switchButton ? false : true} sx={{ marginBottom: '13px' }}>
                            <InputLabel id="asal-kabupaten-labelId">Asal Kabupaten</InputLabel>
                            <Select
                                label="Asal Kabupaten"
                                labelId="asal-kabupaten-labelId"
                                id="asal-kabupaten-Id"
                                defaultValue=''
                                value={asalKabupaten}
                                onChange={(e) => handleSelectKabupaten(e)}
                                required
                            >
                                {listKabupaten && listKabupaten.map((kabupaten, index) => (
                                    <MenuItem key={index} value={kabupaten}>
                                        {kabupaten}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Tempat Lahir"
                            placeholder='Masukkan tempat lahir'
                            value={newData.tempatLahir}
                            name="tempatLahir"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '13px' : '16px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '14px',
                                marginBottom: '16px',
                                width: '100%'
                            }}
                        >
                            <DatePicker
                                label='Tanggal Lahir'
                                // localeText={{ toolbarTitle: 'Title' }}
                                sx={{ gridColumn: 'span 2', padding: '0px', width: '100%' }}
                                slotProps={{
                                    textField: { variant: 'standard' },
                                    toolbar: {
                                        toolbarPlaceholder: '__',
                                        toolbarFormat: 'yyyy-mm-dd',
                                        hidden: true,
                                    },
                                }}
                                // slots={{
                                //   toolbar: CustomToolbarComponent
                                // }}
                                value={tanggalLahir}
                                onChange={(newValue) => setTanggalLahir(newValue)}
                                disabled={switchButton ? false : true}
                            />
                        </Box>
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='medium'
                            sx={{
                                backgroundColor: btnBgColor,
                                textTransform: 'none',
                                color: mainColor,
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: btnBgColor
                                },
                            }}
                            disabled={isSubmitting || !switchButton ? true : false}
                        >
                            {isSubmitting ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Simpan Data'
                            }
                        </Button>
                    </Box>
                </form>
            </Card>
        </>
    )
}

export default Biodata