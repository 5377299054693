import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useState } from 'react';
import { Maroon, TextGray, mainColor } from '../../Config/Color';
import LogoEmail from '../../../assets/Logo/Logo_Email.png'
import { Icon } from '@iconify/react';
import { RegistrasiTahapI, activationEmail, cekVerifikasiEmail, updateDataRegistrasiTahapI } from '../../Config/Api';
import axios from 'axios';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import { useEffect } from 'react';
import { useFormContext } from '../../../pages/Registrasi/FormContext';


const EmailActivationModal = ({
    open,
    close,
    isMobile,
    isTablet,
    newData,
    emailVerifData,
    selectedImageNIK,
    selectedImageKK,
    nextStep,
    countdownTimer,
    errorMessage,
    emptyErrorMessage,
    monthAndYear,
    asalKabupaten,
    statusActivationEmailTrue
}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? 290 : 350,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        p: 3,
    };

    const [isLoadingBack, setIsLoadingBack] = useState(false)
    const [isLoadingSubmitData, setIsLoadingSubmitData] = useState(false)
    const [otp, setOtp] = useState('')

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    useEffect(() => {
        if (countdownTimer === 0) {
            setOtp('')
        }
    }, [countdownTimer])



    const handleSubmitKonfirmasiEmail = async () => {
        // e.preventDefault();
        setIsLoadingSubmitData(true)

        const data = localStorage.getItem('data')

        if (otp !== '') {
            await axios.put(`${activationEmail}`, {
                otp: otp,
                email: newData.email
            }).then((response) => {
                console.log('response activationEmail', response);
                if (response.data.message && response.data.message === "Invalid verification link") {
                    ErrorAlert({
                        message: "Kode aktivasi tidak valid",
                        position: 'top-center'
                    })
                    setIsLoadingSubmitData(false)
                    return
                }

                const { verification_status, email, verification_email_id } = response.data?.data
                console.log('email', email);

                if (verification_status && verification_status === true) {
                    statusActivationEmailTrue()
                    SuccessAlert({
                        message: 'Aktivasi email berhasil',
                        position: 'top-center'
                    })
                    
                    localStorage.setItem('ActivasiEmail', JSON.stringify({
                        email: email,
                        verification_email_id: verification_email_id,
                        nik_ktp: newData.nik,
                    }))

                    setOtp('')
                    emptyErrorMessage()
                    close()
                }
                setIsLoadingSubmitData(false)

            }).catch((error) => {
                console.log(error.response);
                setIsLoadingSubmitData(false)
                if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
                    ErrorAlert({
                        message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                        position: 'top-center'
                    })
                }

            })
        } else {
            ErrorAlert({
                message: 'Kode aktivasi wajib diisi.',
                position: 'top-center'
            })
            setIsLoadingSubmitData(false)
        }


    }

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    return (
        <div>
            <Modal
                open={open}
                // onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {errorMessage && errorMessage === 'The email has already been taken.' ?
                        <>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '15px' : '16px',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                Gunakan kode aktivasi yang telah dikirimkan ke email {' '}
                                <span style={{ fontFamily: 'Jakarta Sans, sans-serif', fontStyle: 'italic', fontWeight: 'bold', color: mainColor }}>{newData.email}</span>,
                                silahkan periksa mailbox atau spambox Anda.

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '15px'
                                }}
                            >
                                <img src={LogoEmail} alt='logo-email' style={{ width: '90px', height: '90px' }} />
                            </Box>

                            {/* <Icon icon='email' style={{ fontSize: '50px', color: mainColor }} /> */}
                            <MuiOtpInput
                                value={otp}
                                length={5}
                                onChange={handleChange}
                                // validateChar={matchIsNumeric}
                                autoFocus
                                TextFieldsProps={{
                                    // disabled: true,
                                    size: 'small'
                                }}
                            />
                        </>
                        :
                        <>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '15px' : '16px',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                Kami telah mengirimkan kode aktivasi ke email {' '}
                                <span style={{ fontFamily: 'Jakarta Sans, sans-serif', fontStyle: 'italic', fontWeight: 'bold', color: mainColor }}>{newData.email}</span>,
                                Masukkan kode untuk aktifkan email Anda.

                            </Typography>
                            {/* <Icon icon='email' style={{ fontSize: '50px', color: mainColor }} /> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '15px'
                                }}
                            >
                                <img src={LogoEmail} alt='logo-email' style={{ width: '90px', height: '90px' }} />
                            </Box>
                            <MuiOtpInput
                                value={otp}
                                length={5}
                                onChange={handleChange}
                                // validateChar={matchIsNumeric}
                                autoFocus
                                TextFieldsProps={{
                                    // disabled: true,
                                    size: 'small'
                                }}
                            />
                        </>

                    }
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: isMobile ? '30px' : '80px',
                            marginTop: "30px"
                        }}
                    >
                        <Button
                            fullWidth
                            size='medium'
                            variant='contained'
                            onClick={() => {
                                setIsLoadingBack(true)
                                // setIsLoadingSubmitData(true)
                                setTimeout(() => {
                                    // resetCountdown()
                                    setIsLoadingBack(false)
                                    // setIsLoadingSubmitData(false)
                                    setOtp('')
                                    emptyErrorMessage()
                                    close()
                                }, 1500);
                            }}
                            sx={{
                                backgroundColor: Maroon,
                                textTransform: 'none',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '12px',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: Maroon,
                                },
                            }}
                            disabled={isLoadingBack || isLoadingSubmitData ? true : false}
                        >
                            {isLoadingBack ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Kembali'
                            }
                        </Button>
                        <Button
                            fullWidth
                            size='medium'
                            variant='contained'
                            onClick={handleSubmitKonfirmasiEmail}
                            sx={{

                                backgroundColor: mainColor,
                                textTransform: 'none',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '12px',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: mainColor,
                                },
                            }}
                            disabled={isLoadingBack || isLoadingSubmitData ? true : false}
                        >
                            {isLoadingSubmitData ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Aktivasi'

                            }

                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default EmailActivationModal