import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, Button, Grid, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../Config/Color';
import TooltipUploadGambar from '../../../assets/tooltip/uploadgambarkelas_x.png'
import { useFormContext } from '../../../pages/Registrasi/FormContext';
import { encrypt, decrypt } from 'n-krypta';
import CustomTooltip from '../../Modal/Tooltip/CustomTooltip';
import { secretKey } from '../../Config/SecretKey';
import { red } from '@mui/material/colors';
import Base64ToFile from "../../Base64ToFile/Base64ToFile";
import FileToBase64 from "../../FileToBase64/FileToBase64";

const BeasiswaBerprestasiS3 = ({ isMobile, isTablet, selectedCategory, selectedJenjangPendidikan, activeCategory }) => {
    // const secretKey = '@BCTdev2024WebPapua'

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const [selectedImageIjazahSMA, setSelectedImageIjazahSMA] = useState(null);
    const [imageUrlIjazahSMA, setImageUrlIjazahSMA] = useState(null);
    const fileInputRefIjazahSMA = useRef(null);

    const [selectedImageIjazahS1, setSelectedImageIjazahS1] = useState(null);
    const [imageUrlIjazahS1, setImageUrlIjazahS1] = useState(null);
    const fileInputRefIjazahS1 = useRef(null);

    const [selectedImageTranskripNilaiS1, setSelectedImageTranskripNilaiS1] = useState(null);
    const [imageUrlTranskripNilaiS1, setImageUrlTranskripNilaiS1] = useState(null);
    const fileInputRefTranskripNilaiS1 = useRef(null);

    const [selectedImageIjazahS2, setSelectedImageIjazahS2] = useState(null);
    const [imageUrlIjazahS2, setImageUrlIjazahS2] = useState(null);
    const fileInputRefIjazahS2 = useRef(null);

    const [selectedImageTranskripNilaiS2, setSelectedImageTranskripNilaiS2] = useState(null);
    const [imageUrlTranskripNilaiS2, setImageUrlTranskripNilaiS2] = useState(null);
    const fileInputRefTranskripNilaiS2 = useRef(null);

    const [loadingSimpanData, setLoadingSimpanData] = useState(false)

    const [selectedCertificate1, setSelectedCertificate1] = useState(null);
    const [selectedCertificate2, setSelectedCertificate2] = useState(null);
    const [selectedCertificate3, setSelectedCertificate3] = useState(null);
    const [selectedToelf, setSelectedToelf] = useState(null);

    const [imageUrlCertificate1, setImageUrlCertificate1] = useState(null);
    const [imageUrlCertificate2, setImageUrlCertificate2] = useState(null);
    const [imageUrlCertificate3, setImageUrlCertificate3] = useState(null);
    const [imageUrlToelf, setImageUrlToelf] = useState(null);

    const fileInputRefCertificate1 = useRef(null);
    const fileInputRefCertificate2 = useRef(null);
    const fileInputRefCertificate3 = useRef(null);
    const fileInputRefToelf = useRef(null);

    const [errorGpaS1, setErrorGpaS1] = useState(null);
    const [errorGpaS2, setErrorGpaS2] = useState(null);
    const [errorAvgS1, setErrorAvgS1] = useState(null);
    const [errorAvgS2, setErrorAvgS2] = useState(null);


    const [newData, setNewData] = useState({
        avg_s1: null,
        avg_s2: null,
        gpa_s1: null,
        gpa_s2: null,
    })

    const [isDragging, setIsDragging] = useState({
        isDraggingIjazahSMA: false,
        isDraggingIjazahS1: false,
        isDraggingIjazahS2: false,
        isDraggingTranskripNilaiS1: false,
        isDraggingTranskripNilaiS2: false,
        isDraggingCertificate1: false,
        isDraggingCertificate2: false,
        isDraggingCertificate3: false,
        isDraggingToefl: false,
    });

    const handleImageUpload = (fileInput) => {
        fileInput.current.click();
    };

    const data = localStorage.getItem('data')

    const getAlreadyExistData = (token) => {
        setNewData({
            avg_s2: token.nilaiRata2S2Exist || null,
            gpa_s2: token.nilaiGpaS2Exist || null,
            avg_s1: token.nilaiRata2S1Exist || null,
            gpa_s1: token.nilaiGpaS1Exist || null
        })


        if (token?.selectedImageIjazahSMAExist) {
            const fileIjazahSMA = Base64ToFile(token.selectedImageIjazahSMAExist.base64, token.selectedImageIjazahSMAExist.name);
            setSelectedImageIjazahSMA(fileIjazahSMA);
            setImageUrlIjazahSMA(token.selectedImageIjazahSMAExist.base64 || null);
        }

        if (token?.selectedImageIjazahS1Exist) {
            const fileIjazahS1 = Base64ToFile(token.selectedImageIjazahS1Exist.base64, token.selectedImageIjazahS1Exist.name);
            setSelectedImageIjazahS1(fileIjazahS1);
            setImageUrlIjazahS1(token.selectedImageIjazahS1Exist.base64 || null);
        }

        if (token?.selectedImageIjazahS2Exist) {
            const fileIjazahS2 = Base64ToFile(token.selectedImageIjazahS2Exist.base64, token.selectedImageIjazahS2Exist.name);
            setSelectedImageIjazahS2(fileIjazahS2);
            setImageUrlIjazahS2(token.selectedImageIjazahS2Exist.base64 || null);
        }

        if (token?.selectedImageTranskripNilaiS1Exist) {
            const fileTranskripNilaiS1 = Base64ToFile(token.selectedImageTranskripNilaiS1Exist.base64, token.selectedImageTranskripNilaiS1Exist.name);
            setSelectedImageTranskripNilaiS1(fileTranskripNilaiS1);
            setImageUrlTranskripNilaiS1(token.selectedImageTranskripNilaiS1Exist.base64 || null);
        }

        if (token?.selectedImageTranskripNilaiS2Exist) {
            const FileTranskripNilaiS2 = Base64ToFile(token.selectedImageTranskripNilaiS2Exist.base64, token.selectedImageTranskripNilaiS2Exist.name);
            setSelectedImageTranskripNilaiS2(FileTranskripNilaiS2);
            setImageUrlTranskripNilaiS2(token.selectedImageTranskripNilaiS2Exist.base64 || null);
        }

        if (token?.selectedCertificate1Exist) {
            const fileSelectedCertificate1 = Base64ToFile(token.selectedCertificate1Exist.base64, token.selectedCertificate1Exist.name);
            setSelectedCertificate1(fileSelectedCertificate1);
            setImageUrlCertificate1(token.selectedCertificate1Exist.base64 || null);
        }

        if (token?.selectedCertificate2Exist) {
            const fileSelectedCertificate2 = Base64ToFile(token.selectedCertificate2Exist.base64, token.selectedCertificate2Exist.name);
            setSelectedCertificate2(fileSelectedCertificate2);
            setImageUrlCertificate2(token.selectedCertificate2Exist.base64 || null);
        }

        if (token?.selectedCertificate3Exist) {
            const fileSelectedCertificate3 = Base64ToFile(token.selectedCertificate3Exist.base64, token.selectedCertificate3Exist.name);
            setSelectedCertificate3(fileSelectedCertificate3);
            setImageUrlCertificate3(token.selectedCertificate3Exist.base64 || null);
        }

        if (token?.selectedToelfExist) {
            const fileSelectedToelf = Base64ToFile(token.selectedToelfExist.base64, token.selectedToelfExist.name);
            setSelectedToelf(fileSelectedToelf);
            setImageUrlToelf(token.selectedToelfExist.base64 || null);
        }
    }

    useEffect(() => {
        if (data) {
            const { dataStep2, cekData } = JSON.parse(data)
            // console.log('key', secretKey);
            if (dataStep2) {
                // const decryptedString = decrypt(dataStep2, secretKey)
                // console.log('dataStep2', dataStep2);
                if (cekData === 'Ya' && dataStep2) {
                    getAlreadyExistData(dataStep2)
                }
            }
        }
    }, [data]);

    const handleRemoveImage = (removeImage) => {
        if (removeImage === 'ijazah sma') {
            setSelectedImageIjazahSMA(null);
            setImageUrlIjazahSMA(null);
        } else if (removeImage === 'ijazah s1') {
            setSelectedImageIjazahS1(null);
            setImageUrlIjazahS1(null);
        } else if (removeImage === 'transkrip nilai s1') {
            setSelectedImageTranskripNilaiS1(null);
            setImageUrlTranskripNilaiS1(null);
        } else if (removeImage === 'ijazah s2') {
            setSelectedImageIjazahS2(null);
            setImageUrlIjazahS2(null);
        } else if (removeImage === 'transkrip nilai s2') {
            setSelectedImageTranskripNilaiS2(null);
            setImageUrlTranskripNilaiS2(null);
        } else if (removeImage === 'sertifikat 1') {
            setSelectedCertificate1(null)
            setImageUrlCertificate1(null)
        } else if (removeImage === 'sertifikat 2') {
            setSelectedCertificate2(null)
            setImageUrlCertificate2(null)
        } else if (removeImage === 'sertifikat 3') {
            setSelectedCertificate3(null)
            setImageUrlCertificate3(null)
        } else if (removeImage === 'toelf') {
            setSelectedToelf(null)
            setImageUrlToelf(null)
        }
    };

    const handleDragOver = (event, option) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        if (option === 'ijazah sma') {
            setIsDragging({
                isDraggingIjazahSMA: true
            })
        } else if (option === 'ijazah s1') {
            setIsDragging({
                isDraggingIjazahS1: true
            })
        } else if (option === 'ijazah s2') {
            setIsDragging({
                isDraggingIjazahS2: true
            })
        } else if (option === 'transkrip nilai s1') {
            setIsDragging({
                isDraggingTranskripNilaiS1: true
            })
        } else if (option === 'transkrip nilai s2') {
            setIsDragging({
                isDraggingTranskripNilaiS2: true
            })
        } else if (option === 'sertifikat 1') {
            setIsDragging({
                isDraggingCertificate1: true
            })
        } else if (option === 'sertifikat 2') {
            setIsDragging({
                isDraggingCertificate2: true
            })
        } else if (option === 'sertifikat 3') {
            setIsDragging({
                isDraggingCertificate3: true
            })
        } else if (option === 'toelf') {
            setIsDragging({
                isDraggingToefl: true
            })
        }
    };

    const handleDrop = (event, option) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        if (option === 'ijazah sma') {
            setIsDragging({
                isDraggingIjazahSMA: false
            })
        } else if (option === 'ijazah s1') {
            setIsDragging({
                isDraggingIjazahS1: false
            })
        } else if (option === 'ijazah s2') {
            setIsDragging({
                isDraggingIjazahS2: false
            })
        } else if (option === 'transkrip nilai s1') {
            setIsDragging({
                isDraggingTranskripNilaiS1: false
            })
        } else if (option === 'transkrip nilai s2') {
            setIsDragging({
                isDraggingTranskripNilaiS2: false
            })
        } else if (option === 'sertifikat 1') {
            setIsDragging({
                isDraggingCertificate1: false
            })
        } else if (option === 'sertifikat 2') {
            setIsDragging({
                isDraggingCertificate2: false
            })
        } else if (option === 'sertifikat 3') {
            setIsDragging({
                isDraggingCertificate3: false
            })
        } else if (option === 'toelf') {
            setIsDragging({
                isDraggingToefl: false
            })
        }
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingIjazahSMA: false,
            isDraggingIjazahS1: false,
            isDraggingIjazahS2: false,
            isDraggingTranskripNilaiS1: false,
            isDraggingTranskripNilaiS2: false,
            isDraggingCertificate1: false,
            isDraggingCertificate2: false,
            isDraggingCertificate3: false,
            isDraggingToefl: false,
        }) // Set state menjadi false saat dragleave
    };

    const handleFileInputChange = (file, fileInputChange) => {
        // const file = event.target.files[0];
        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                })
                // // Optionally, clear the file input
                // event.target.value = null;
                // return;
            } else {
                // Check the file type
                const fileType = file.type;
                const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
                const allowedPdfTypes = ['application/pdf'];

                if (allowedImageTypes.includes(fileType)) {
                    // Handle Image File
                    if (fileInputChange === 'ijazah sma') {
                        setSelectedImageIjazahSMA(file);
                        const imageUrlIjazahSMA = URL.createObjectURL(file);
                        setImageUrlIjazahSMA(imageUrlIjazahSMA);
                    } else if (fileInputChange === 'ijazah s1') {
                        setSelectedImageIjazahS1(file);
                        const imageUrlIjazahS1 = URL.createObjectURL(file);
                        setImageUrlIjazahS1(imageUrlIjazahS1);
                    } else if (fileInputChange === 'transkrip nilai s1') {
                        setSelectedImageTranskripNilaiS1(file);
                        const imageUrlTranskripNilaiS1 = URL.createObjectURL(file);
                        setImageUrlTranskripNilaiS1(imageUrlTranskripNilaiS1);
                    } else if (fileInputChange === 'ijazah s2') {
                        setSelectedImageIjazahS2(file);
                        const imageUrlIjazahS2 = URL.createObjectURL(file);
                        setImageUrlIjazahS2(imageUrlIjazahS2);
                    } else if (fileInputChange === 'transkrip nilai s2') {
                        setSelectedImageTranskripNilaiS2(file);
                        const imageUrlTranskripNilaiS2 = URL.createObjectURL(file);
                        setImageUrlTranskripNilaiS2(imageUrlTranskripNilaiS2);
                    } else if (fileInputChange === 'sertifikat 1') {
                        setSelectedCertificate1(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate1(imageUrl)
                    } else if (fileInputChange === 'sertifikat 2') {
                        setSelectedCertificate2(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate2(imageUrl)
                    } else if (fileInputChange === 'sertifikat 3') {
                        setSelectedCertificate3(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate3(imageUrl)
                    } else if (fileInputChange === 'toelf') {
                        setSelectedToelf(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlToelf(imageUrl)
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    // Handle PDF file
                    if (fileInputChange === 'ijazah sma') {
                        setSelectedImageIjazahSMA(file);
                        const imageUrlIjazahSMA = URL.createObjectURL(file);
                        setImageUrlIjazahSMA(imageUrlIjazahSMA);
                    } else if (fileInputChange === 'ijazah s1') {
                        setSelectedImageIjazahS1(file);
                        const imageUrlIjazahS1 = URL.createObjectURL(file);
                        setImageUrlIjazahS1(imageUrlIjazahS1);
                    } else if (fileInputChange === 'transkrip nilai s1') {
                        setSelectedImageTranskripNilaiS1(file);
                        const imageUrlTranskripNilaiS1 = URL.createObjectURL(file);
                        setImageUrlTranskripNilaiS1(imageUrlTranskripNilaiS1);
                    } else if (fileInputChange === 'ijazah s2') {
                        setSelectedImageIjazahS2(file);
                        const imageUrlIjazahS2 = URL.createObjectURL(file);
                        setImageUrlIjazahS2(imageUrlIjazahS2);
                    } else if (fileInputChange === 'transkrip nilai s2') {
                        setSelectedImageTranskripNilaiS2(file);
                        const imageUrlTranskripNilaiS2 = URL.createObjectURL(file);
                        setImageUrlTranskripNilaiS2(imageUrlTranskripNilaiS2);
                    } else if (fileInputChange === 'sertifikat 1') {
                        setSelectedCertificate1(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate1(imageUrl)
                    } else if (fileInputChange === 'sertifikat 2') {
                        setSelectedCertificate2(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate2(imageUrl)
                    } else if (fileInputChange === 'sertifikat 3') {
                        setSelectedCertificate3(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate3(imageUrl)
                    } else if (fileInputChange === 'toelf') {
                        setSelectedToelf(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlToelf(imageUrl)
                    }
                } else {
                    // Unsupported file type
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png, gif dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }


        }
    };


    const handleSubmit = async () => {

        setLoadingSimpanData(true)

        if (selectedCategory === null) {
            ErrorAlert({
                message: 'Kategori Beasiswa Wajib di Pilih',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedJenjangPendidikan === null) {
            ErrorAlert({
                message: 'Jenjang Pendidikan Wajib di Pilih',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageIjazahSMA === null) {
            ErrorAlert({
                message: 'Silahkan Upload Gambar Ijazah SMA',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageIjazahS1 === null) {
            ErrorAlert({
                message: 'Silahkan Upload Gambar Ijazah S1',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageTranskripNilaiS1 === null) {
            ErrorAlert({
                message: 'Silahkan Upload Gambar Transkrip Nilai',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.avg_s1 === null) {
            ErrorAlert({
                message: 'Nilai rata-rata S1 wajib diisi',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.avg_s1 <= 69) {
            ErrorAlert({
                message: 'Nilai rata-rata S1 tidak memenuhi syarat',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.avg_s1 <= 0 || newData.avg_s1 >= 101) {
            ErrorAlert({
                message: 'Nilai rata-rata S1 tidak valid',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.avg_s2 === null) {
            ErrorAlert({
                message: 'Nilai rata-rata S2 wajib diisi',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.avg_s2 <= 69) {
            ErrorAlert({
                message: 'Nilai rata-rata S2 tidak memenuhi syarat',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.avg_s2 <= 0 || newData.avg_s2 >= 101) {
            ErrorAlert({
                message: 'Nilai rata-rata S2 tidak valid',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.gpa_s1 === null) {
            ErrorAlert({
                message: 'GPA S1 wajib diisi',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.gpa_s1 <= 2.74) {
            ErrorAlert({
                message: 'GPA S1 tidak memenuhi syarat',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.gpa_s1 > 4.00 || newData.gpa_s1 < 0) {
            ErrorAlert({
                message: 'GPA S1 tidak valid',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.gpa_s2 === null) {
            ErrorAlert({
                message: 'GPA S2 wajib diisi',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.gpa_s2 <= 2.74) {
            ErrorAlert({
                message: 'GPA S2 tidak memenuhi syarat',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (newData.gpa_s2 > 4.00 || newData.gpa_s2 < 0) {
            ErrorAlert({
                message: 'GPA S2 tidak valid',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedCertificate1 === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Sertifikat 1',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedCertificate2 === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Sertifikat 2',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedCertificate3 === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Sertifikat 3',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedToelf === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Sertifikat Toefl',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else {
            const selectedImageIjazahSMABase64 = selectedImageIjazahSMA ? await FileToBase64(selectedImageIjazahSMA) : null;
            const selectedImageIjazahS1Base64 = selectedImageIjazahS1 ? await FileToBase64(selectedImageIjazahS1) : null;
            const selectedImageIjazahS2Base64 = selectedImageIjazahS2 ? await FileToBase64(selectedImageIjazahS2) : null;
            const selectedImageTranskripNilaiS1Base64 = selectedImageTranskripNilaiS1 ? await FileToBase64(selectedImageTranskripNilaiS1) : null;
            const selectedImageTranskripNilaiS2Base64 = selectedImageTranskripNilaiS2 ? await FileToBase64(selectedImageTranskripNilaiS2) : null;
            const selectedCertificate1Base64 = selectedCertificate1 ? await FileToBase64(selectedCertificate1) : null;
            const selectedCertificate2Base64 = selectedCertificate2 ? await FileToBase64(selectedCertificate2) : null;
            const selectedCertificate3Base64 = selectedCertificate3 ? await FileToBase64(selectedCertificate3) : null;
            const selectedToelfBase64 = selectedToelf ? await FileToBase64(selectedToelf) : null;


            const nilaiRata2S1 = newData.avg_s1
            const nilaiRata2S2 = newData.avg_s2
            const nilaiGpaS1 = newData.gpa_s1
            const nilaiGpaS2 = newData.gpa_s2


            let values = {
                selectedCategoryExist: selectedCategory,
                selectedJenjangPendidikanExist: selectedJenjangPendidikan,
                nilaiGpaS1Exist: nilaiGpaS1,
                nilaiGpaS2Exist: nilaiGpaS2,
                nilaiRata2S1Exist: nilaiRata2S1,
                nilaiRata2S2Exist: nilaiRata2S2,
                selectedImageIjazahSMAExist: {
                    lastModified: selectedImageIjazahSMA.lastModified,
                    lastModifiedDate: selectedImageIjazahSMA.lastModifiedDate,
                    name: selectedImageIjazahSMA.name,
                    type: selectedImageIjazahSMA.type,
                    size: selectedImageIjazahSMA.size,
                    webkitRelativePath: selectedImageIjazahSMA.webkitRelativePath,
                    url: selectedImageIjazahSMA ? URL.createObjectURL(selectedImageIjazahSMA) : null,
                    base64: selectedImageIjazahSMABase64
                },
                selectedImageIjazahS1Exist: {
                    lastModified: selectedImageIjazahS1.lastModified,
                    lastModifiedDate: selectedImageIjazahS1.lastModifiedDate,
                    name: selectedImageIjazahS1.name,
                    type: selectedImageIjazahS1.type,
                    size: selectedImageIjazahS1.size,
                    webkitRelativePath: selectedImageIjazahS1.webkitRelativePath,
                    url: selectedImageIjazahS1 ? URL.createObjectURL(selectedImageIjazahS1) : null,
                    base64: selectedImageIjazahS1Base64
                },
                selectedImageIjazahS2Exist: {
                    lastModified: selectedImageIjazahS2.lastModified,
                    lastModifiedDate: selectedImageIjazahS2.lastModifiedDate,
                    name: selectedImageIjazahS2.name,
                    type: selectedImageIjazahS2.type,
                    size: selectedImageIjazahS2.size,
                    webkitRelativePath: selectedImageIjazahS2.webkitRelativePath,
                    url: selectedImageIjazahS2 ? URL.createObjectURL(selectedImageIjazahS2) : null,
                    base64: selectedImageIjazahS2Base64
                },
                selectedImageTranskripNilaiS1Exist: {
                    lastModified: selectedImageTranskripNilaiS1.lastModified,
                    lastModifiedDate: selectedImageTranskripNilaiS1.lastModifiedDate,
                    name: selectedImageTranskripNilaiS1.name,
                    type: selectedImageTranskripNilaiS1.type,
                    size: selectedImageTranskripNilaiS1.size,
                    webkitRelativePath: selectedImageTranskripNilaiS1.webkitRelativePath,
                    url: selectedImageTranskripNilaiS1 ? URL.createObjectURL(selectedImageTranskripNilaiS1) : null,
                    base64: selectedImageTranskripNilaiS1Base64
                },
                selectedImageTranskripNilaiS2Exist: {
                    lastModified: selectedImageTranskripNilaiS2.lastModified,
                    lastModifiedDate: selectedImageTranskripNilaiS2.lastModifiedDate,
                    name: selectedImageTranskripNilaiS2.name,
                    type: selectedImageTranskripNilaiS2.type,
                    size: selectedImageTranskripNilaiS2.size,
                    webkitRelativePath: selectedImageTranskripNilaiS2.webkitRelativePath,
                    url: selectedImageTranskripNilaiS2 ? URL.createObjectURL(selectedImageTranskripNilaiS2) : null,
                    base64: selectedImageTranskripNilaiS2Base64
                },
                selectedCertificate1Exist: {
                    lastModified: selectedCertificate1.lastModified,
                    lastModifiedDate: selectedCertificate1.lastModifiedDate,
                    name: selectedCertificate1.name,
                    type: selectedCertificate1.type,
                    size: selectedCertificate1.size,
                    webkitRelativePath: selectedCertificate1.webkitRelativePath,
                    url: selectedCertificate1 ? URL.createObjectURL(selectedCertificate1) : null,
                    base64: selectedCertificate1Base64
                },
                selectedCertificate2Exist: {
                    lastModified: selectedCertificate2.lastModified,
                    lastModifiedDate: selectedCertificate2.lastModifiedDate,
                    name: selectedCertificate2.name,
                    type: selectedCertificate2.type,
                    size: selectedCertificate2.size,
                    webkitRelativePath: selectedCertificate2.webkitRelativePath,
                    url: selectedCertificate2 ? URL.createObjectURL(selectedCertificate2) : null,
                    base64: selectedCertificate2Base64
                },
                selectedCertificate3Exist: {
                    lastModified: selectedCertificate3.lastModified,
                    lastModifiedDate: selectedCertificate3.lastModifiedDate,
                    name: selectedCertificate3.name,
                    type: selectedCertificate3.type,
                    size: selectedCertificate3.size,
                    webkitRelativePath: selectedCertificate3.webkitRelativePath,
                    url: selectedCertificate3 ? URL.createObjectURL(selectedCertificate3) : null,
                    base64: selectedCertificate3Base64
                },
                selectedToelfExist: {
                    lastModified: selectedToelf.lastModified,
                    lastModifiedDate: selectedToelf.lastModifiedDate,
                    name: selectedToelf.name,
                    type: selectedToelf.type,
                    size: selectedToelf.size,
                    webkitRelativePath: selectedToelf.webkitRelativePath,
                    url: selectedToelf ? URL.createObjectURL(selectedToelf) : null,
                    base64: selectedToelfBase64
                }
            };

            // Ambil data yang sudah ada dari localStorage
            const existingData = JSON.parse(localStorage.getItem('data')) || {};

            const { bypass, cekData } = existingData

            // Tambahkan data baru ke dalam objek existingData
            if (cekData === 'Ya' && bypass === 'tahap2') {
                existingData.bypass = 'tahap3';
            }

            existingData.dataStep2 = values;

            // Simpan kembali objek yang telah diperbarui ke dalam localStorage
            localStorage.setItem('data', JSON.stringify(existingData));


            setTimeout(() => {
                setLoadingSimpanData(false)
            }, 2500);
        }
        // setLoadingSimpanData(false)
    }

    const handleShowPDF = (fileName) => {

        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Pengecekan apakah nilai yang diinput adalah string
        if (isNaN(value)) {
            // setErrorAvgSMA('Nilai rata-rata tidak valid.');
            return; // Keluar dari fungsi handleChange jika nilai tidak valid
        }

        setNewData(prev => {
            return { ...prev, [name]: value };
        });

        // Pengecekan nilai rata-rata
        if (name === 'avg_s1') {
            if (value !== '' && (value > 100 || value < 0)) {
                setErrorAvgS1('Nilai rata-rata tidak valid.');
            } else if (value !== '' && value <= 69) {
                setErrorAvgS1('Nilai rata-rata tidak memenuhi syarat(min. 70).');
            } else {
                setErrorAvgS1('');
            }
        } else if (name === 'avg_s2') {
            if (value !== '' && (value > 100 || value < 0)) {
                setErrorAvgS2('Nilai rata-rata tidak valid.');
            } else if (value !== '' && value <= 69) {
                setErrorAvgS2('Nilai rata-rata tidak memenuhi syarat(min. 70).');
            } else {
                setErrorAvgS2('');
            }
        } else if (name === 'gpa_s1') {
            if (value !== '' && (value > 4.00 || value < 0)) {
                setErrorGpaS1('Nilai GPA tidak valid.');
            } else if (value !== '' && value <= 2.74) {
                setErrorGpaS1('Nilai GPA tidak memenuhi syarat(min. 2.75).');
            } else {
                setErrorGpaS1('');
            }
        } else if (name === 'gpa_s2') {
            if (value !== '' && (value > 4.00 || value < 0)) {
                setErrorGpaS2('Nilai GPA tidak valid.');
            } else if (value !== '' && value <= 2.74) {
                setErrorGpaS2('Nilai GPA tidak memenuhi syarat(min. 2.75).');
            } else {
                setErrorGpaS2('');
            }
        }

    }

    return (
        <>

            {/* Textfield GPA S1 */}
            <Grid item xs={12} sm={12} mt='-5px'>
                <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="Masukkan Nilai GPA S1"
                    placeholder='Contoh : 2.75'
                    value={newData.gpa_s1}
                    name="gpa_s1"
                    onChange={(e) => handleChange(e)}
                    error={newData.gpa_s1 === '' ? undefined : !!errorGpaS1}
                    helperText={errorGpaS1}
                    // onInput={(e) => {
                    //     // Filter out non-numeric characters
                    //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    InputLabelProps={{ shrink: newData.gpa_s1 !== null ? true : false }}
                    autoComplete="off"
                    required
                    sx={{
                        width: isMobile ? '100%' : '40%',
                        maxWidth: '40%',
                        minWidth: isMobile ? '100%' : '40px',
                        // marginBottom: isMobile ? '15px' : '24px',
                        fontSize: isMobile ? '14px' : '13px',
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "white",
                        },
                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                            "& fieldset": {
                                borderBottomColor: mainColor,
                            },
                        },
                        "& .MuiInputLabel-root.Mui-disabled": {
                            color: mainColor,
                        },
                        "& .MuiFilledInput-underline:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiInputLabel-root": {
                            color: '#B3B3D4',
                            fontWeight: 'bold',
                            fontSize: '13px',
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: mainColor,
                        },
                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                            color: mainColor,
                        },
                        "& .MuiFilledInput-underline:hover:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:hover:after": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus:after": {
                            borderBottomColor: mainColor,
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                            "-webkit-appearance": "none",
                            margin: 0,
                        },
                        "& input[type=number]": {
                            "-moz-appearance": "textfield",
                        },
                    }}
                />
            </Grid>

            <Grid xs={12} md={12}>
                <Box
                    sx={{
                        width: isMobile ? '100%' : '40%',
                        maxWidth: '40%',
                        minWidth: isMobile ? '100%' : '40px',
                        // marginBottom: isMobile ? '15px' : '4px',
                    }}
                >
                    <ul style={{ marginTop: '5px', paddingLeft: '16px' }}>
                        <li style={{ color: red[700] }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '17,64px',
                                    color: red[700]
                                }}
                            >
                                Nilai GPA minimal 2.75
                            </Typography>
                        </li>
                        <li style={{ color: red[700] }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '17,64px',
                                    color: red[700]
                                }}
                            >
                                Nilai GPA maksimal 4.00
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </Grid>

            {/* Textfield GPA S2 */}
            <Grid item xs={12} sm={12} mt='5px'>
                <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="Masukkan Nilai GPA S2"
                    placeholder='Contoh : 2.75'
                    value={newData.gpa_s2}
                    name="gpa_s2"
                    onChange={(e) => handleChange(e)}
                    error={newData.gpa_s2 === '' ? undefined : !!errorGpaS2}
                    helperText={errorGpaS2}
                    // onInput={(e) => {
                    //     // Filter out non-numeric characters
                    //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    InputLabelProps={{ shrink: newData.gpa_s2 !== null ? true : false }}
                    autoComplete="off"
                    required
                    sx={{
                        width: isMobile ? '100%' : '40%',
                        maxWidth: '40%',
                        minWidth: isMobile ? '100%' : '40px',
                        // marginBottom: isMobile ? '15px' : '24px',
                        fontSize: isMobile ? '14px' : '13px',
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "white",
                        },
                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                            "& fieldset": {
                                borderBottomColor: mainColor,
                            },
                        },
                        "& .MuiInputLabel-root.Mui-disabled": {
                            color: mainColor,
                        },
                        "& .MuiFilledInput-underline:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiInputLabel-root": {
                            color: '#B3B3D4',
                            fontWeight: 'bold',
                            fontSize: '13px',
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: mainColor,
                        },
                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                            color: mainColor,
                        },
                        "& .MuiFilledInput-underline:hover:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:hover:after": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus:after": {
                            borderBottomColor: mainColor,
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                            "-webkit-appearance": "none",
                            margin: 0,
                        },
                        "& input[type=number]": {
                            "-moz-appearance": "textfield",
                        },
                    }}
                />
            </Grid>

            <Grid xs={12} md={12}>
                <Box
                    sx={{
                        width: isMobile ? '100%' : '40%',
                        maxWidth: '40%',
                        minWidth: isMobile ? '100%' : '40px',
                        // marginBottom: isMobile ? '15px' : '4px',
                    }}
                >
                    <ul style={{ marginTop: '5px', paddingLeft: '16px' }}>
                        <li style={{ color: red[700] }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '17,64px',
                                    color: red[700]
                                }}
                            >
                                Nilai GPA minimal 2.75
                            </Typography>
                        </li>
                        <li style={{ color: red[700] }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '17,64px',
                                    color: red[700]
                                }}
                            >
                                Nilai GPA maksimal 4.00
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </Grid>

            {/* Textfield nilai rata2  S1*/}
            <Grid item xs={12} sm={12} mb='5px' >
                <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="Masukkan Nilai Rata-rata Ijazah S1"
                    placeholder='Contoh : 70'
                    value={newData.avg_s1}
                    name="avg_s1"
                    onChange={(e) => handleChange(e)}
                    error={newData.avg_s1 === '' ? undefined : !!errorAvgS1}
                    helperText={errorAvgS1}
                    InputLabelProps={{ shrink: newData.avg_s1 !== null ? true : false }}
                    // onInput={(e) => {
                    //     // Filter out non-numeric characters
                    //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    autoComplete="off"
                    required
                    sx={{
                        width: isMobile ? '100%' : '40%',
                        maxWidth: '40%',
                        minWidth: isMobile ? '100%' : '40px',
                        // marginBottom: isMobile ? '15px' : '24px',
                        fontSize: isMobile ? '14px' : '13px',
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "white",
                        },
                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                            "& fieldset": {
                                borderBottomColor: mainColor,
                            },
                        },
                        "& .MuiInputLabel-root.Mui-disabled": {
                            color: mainColor,
                        },
                        "& .MuiFilledInput-underline:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiInputLabel-root": {
                            color: '#B3B3D4',
                            fontWeight: 'bold',
                            fontSize: '13px',
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: mainColor,
                        },
                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                            color: mainColor,
                        },
                        "& .MuiFilledInput-underline:hover:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:hover:after": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus:after": {
                            borderBottomColor: mainColor,
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                            "-webkit-appearance": "none",
                            margin: 0,
                        },
                        "& input[type=number]": {
                            "-moz-appearance": "textfield",
                        },
                    }}
                />
            </Grid>

            <Grid xs={12} md={12}>
                <Box
                    sx={{
                        width: isMobile ? '100%' : '40%',
                        maxWidth: '40%',
                        minWidth: isMobile ? '100%' : '40px',
                        // marginBottom: isMobile ? '15px' : '4px',
                    }}
                >
                    <ul style={{ marginTop: '5px', paddingLeft: '16px' }}>
                        <li style={{ color: red[700] }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '17,64px',
                                    color: red[700]
                                }}
                            >
                                Nilai rata-rata minimal 70
                            </Typography>
                        </li>
                        <li style={{ color: red[700] }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '17,64px',
                                    color: red[700]
                                }}
                            >
                                Nilai rata-rata maksimal 100
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </Grid>

            {/* Textfield nilai rata2  S2*/}
            <Grid item xs={12} sm={12} mb='5px' >
                <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="Masukkan Nilai Rata-rata Ijazah S2"
                    placeholder='Contoh : 70'
                    value={newData.avg_s2}
                    name="avg_s2"
                    onChange={(e) => handleChange(e)}
                    error={newData.avg_s2 === '' ? undefined : !!errorAvgS2}
                    helperText={errorAvgS2}
                    InputLabelProps={{ shrink: newData.avg_s2 !== null ? true : false }}
                    // onInput={(e) => {
                    //     // Filter out non-numeric characters
                    //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    autoComplete="off"
                    required
                    sx={{
                        width: isMobile ? '100%' : '40%',
                        maxWidth: '40%',
                        minWidth: isMobile ? '100%' : '40px',
                        // marginBottom: isMobile ? '15px' : '24px',
                        fontSize: isMobile ? '14px' : '13px',
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "white",
                        },
                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                            "& fieldset": {
                                borderBottomColor: mainColor,
                            },
                        },
                        "& .MuiInputLabel-root.Mui-disabled": {
                            color: mainColor,
                        },
                        "& .MuiFilledInput-underline:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiInputLabel-root": {
                            color: '#B3B3D4',
                            fontWeight: 'bold',
                            fontSize: '13px',
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: mainColor,
                        },
                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                            color: mainColor,
                        },
                        "& .MuiFilledInput-underline:hover:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:hover:after": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus:before": {
                            borderBottomColor: mainColor,
                        },
                        "& .MuiFilledInput-underline:focus:after": {
                            borderBottomColor: mainColor,
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                            "-webkit-appearance": "none",
                            margin: 0,
                        },
                        "& input[type=number]": {
                            "-moz-appearance": "textfield",
                        },
                    }}
                />
            </Grid>

            <Grid xs={12} md={12}>
                <Box
                    sx={{
                        width: isMobile ? '100%' : '40%',
                        maxWidth: '40%',
                        minWidth: isMobile ? '100%' : '40px',
                        // marginBottom: isMobile ? '15px' : '4px',
                    }}
                >
                    <ul style={{ marginTop: '5px', paddingLeft: '16px' }}>
                        <li style={{ color: red[700] }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '17,64px',
                                    color: red[700]
                                }}
                            >
                                Nilai rata-rata minimal 70
                            </Typography>
                        </li>
                        <li style={{ color: red[700] }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '17,64px',
                                    color: red[700]
                                }}
                            >
                                Nilai rata-rata maksimal 100
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </Grid>

            {/* Berkas Ijazah SMA */}
            <Grid item xs={12} sm={12} mt='20px'>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                    }}
                >
                    Gambar Scan/Foto atau PDF Ijazah SMA (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'ijazah sma')}
                        onDrop={(e) => handleDrop(e, 'ijazah sma')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingIjazahSMA && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageIjazahSMA && selectedImageIjazahSMA.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageIjazahSMA.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah sma')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlIjazahSMA ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlIjazahSMA}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah sma')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlIjazahSMA ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefIjazahSMA)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlIjazahSMA ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'ijazah sma')}
                            ref={fileInputRefIjazahSMA}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Ijazah S1 */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '34px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Ijazah S1 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            overflow: 'hidden',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'ijazah s1')}
                        onDrop={(e) => handleDrop(e, 'ijazah s1')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingIjazahS1 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageIjazahS1 && selectedImageIjazahS1.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageIjazahS1.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah s1')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlIjazahS1 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlIjazahS1}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah s1')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlIjazahS1 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefIjazahS1)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlIjazahS1 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )}
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'ijazah s1')}
                            ref={fileInputRefIjazahS1}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Transkrip Nilai S1 */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '34px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Transkrip Nilai S1 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'transkrip nilai s1')}
                        onDrop={(e) => handleDrop(e, 'transkrip nilai s1')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingTranskripNilaiS1 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageTranskripNilaiS1 && selectedImageTranskripNilaiS1.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageTranskripNilaiS1.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('transkrip nilai s1')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlTranskripNilaiS1 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlTranskripNilaiS1}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('transkrip nilai s1')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlTranskripNilaiS1 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefTranskripNilaiS1)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlTranskripNilaiS1 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'transkrip nilai s1')}
                            ref={fileInputRefTranskripNilaiS1}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Ijazah S2 */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '34px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Ijazah S2 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'ijazah s2')}
                        onDrop={(e) => handleDrop(e, 'ijazah s2')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingIjazahS2 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageIjazahS2 && selectedImageIjazahS2.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageIjazahS2.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah s2')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlIjazahS2 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlIjazahS2}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah s2')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlIjazahS2 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefIjazahS2)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlIjazahS2 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )}
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'ijazah s2')}
                            ref={fileInputRefIjazahS2}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Transkrip Nilai S2 */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '34px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Transkrip Nilai S2 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'transkrip nilai s2')}
                        onDrop={(e) => handleDrop(e, 'transkrip nilai s2')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingTranskripNilaiS2 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageTranskripNilaiS2 && selectedImageTranskripNilaiS2.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageTranskripNilaiS2.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('transkrip nilai s2')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlTranskripNilaiS2 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlTranskripNilaiS2}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('transkrip nilai s2')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlTranskripNilaiS2 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefTranskripNilaiS2)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlTranskripNilaiS2 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )}
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'transkrip nilai s2')}
                            ref={fileInputRefTranskripNilaiS2}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Sertifikat 1 */}
            <Grid item xs={12} sm={12} mt='20px'>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '34px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Sertifikat 1 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'sertifikat 1')}
                        onDrop={(e) => handleDrop(e, 'sertifikat 1')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingCertificate1 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedCertificate1 && selectedCertificate1.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedCertificate1.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 1')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlCertificate1 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlCertificate1}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 1')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlCertificate1 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefCertificate1)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlCertificate1 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'sertifikat 1')}
                            ref={fileInputRefCertificate1}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Sertifikat 2 */}
            <Grid item xs={12} sm={12} mt='20px'>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '34px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Sertifikat 2 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'sertifikat 2')}
                        onDrop={(e) => handleDrop(e, 'sertifikat 2')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingCertificate2 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedCertificate2 && selectedCertificate2.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedCertificate2.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 2')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlCertificate2 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlCertificate2}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 2')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlCertificate2 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefCertificate2)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlCertificate2 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }

                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'sertifikat 2')}
                            ref={fileInputRefCertificate2}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Sertifikat 3 */}
            <Grid item xs={12} sm={12} mt='20px'>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '34px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Sertifikat 3 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'sertifikat 3')}
                        onDrop={(e) => handleDrop(e, 'sertifikat 3')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingCertificate3 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedCertificate3 && selectedCertificate3.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedCertificate3.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 3')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlCertificate3 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlCertificate3}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 3')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlCertificate3 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefCertificate3)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlCertificate3 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'sertifikat 3')}
                            ref={fileInputRefCertificate3}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Sertifikat Toelf */}
            <Grid item xs={12} sm={12} mt='20px'>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '34px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Sertifikat Toelf (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'toelf')}
                        onDrop={(e) => handleDrop(e, 'toelf')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingToefl && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedToelf && selectedToelf.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedToelf.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('toelf')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlToelf ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlToelf}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('toelf')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlToelf ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefToelf)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlToelf ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'toelf')}
                            ref={fileInputRefToelf}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Box
                    // fullWidth
                    // align='center'
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // width: '100%',
                        // maxWidth: isMobile ? '100%' : isTablet ? '352px' : '100%',
                        marginTop: '26px',
                        marginBottom: '56px',
                        // backgroundColor: 'orange',
                        // position: 'relative',
                    }}
                >

                    <Button
                        fullWidth
                        variant='contained'
                        size='medium'
                        onClick={() => handleSubmit()}
                        sx={{
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            padding: '10px 56px',
                            width: '100%',
                            maxWidth: isMobile ? '100%' : '384px',
                            textTransform: 'none',
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor,
                            },
                        }}
                        disabled={
                            loadingSimpanData
                                // ||
                                // (activeCategory[0] && activeCategory[0].status === false)
                                ?
                                true : false
                        }
                    >
                        {loadingSimpanData ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                Simpan Data & Lanjutkan
                            </Typography>
                        }
                    </Button>

                </Box>
            </Grid>
        </>
    )
}

export default BeasiswaBerprestasiS3