import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, Button, TextField } from '@mui/material';
import KuisionerS1 from './KuisionerS1';
import KuisionerS2 from './KuisionerS2';
import KuisionerS3 from './KuisionerS3';

const Kuisioner = ({
    isMobile,
    isTablet,
    selectedCategory,
    selectedJenjangPendidikan,
    newData,
    selectedUniverityNew,
    monthAndYear,
    selectedImageNIM,
    imageUrlNIM,
    dataPersonalInformation,
    isPageLoadingTrue,
    isPageLoadingFalse
}) => {


    return (
        <>
            {/* <Typography  sx={{ fontFamily: 'Jakarta Sans, sans-serif', marginBottom: '10px', fontSize: '16px', }}>
                Lengkapi Persyaratan
            </Typography> */}
            {selectedJenjangPendidikan === 'Strata 1(S1)' ?
                <KuisionerS1
                    isMobile={isMobile}
                    isTablet={isTablet}
                    selectedCategory={selectedCategory}
                    selectedJenjangPendidikan={selectedJenjangPendidikan}
                    newData={newData}
                    selectedUniverityNew={selectedUniverityNew}
                    monthAndYear={monthAndYear}
                    selectedImageNIM={selectedImageNIM}
                    imageUrlNIM={imageUrlNIM}
                    dataPersonalInformation={dataPersonalInformation}
                    isPageLoadingTrue={isPageLoadingTrue}
                    isPageLoadingFalse={isPageLoadingFalse}
                />
                :
                selectedJenjangPendidikan === 'Strata 2(S2)' ?
                    <KuisionerS2
                        isMobile={isMobile}
                        isTablet={isTablet}
                        selectedCategory={selectedCategory}
                        selectedJenjangPendidikan={selectedJenjangPendidikan}
                        newData={newData}
                        selectedUniverityNew={selectedUniverityNew}
                        monthAndYear={monthAndYear}
                        selectedImageNIM={selectedImageNIM}
                        imageUrlNIM={imageUrlNIM}
                        dataPersonalInformation={dataPersonalInformation}
                        isPageLoadingTrue={isPageLoadingTrue}
                        isPageLoadingFalse={isPageLoadingFalse}
                    />
                    :
                    selectedJenjangPendidikan === 'Strata 3(S3)' ?
                        <KuisionerS3
                            isMobile={isMobile}
                            isTablet={isTablet}
                            selectedJenjangPendidikan={selectedJenjangPendidikan}
                            selectedCategory={selectedCategory}
                            newData={newData}
                            selectedUniverityNew={selectedUniverityNew}
                            monthAndYear={monthAndYear}
                            selectedImageNIM={selectedImageNIM}
                            imageUrlNIM={imageUrlNIM}
                            dataPersonalInformation={dataPersonalInformation}
                            isPageLoadingTrue={isPageLoadingTrue}
                            isPageLoadingFalse={isPageLoadingFalse}
                        />
                        :
                        undefined
            }

        </>
    );
};

export default Kuisioner;
