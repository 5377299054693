import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextareaAutosize, TextField, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { bgColor, DividerColor, mainColor, TextGray, White } from '../../Config/Color';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { updateAgreement, updateDataPersyaratan, url } from '../../Config/Api';
import axios from 'axios';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import { red } from '@mui/material/colors';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { useNavigate } from 'react-router-dom';



const NotificationAgreement = ({
    open,
    close,
    pageLoadingFalse,
    pageLoadingTrue
}) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isMobile ? '320px' : '400px',
        width: '100%',
        maxHeight: '90vh', // Membatasi tinggi modal maksimal 90% dari viewport height
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        gap: '5px',
        p: 2,
        // overflowY: 'auto', // Membuat modal bisa di-scroll

        // /* Custom scrollbar styling */
        // '&::-webkit-scrollbar': {
        //     width: 'none', // Lebar scroll bar
        // },
        // '&::-webkit-scrollbar-track': {
        //     backgroundColor: '#f1f1f1', // Warna track scroll (background scroll)
        // },
        // '&::-webkit-scrollbar-thumb': {
        //     backgroundColor: '#fff', // Warna scroll bar
        //     borderRadius: '10px', // Sudut scroll bar
        // },
        // '&::-webkit-scrollbar-thumb:hover': {
        //     backgroundColor: '#fff', // Warna scroll saat hover
        // },
        // scrollbarWidth: 'thin', // Untuk Firefox (atur lebar scroll)
        // scrollbarColor: `${'#fff'} #f1f1f1`, // Warna scroll dan track untuk Firefox
    };

    const [userEmail, setUserEmail] = useState('')

    const getData = () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const { email } = JSON.parse(getDataLogin)
            setUserEmail(email)
        }
        console.log('email', userEmail);

    }

    useEffect(() => {
        if (open) {
            getData()
        }
    }, [open])


    return (
        <div>
            <Modal
                open={open}
                onClose={close}
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            gap: '10px'
                        }}
                    >
                        <NotInterestedIcon
                            style={{
                                color: mainColor,
                                fontSize: '100px'
                            }}
                        />
                        <Typography
                            sx={{
                                fontWeight: 'bolder',
                                color: mainColor,
                                marginTop: '-10px',
                                marginBottom: '5px',
                                fontSize: '16px',
                                fontFamily: 'Plus Jakarta Sans'
                            }}
                        >
                            Tidak Bisa Ubah Data
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '21px',
                                // fontFamily: 'Plus Jakarta Sans'
                            }}
                        >
                            Anda belum menyelesaikan proses pendaftaran, silahkan klik tombol di bawah ini untuk melanjutkan
                            proses pendaftaran.
                        </Typography>
                        <Button
                            fullWidth
                            variant='contained'
                            size='small'
                            onClick={() => {
                                pageLoadingTrue()
                                localStorage.setItem('ActivasiEmail', JSON.stringify({
                                    email: userEmail,
                                    cekData:'Agreement'
                                }))
                                setTimeout(() => {
                                    localStorage.removeItem('LogInfo')
                                    navigate('/registrasi-tahap-1')
                                    pageLoadingFalse()
                                }, 1500);
                            }}
                            sx={{
                                textTransform: 'none',
                                backgroundColor: mainColor,
                                color: White,
                                fontWeight: 'bold',
                                marginTop: '10px',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: mainColor,
                                },
                            }}
                        // disabled={isLoadingResetPassword ? true : false}
                        >
                            Lanjutkan Pendaftaran
                        </Button>

                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default NotificationAgreement