import React, { useEffect, useRef, useState } from 'react'
import TopMenu from '../../components/Header/TopMenu'
import { Blue, Dark, DividerColor, Green, Maroon, TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color'
import { Box, Button, Card, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { getDataDashboardApplicant, getListChat, getListTicket, sendMessage, updateBiodata } from '../../components/Config/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateComplaintModal from '../../components/Modal/Pengaduan/CreateComplaintModal';
import IconPlus from '../../assets/Icon/Add_round_fill.png'
import LoginSessionModal from '../../components/Session/LoginSessionModal';
import moment from 'moment';
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert';
import { Spin } from 'antd';

const Pengaduan = () => {

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const messagesEndRef = useRef(null);
    const ticketEndRef = useRef(null);

    const [openCreateComplaintModal, setOpenCreateComplaintModal] = useState(false)
    const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isChatSelected, setIsChatSelected] = useState(false)
    const [biodata, setBiodata] = useState('')
    const [dataTicket, setDataTicket] = useState([])
    const [selectedSubject, setSelectedSubject] = useState(null)
    const [lastSeen, setLastSeen] = useState(null)
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [selectedTicketMessage, setSelectedTicketMessage] = useState([]);
    const [selectedDataTicket, setSelectedDataTicket] = useState(null);
    const [selectedTicketTime, setSelectedTicketTime] = useState(null);
    const [filterLoading, setFilterLoading] = useState(false)
    const [newMessage, setNewMessage] = useState('');

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            // console.log(token);

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            //Request untuk mengambil data user
            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    // console.log(response.data);
                    setBiodata(response.data)
                    setFilterLoading(false)
                }).catch((error) => {
                    console.log(error.response);
                    if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
                        ErrorAlert({
                            message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                            position: 'top-center'
                        })
                    }
                    setFilterLoading(false)
                })

            //Request untuk mengambil data daftar chat
            await axios.post(`${getListTicket}`, {
                user_id: user_id
            })
                .then((response) => {
                    // console.log('data ticket', response.data);
                    setDataTicket(response.data?.data)
                    setFilterLoading(false)
                }).catch((error) => {
                    console.log(error.response);
                    setFilterLoading(false)
                })
        }
    }

    const handleLoginSession = () => {
        const loginSessionExp = localStorage.getItem('LogInfo')
        const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
        if (res) {
            setFilterLoading(false)
            setOpenLoginSessionModal(true)
            localStorage.removeItem('LogInfo')
        }
    }

    const handleCloseLoginSessionModal = () => {
        setOpenLoginSessionModal(false)
        navigate('/login');
    }

    useEffect(() => {
        setFilterLoading(true)
        const dataLogin = localStorage.getItem('LogInfo')
        if (dataLogin) {
            handleLoginSession()
            getData()
        } else if (!dataLogin) {
            setOpenLoginSessionModal(true)
            setTimeout(() => {
                navigate('/login');
            }, 9000);
        }

    }, [])

    // Fungsi untuk mengarahkan ke bagian paling bawah
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToBottomTicket = () => {
        ticketEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Gunakan useEffect untuk memantau perubahan pesan atau saat komponen dimuat
    useEffect(() => {
        scrollToBottom(); // Panggil fungsi scrollToBottom ketika pesan atau komponen dimuat
    }, []);


    const handleClickTicket = async (data) => {
        const getDataLogin = localStorage.getItem('LogInfo')
        handleLoginSession()
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            // console.log('clicked', data);

            await axios.get(`${getListChat}/${data.ticket_id}`)
                .then((response) => {
                    // console.log('list chat', response.data);
                    setSelectedTicketMessage(response.data?.data)
                    setSelectedSubject(data.subject)
                    setSelectedDataTicket(data)
                    setLastSeen(response.data.time_difference_since_last_access)
                    setIsChatSelected(true)
                    scrollToBottom();
                }).catch((error) => {
                    console.log(error.response);
                    if (error.response.data.message === 'not found: ') {
                        setSelectedSubject(null)
                        ErrorAlert({
                            message: 'Data tidak ditemukan',
                            position: 'top-center'
                        })
                    }
                    if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
                        ErrorAlert({
                            message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                            position: 'top-center'
                        })
                    }
                })
        }
    }

    const handleSendMessage = async (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        // console.log('asdsadsad', selectedTicketMessage);
        const getDataLogin = localStorage.getItem('LogInfo')

        if (getDataLogin) {
            const token = JSON.parse(getDataLogin).token
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.post(sendMessage, {
                ticket_id: selectedDataTicket.ticket_id,
                user_id: selectedDataTicket.user_id,
                message: newMessage,
            }).then((response) => {
                // console.log(response.data);
                handleClickTicket(selectedDataTicket)
                setNewMessage('')
                handleLoginSession()
                setTimeout(() => {
                    SuccessAlert({
                        message: 'Pesan terkirim',
                        position: 'top-center'
                    })
                    scrollToBottom()
                    setIsSubmitting(false)
                }, 1500);
            }).catch((error) => {
                console.log(error.response);
                if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
                    ErrorAlert({
                        message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                        position: 'top-center'
                    })
                }
                setIsSubmitting(false)
            })

        }
    }

    // Translate waktu ke bahasa Indonesia
    const convertTimeToIndonesian = (timeString) => {
        const timeMap = {
            'months': 'bulan',
            'month': 'bulan',
            'hours': 'jam',
            'hour': 'jam',
            'minutes': 'menit',
            'minute': 'menit',
            'seconds': 'detik',
            'second': 'detik',
            'weeks': 'minggu',
            'week': 'minggu',
            'day': 'hari',
            'days': 'hari',
            // 'after': 'setelah'
        };

        // Pisahkan string berdasarkan spasi
        const parts = timeString.split(' ');

        // Ambil bagian-bagian yang diperlukan
        let value = parts[0];
        const unit = parts[1];
        let rest = parts.slice(2).join(' '); // Jadikan 'rest' sebagai variabel let

        // Cek apakah unit waktu ada dalam peta konversi
        if (timeMap[unit]) {
            let unitTranslated = timeMap[unit];
            if (rest === 'after') {
                rest = 'lalu'; // Perbaiki penggunaan variabel 'rest'
            }
            return `${value} ${unitTranslated} ${rest}`;
        } else {
            // Jika tidak ada konversi, kembalikan string asli
            return timeString;
        }
    };

    // console.log(isChatSelected);

    return (
        <>
            <TopMenu />
            <Grid
                container
                spacing={2}
                sx={{
                    padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
                    backgroundColor: bgColor,
                    paddingBottom: '42px',
                    // backgroundColor: 'orange',
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: isMobile ? 'center' : 'flex-start',
                        alignItems: 'center',
                        margin: isMobile ? '10px 0px' : '10px 0px 16px 0px',
                        maxHeight: isMobile ? 'auto' : '623px',

                    }}
                >
                    <Card
                        elevation={3}
                        sx={{
                            display: 'flex',
                            // flexDirection: isMobile ? 'column' : 'row',
                            // justifyContent: isMobile ? 'center' : 'flex-start',
                            // alignItems: 'center',
                            backgroundColor: '#fff',
                            // padding: '0px 10px',
                            // gap: '10px',
                            maxWidth: '1200',
                            width: '100%',
                            height: '523px',
                            // margin:'10px 0px',
                            borderRadius: '8px',
                            border: `1px solid ${DividerColor}`,
                        }}
                    >
                        <Grid
                            container
                        >
                            {isMobile || isTablet ?
                                <Grid
                                    item
                                    xs={12}
                                    md={3.5}
                                    sx={{
                                        border: `1px solid ${DividerColor}`,
                                        // height: isMobile || isTablet ? '100%' : undefined,
                                        display: !isChatSelected ? 'unset' : 'none'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            padding: '15px',
                                            borderBottom: `1px solid ${DividerColor}`,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: Dark,
                                                fontSize: isMobile ? '13px' : '15px',
                                                fonStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '20.16px',
                                            }}
                                        >
                                            Daftar Pengaduan
                                        </Typography>
                                        <IconButton
                                            onClick={() => setOpenCreateComplaintModal(true)}
                                            sx={{
                                                margin: '0px',
                                                padding: '0px',
                                            }}
                                        >
                                            <Tooltip title='Buat Pegaduan'>
                                                <img src={IconPlus} alt='icon-plus' width='24px' height='24px' />
                                            </Tooltip>
                                        </IconButton>
                                    </Box>

                                    {/* Daftar Tiket */}
                                    <Box
                                        sx={{
                                            maxHeight: '470px', // Atur tinggi maksimum yang Anda inginkan
                                            overflowY: 'auto', // Aktifkan pengguliran vertikal
                                            overflowX: 'hidden',
                                            '&::-webkit-scrollbar': {
                                                width: '1px', // Adjust the width as needed
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#fff', // Change this to your brand color
                                                borderRadius: '6px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                backgroundColor: '#f1f1f1', // Change this to the background color
                                                borderRadius: '6px',
                                            },

                                        }}
                                    >
                                        {dataTicket.length > 0 ? dataTicket.map((data, index) => {
                                            return (
                                                <Button
                                                    onClick={() => {
                                                        handleClickTicket(data)
                                                        setSelectedTicket(index)
                                                        setTimeout(() => {
                                                            scrollToBottom();
                                                        }, 1500);
                                                    }}
                                                    key={index}
                                                    sx={{
                                                        // maxWidth: isMobile ? '100%' : isTablet ? '100%' : '400px',
                                                        width: '100%',
                                                        maxHeight: '80px',
                                                        height: '100%',
                                                        backgroundColor: 'orange',
                                                        textTransform: 'none',
                                                        padding: '20px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'flex-start',
                                                        gap: '8px',
                                                        borderRadius: '0px',
                                                        backgroundColor: selectedTicket === index ? mainColor : index % 2 === 0 ? '#fff' : '#f4f7ff',
                                                        '&:hover': {
                                                            backgroundColor: selectedTicket === index ? mainColor : index % 2 === 0 ? '#f0f0f0' : '#f4f7ff',
                                                        },
                                                    }}
                                                >

                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Plus Jakarta Sans',
                                                                color: selectedTicket === index ? '#fff' : Dark,
                                                                fontSize: isMobile ? '12px' : '13px',
                                                                fonStyle: 'normal',
                                                                fontWeight: '500',
                                                                lineHeight: '17.64px',
                                                            }}
                                                        >
                                                            {data.no_aduan ? `Aduan #${data.no_aduan}` : undefined}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Plus Jakarta Sans',
                                                                color: selectedTicket === index ? '#fff' : Green,
                                                                // color: Green,
                                                                fontSize: isMobile ? '12px' : '13px',
                                                                fonStyle: 'normal',
                                                                fontWeight: '600',
                                                                lineHeight: '17.64px',
                                                            }}
                                                        >
                                                            {data.status === 'open' ? 'Open' : 'Closed'}
                                                        </Typography>
                                                    </Box>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            color: selectedTicket === index ? '#fff' : Dark,
                                                            // color: Dark,
                                                            fontSize: isMobile ? '12px' : '13px',
                                                            fonStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: '20.16px',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        {data.subject.length > 35 ? `${data.subject.substring(0, 35)}...` : data.subject}
                                                    </Typography>
                                                    <div ref={ticketEndRef} />
                                                </Button>
                                            )
                                        })
                                            :
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '470px',
                                                    gap: '10px'
                                                    // backgroundColor:'orange'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        color: Dark,
                                                        // color: Dark,
                                                        fontSize: isMobile ? '12px' : '13px',
                                                        fonStyle: 'normal',
                                                        fontWeight: '600',
                                                        lineHeight: '20.16px',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    Belum ada data pengaduan
                                                </Typography>
                                                {/* <Icon icon='svg-spinners:3-dots-bounce' style={{ fontSize: '20px' }} /> */}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                                :

                                // Tampilan list ticket PC
                                <Grid
                                    item
                                    xs={12}
                                    md={3.5}
                                    sx={{
                                        border: `1px solid ${DividerColor}`,
                                        // height: isMobile || isTablet ? '100%' : undefined,
                                        display: isMobile || isTablet ? 'none' : 'unset'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            padding: '15px',
                                            borderBottom: `1px solid ${DividerColor}`,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: Dark,
                                                fontSize: isMobile ? '13px' : '15px',
                                                fonStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '20.16px',
                                            }}
                                        >
                                            Daftar Pengaduan
                                        </Typography>
                                        <IconButton
                                            onClick={() => setOpenCreateComplaintModal(true)}
                                            sx={{
                                                margin: '0px',
                                                padding: '0px',
                                            }}
                                        >
                                            <Tooltip title='Kirim Pesan'>
                                                <img src={IconPlus} alt='icon-plus' width='24px' height='24px' />
                                            </Tooltip>
                                        </IconButton>
                                    </Box>

                                    {/* Daftar Tiket */}
                                    <Box
                                        sx={{
                                            maxHeight: '470px', // Atur tinggi maksimum yang Anda inginkan
                                            overflowY: 'auto', // Aktifkan pengguliran vertikal
                                            overflowX: 'hidden',
                                            '&::-webkit-scrollbar': {
                                                width: '1px', // Adjust the width as needed
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#fff', // Change this to your brand color
                                                borderRadius: '6px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                backgroundColor: '#f1f1f1', // Change this to the background color
                                                borderRadius: '6px',
                                            },

                                        }}
                                    >
                                        {dataTicket.length > 0 ? dataTicket.map((data, index) => {
                                            return (
                                                <Button
                                                    onClick={() => {
                                                        handleClickTicket(data)
                                                        setSelectedTicket(index)
                                                        setTimeout(() => {
                                                            scrollToBottom();
                                                        }, 1500);
                                                    }}
                                                    key={index}
                                                    sx={{
                                                        // maxWidth: isMobile ? '100%' : isTablet ? '100%' : '400px',
                                                        width: '100%',
                                                        maxHeight: '80px',
                                                        height: '100%',
                                                        backgroundColor: 'orange',
                                                        textTransform: 'none',
                                                        padding: '20px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'flex-start',
                                                        gap: '8px',
                                                        borderRadius: '0px',
                                                        backgroundColor: selectedTicket === index ? mainColor : index % 2 === 0 ? '#fff' : '#f4f7ff',
                                                        '&:hover': {
                                                            backgroundColor: selectedTicket === index ? mainColor : index % 2 === 0 ? '#f0f0f0' : '#f4f7ff',
                                                        },
                                                    }}
                                                >

                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Plus Jakarta Sans',
                                                                color: selectedTicket === index ? '#fff' : Dark,
                                                                fontSize: isMobile ? '12px' : '13px',
                                                                fonStyle: 'normal',
                                                                fontWeight: '500',
                                                                lineHeight: '17.64px',
                                                            }}
                                                        >
                                                            {data.no_aduan ? `Aduan #${data.no_aduan}` : undefined}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Plus Jakarta Sans',
                                                                color: selectedTicket === index ? '#fff' : Green,
                                                                // color: Green,
                                                                fontSize: isMobile ? '12px' : '13px',
                                                                fonStyle: 'normal',
                                                                fontWeight: '600',
                                                                lineHeight: '17.64px',
                                                            }}
                                                        >
                                                            {data.status === 'open' ? 'Open' : 'Closed'}
                                                        </Typography>
                                                    </Box>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            color: selectedTicket === index ? '#fff' : Dark,
                                                            // color: Dark,
                                                            fontSize: isMobile ? '12px' : '13px',
                                                            fonStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: '20.16px',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        {isTablet ?
                                                            data.subject.length > 20 ? `${data.subject.substring(0, 20)}...` : data.subject
                                                            :
                                                            data.subject.length > 35 ? `${data.subject.substring(0, 35)}...` : data.subject
                                                        }
                                                    </Typography>
                                                    <div ref={ticketEndRef} />
                                                </Button>
                                            )
                                        })
                                            :
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '470px',
                                                    gap: '10px'
                                                    // backgroundColor:'orange'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        color: Dark,
                                                        // color: Dark,
                                                        fontSize: isMobile ? '12px' : '13px',
                                                        fonStyle: 'normal',
                                                        fontWeight: '600',
                                                        lineHeight: '20.16px',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    Belum ada data pengaduan
                                                </Typography>
                                                {/* <Icon icon='svg-spinners:3-dots-bounce' style={{ fontSize: '20px' }} /> */}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            }
                            {/* Message Section */}
                            <Grid
                                item
                                xs={12}
                                md={8.5}
                                sx={{
                                    display: isChatSelected ? 'unset' : 'none'
                                }}
                            >
                                <Box
                                    sx={{
                                        padding: selectedSubject && selectedTicketMessage.length > 0 ? '17px' : '27px',
                                        borderBottom: `1px solid ${DividerColor}`,
                                        borderTop: `1px solid ${DividerColor}`,
                                        borderRight: `1px solid ${DividerColor}`,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: isMobile ? 'center' : isTablet ? 'center' : 'space-between',
                                        alignItems: 'center',
                                        gap: isMobile ? '20px' : isTablet ? '10px' : undefined
                                    }}
                                >
                                    {selectedSubject && selectedTicketMessage.length > 0 ?
                                        isMobile || isTablet ?
                                            <>
                                                <Box
                                                    sx={{
                                                        // backgroundColor: 'orange',
                                                        width: '3%'
                                                    }}
                                                >
                                                    <IconButton
                                                        onClick={() => setIsChatSelected(false)}
                                                        sx={{
                                                            margin: '0px',
                                                            padding: '0px'
                                                        }}
                                                    >
                                                        <Icon icon='ic:baseline-arrow-back' width={isMobile ? '19px' : '20px'} height={isMobile ? '19px' : '20px'} color={mainColor} />
                                                    </IconButton>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        // backgroundColor: 'orange',
                                                        alignItems: 'center',
                                                        width: '95%'
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            color: Dark,
                                                            fontSize: isMobile ? '12px' : '15px',
                                                            fonStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: '20.16px',
                                                        }}
                                                    >
                                                        Topik : {selectedSubject.length > 10 ? `${selectedSubject.substring(0, 8)}...` : selectedSubject}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            color: TextGray,
                                                            fontSize: isMobile ? '11px' : '13px',
                                                            fonStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17.64px',
                                                            textAlign: 'right'
                                                        }}
                                                    >
                                                        Terakhir dilihat {convertTimeToIndonesian(lastSeen)} lalu
                                                    </Typography>
                                                </Box>
                                            </>
                                            :
                                            <>

                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        color: Dark,
                                                        fontSize: isMobile ? '13px' : '15px',
                                                        fonStyle: 'normal',
                                                        fontWeight: '600',
                                                        lineHeight: '20.16px',
                                                    }}
                                                >
                                                    Topik : {selectedSubject.length > 40 ? `${selectedSubject.substring(0, 40)}...` : selectedSubject}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        color: TextGray,
                                                        fontSize: isMobile ? '12px' : '13px',
                                                        fonStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17.64px',
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    Terakhir dilihat {convertTimeToIndonesian(lastSeen)} lalu
                                                </Typography>
                                            </>
                                        :
                                        undefined
                                    }
                                </Box>

                                {/* Message Section */}
                                {selectedSubject ?
                                    <>
                                        <Box
                                            sx={{
                                                // backgroundColor:'blue',
                                                maxHeight: '410px', // Atur tinggi maksimum yang Anda inginkan
                                                height: '100%',
                                                overflowY: 'auto', // Aktifkan pengguliran vertikal
                                                '&::-webkit-scrollbar': {
                                                    width: '1px', // Adjust the width as needed
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    backgroundColor: '#fff', // Change this to your brand color
                                                    borderRadius: '6px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                    backgroundColor: '#f1f1f1', // Change this to the background color
                                                    borderRadius: '6px',
                                                },
                                            }}
                                        >
                                            {selectedTicketMessage.length > 0 ? selectedTicketMessage.map((data, index) => {
                                                return (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            margin: '20px 15px',
                                                            // backgroundColor: 'orange',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: data.chat_from === 'user' ? 'flex-start' : 'flex-end',
                                                            gap: '5px',
                                                            // maxWidth:'1000px',
                                                            // width:'100%',
                                                            maxHeight: '60px',
                                                            height: '100%',

                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                gap: '10px'
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Plus Jakarta Sans',
                                                                    color: Dark,
                                                                    fontSize: isMobile ? '11px' : '12px',
                                                                    fonStyle: 'normal',
                                                                    fontWeight: '600',
                                                                    lineHeight: '17.64px',
                                                                    textAlign: 'left'
                                                                }}
                                                            >
                                                                {data.chat_from === 'user' ? 'Saya' : 'Admin'}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Plus Jakarta Sans',
                                                                    color: TextGray,
                                                                    fontSize: isMobile ? '11px' : '12px',
                                                                    fonStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '17.64px',
                                                                    textAlign: 'left'
                                                                }}
                                                            >
                                                                {convertTimeToIndonesian(data.time_difference)}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                backgroundColor: data.chat_from === 'user' ? '#D9D9D980' : '#6FCF974D',
                                                                padding: '8px',
                                                                borderRadius: '6px',
                                                                // width: '100%'
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Plus Jakarta Sans',
                                                                    color: '#000000',
                                                                    fontSize: isMobile ? '13px' : '14px',
                                                                    fonStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '20.16px',
                                                                    textAlign: 'justify',
                                                                }}
                                                            >
                                                                {data.message}
                                                            </Typography>
                                                        </Box>
                                                        <div ref={messagesEndRef} />
                                                    </Box>
                                                )
                                            })
                                                :
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: '410px',
                                                        gap: '10px'
                                                        // backgroundColor:'orange'
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            color: Dark,
                                                            // color: Dark,
                                                            fontSize: isMobile ? '12px' : '13px',
                                                            fonStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: '20.16px',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        Loading Data
                                                    </Typography>
                                                    <Icon icon='svg-spinners:3-dots-bounce' style={{ fontSize: '20px' }} />
                                                </Box>
                                            }
                                        </Box>

                                        {/* Button Send Message */}
                                        <form onSubmit={handleSendMessage}>
                                            <Box
                                                sx={{
                                                    // backgroundColor: "orange",
                                                    height: '100%',
                                                    padding: '10px',
                                                    // borderBottom: `1px solid ${DividerColor}`,
                                                    borderTop: `1px solid ${DividerColor}`,
                                                    borderRight: `1px solid ${DividerColor}`
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        gap: '20px'
                                                    }}
                                                >
                                                    <input
                                                        placeholder='Ketik pesan Anda...'
                                                        style={{
                                                            width: isMobile || isTablet ? '85%' : '80%',
                                                            height: '26px',
                                                            padding: '5px 10px',
                                                            border: `1px solid ${DividerColor}`,
                                                            borderRadius: '5px',
                                                            fontFamily: 'Plus Jakarta Sans',
                                                        }}
                                                        value={newMessage} // Nilai input dihubungkan dengan state message
                                                        onChange={(e) => setNewMessage(e.target.value)}
                                                        required
                                                    />
                                                    {isMobile || isTablet ?
                                                        <IconButton
                                                            // align='center'
                                                            type="submit"
                                                            variant="contained"
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                backgroundColor: btnBgColor,
                                                                color: mainColor,
                                                                textTransform: 'none',
                                                                width: '38px',
                                                                height: '38px',
                                                                padding: '7px',
                                                                margin: '0px',
                                                                // fontWeight: 'bold',
                                                                '&:hover': {
                                                                    // color: 'white', // Change text color to white on hover
                                                                    backgroundColor: btnBgColor,
                                                                }
                                                            }}
                                                            disabled={isSubmitting ? true : false}
                                                        >
                                                            {isSubmitting ?
                                                                <Icon icon='svg-spinners:3-dots-move' style={{ fontSize: '20px' }} />
                                                                :
                                                                <Icon icon='basil:send-solid' width='25px' height='25px' />
                                                            }
                                                        </IconButton>
                                                        :
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: btnBgColor,
                                                                color: mainColor,
                                                                textTransform: 'none',
                                                                width: '152px',
                                                                height: '36px',
                                                                // fontWeight: 'bold',
                                                                '&:hover': {
                                                                    // color: 'white', // Change text color to white on hover
                                                                    backgroundColor: btnBgColor,
                                                                }
                                                            }}
                                                            disabled={isSubmitting ? true : false}
                                                        >
                                                            {isSubmitting ?
                                                                <Icon icon='svg-spinners:3-dots-move' style={{ fontSize: '20px' }} />
                                                                :
                                                                'Kirim Pesan'
                                                            }
                                                        </Button>
                                                    }

                                                </Box>
                                            </Box>
                                        </form>
                                    </>
                                    :
                                    undefined
                                }

                            </Grid>
                        </Grid>
                    </Card>
                </Grid >
            </Grid >
            {
                filterLoading && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 2000
                        }}
                    >
                        <Spin size="large" />
                    </Box>
                )
            }
            <CreateComplaintModal
                open={openCreateComplaintModal}
                close={() => setOpenCreateComplaintModal(false)}
                updateData={() => getData()}
                scrollToBottom={() => scrollToBottomTicket()}
            />
            <LoginSessionModal
                open={openLoginSessionModal}
                close={handleCloseLoginSessionModal}
            />
            {/* <ToastContainer style={{ width: 'auto', minWidth: '300px' }} /> */}
            <ToastContainer />
        </>
    )
}

export default Pengaduan