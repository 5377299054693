import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { bgColor, mainColor } from '../Config/Color'
import Logo from '../../assets/Logo/Logo.png'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'

const LoadingPage = () => {

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            // navigate('/maintenance')
            window.location.href = 'https://beasiswapapuatengah.id'
        }, 2000);
    }, [])


    return (
        <Grid
            container
            sx={{
                backgroundColor: bgColor,
                height: '100vh', // Change height to auto
                overflow: 'hidden',
                // backgroundColor: 'blue'
            }}
        >
            <Grid
                item
                xs={12}
                md={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor:' orange'
                }}
            >
                <Box
                    sx={{
                        marginTop: '64px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '16px'
                    }}
                >
                    <img
                        src={Logo}
                        alt="logo-dinas-pendidikan-&-kebudayaan-papua-tengah"
                        style={{
                            width: ' 40.47px',
                            height: '56px'
                        }}
                    />
                    <Box>
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: mainColor,
                                fontSize: '12px',
                                fonStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                letterSpacing: '-0.06px',
                                marginBottom: '2px'
                            }}
                        >
                            Dinas Pendidikan & Kebudayaan
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: mainColor,
                                fontSize: '18px',
                                fonStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                letterSpacing: '4.23px',
                            }}
                        >
                            PAPUA TENGAH
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: 'center',
                        marginTop: '10px',
                        marginLeft: '30px'
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 24 24"><g><rect width={2} height={5} x={11} y={1} fill="currentColor" opacity={0.14}></rect><rect width={2} height={5} x={11} y={1} fill="currentColor" opacity={0.29} transform="rotate(30 12 12)"></rect><rect width={2} height={5} x={11} y={1} fill="currentColor" opacity={0.43} transform="rotate(60 12 12)"></rect><rect width={2} height={5} x={11} y={1} fill="currentColor" opacity={0.57} transform="rotate(90 12 12)"></rect><rect width={2} height={5} x={11} y={1} fill="currentColor" opacity={0.71} transform="rotate(120 12 12)"></rect><rect width={2} height={5} x={11} y={1} fill="currentColor" opacity={0.86} transform="rotate(150 12 12)"></rect><rect width={2} height={5} x={11} y={1} fill="currentColor" transform="rotate(180 12 12)"></rect><animateTransform attributeName="transform" calcMode="discrete" dur="0.525s" repeatCount="indefinite" type="rotate" values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12"></animateTransform></g></svg>
                    {/* <Icon icon='3-dots-scale-middle' style={{ fontSize: '30px', color: mainColor }} /> */}
                </Box>
            </Grid>
        </Grid>
    )
}

export default LoadingPage