import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, Button, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import BeasiswaBerprestasiS1 from './BeasiswaBerprestasiS1';
import BeasiswaBerprestasiS2 from './BeasiswaBerprestasiS2';
import BeasiswaBerprestasiS3 from './BeasiswaBerprestasiS3';

const BeasiswaBerprestasi = ({ isMobile, isTablet, selectedCategory, selectedJenjangPendidikan, activeCategory }) => {
  // console.log(selectedJenjangPendidikan);

  return (
    <>

      {selectedJenjangPendidikan === 'Strata 1(S1)' ?
        // Berkas Persyaratan S1
        <Grid container spacing={2}>
          <BeasiswaBerprestasiS1
            isMobile={isMobile}
            isTablet={isTablet}
            selectedCategory={selectedCategory}
            selectedJenjangPendidikan={selectedJenjangPendidikan}
            activeCategory={activeCategory}
          />
        </Grid>
        :
        // Berkas Persyaratan S2
        selectedJenjangPendidikan === 'Strata 2(S2)' ?
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <BeasiswaBerprestasiS2
                isMobile={isMobile}
                isTablet={isTablet}
                selectedCategory={selectedCategory}
                selectedJenjangPendidikan={selectedJenjangPendidikan}
                activeCategory={activeCategory}
              />
            </Grid>
          </Grid>
          :
          // Berkas Persyaratan S3
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <BeasiswaBerprestasiS3
                isMobile={isMobile}
                isTablet={isTablet}
                selectedCategory={selectedCategory}
                selectedJenjangPendidikan={selectedJenjangPendidikan}
                activeCategory={activeCategory}
              />
            </Grid>
          </Grid>
      }


    </>
  )
}

export default BeasiswaBerprestasi