import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

//Icon
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { Grid, useMediaQuery } from '@mui/material';
import { btnBgColor, mainColor, TextGray, White } from '../../Config/Color';
import { useNavigate } from 'react-router-dom';
import { updateDataRegistrasiTahapI } from '../../Config/Api';
import axios from 'axios';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import FileToBase64 from '../../FileToBase64/FileToBase64';
import moment from 'moment';



const UserRegisterProgressModal = ({
    open,
    close,
    cekUserProgress,
    pageLoadingTrue,
    pageLoadingFalse,
    newData,
    selectedImageKK,
    selectedImageNIK,
    nextStep,
    monthAndYear,
    asalKabupaten,
    handleNavigasi,
    userProgress
}) => {

    const navigate = useNavigate()
    const [isLoadingLogin, setIsLoadingLogin] = useState(false)
    const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false)
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    // console.log('cekUserProgress', cekUserProgress);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? 330 : 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        pageLoadingTrue()
        if (userProgress) {
            const formData = new FormData();
            formData.append('user_id', cekUserProgress.personalInformation.user_id)
            formData.append('full_name', newData.fullName)
            formData.append('origin_district', asalKabupaten)
            formData.append('whatsapp_number', newData.whatsapp)
            formData.append('relatives_whatsapp', newData.whatsappOrangtua)
            formData.append('personal_email', newData.email)
            formData.append('nik_ktp', newData.nik)
            formData.append('ktp_scan', selectedImageNIK)
            formData.append('place_of_birth', newData.tempatLahir)
            formData.append('family_card_number', newData.kk)
            formData.append('family_card_scan', selectedImageKK)
            formData.append('mother_name', newData.mommyFullName)
            formData.append('father_name', newData.daddyFullName)
            formData.append('mother_nik', newData.nikIbu)
            formData.append('father_nik', newData.nikAyah)
            formData.append('date_of_birth', moment(monthAndYear).format('YYYY-MM-DD'))
            formData.append('password', newData.password)
            formData.append('password2', newData.konfirmasiPassword)

            // Mencetak nilai FormData menggunakan console.log
            // for (let pair of formData.entries()) {
            //     console.log(pair[0] + ': ' + pair[1]);
            // }
            try {
                const response = await axios.put(`${updateDataRegistrasiTahapI}/${cekUserProgress.personalInformation.data.personal_information_id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status >= 200 && response.status < 300) {
                    console.log('updpersonalinformation', response.data);
                    localStorage.setItem('ActivasiEmail', JSON.stringify({
                        email: cekUserProgress.personalInformation.data.personal_email,
                    }));
                    SuccessAlert({
                        message: 'Berhasil merubah data.',
                        position: 'top-center'
                    })

                    if (userProgress === 'tahap_2') {
                        setTimeout(() => {
                            navigate('/registrasi-tahap-2')
                            pageLoadingFalse()
                        }, 2500);
                    } else if (userProgress === 'tahap_3') {
                        setTimeout(() => {
                            navigate('/registrasi-tahap-3')
                            pageLoadingFalse()
                        }, 2500);
                    }
                } else {
                    // Handle non-successful response status
                    console.error(`Unexpected response status: ${response.status}`);
                    pageLoadingFalse()

                }
            } catch (error) {
                console.error(error.response);
                if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
                    ErrorAlert({
                        message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                        position: 'top-center'
                    })
                }
                if (error.response?.data?.messages?.nik_ktp) {
                    ErrorAlert({
                        message: error.response.data.messages.nik_ktp === "The nik ktp must be 16 digits." ? 'Nomor KTP minimal 16 digit.' : error.response.data.messages.nik_ktp,
                        position: 'top-center'
                    })
                }
                pageLoadingFalse()
            }
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {userProgress ?
                        userProgress === 'done' ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    gap: '10px'
                                }}
                            >
                                <MarkEmailReadIcon
                                    style={{
                                        color: mainColor,
                                        fontSize: '100px'
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontWeight: 'bolder',
                                        color: mainColor,
                                        marginTop: '-10px',
                                        marginBottom: '5px',
                                        fontSize: '16px',
                                        fontFamily: 'Plus Jakarta Sans'
                                    }}
                                >
                                    Email Sudah Terdaftar
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '21px',
                                        // fontFamily: 'Plus Jakarta Sans'
                                    }}
                                >
                                    Email yang Anda masukkan sudah terdaftar, silahkan Login menggunakan email dan password yang telah dibuat sebelumnya.
                                    Jika Anda lupa password, silahkan tekan tombol dibawah ini untuk reset password Anda.
                                </Typography>

                                <Button
                                    fullWidth
                                    variant='contained'
                                    size='medium'
                                    onClick={() => {
                                        setIsLoadingLogin(true)
                                        pageLoadingTrue()
                                        setTimeout(() => {
                                            navigate('/login')
                                            pageLoadingFalse()
                                            setIsLoadingLogin(false)
                                        }, 1500);
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: btnBgColor,
                                        color: mainColor,
                                        marginTop: '20px',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            color: mainColor, // Change text color to white on hover
                                            backgroundColor: btnBgColor,
                                        },
                                    }}
                                    disabled={isLoadingLogin ? true : false}
                                >
                                    Login
                                </Button>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        color: TextGray,
                                        margin: '5px 0px'
                                    }}
                                >
                                    Atau
                                </Typography>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    size='medium'
                                    onClick={() => {
                                        setIsLoadingResetPassword(true)
                                        pageLoadingTrue()
                                        setTimeout(() => {
                                            navigate('/lupa-kata-sandi')
                                            pageLoadingFalse()
                                            setIsLoadingResetPassword(false)
                                        }, 1500);
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: mainColor,
                                        color: White,
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            color: 'white', // Change text color to white on hover
                                            backgroundColor: mainColor,
                                        },
                                    }}
                                    disabled={isLoadingResetPassword ? true : false}
                                >
                                    Reset Password
                                </Button>

                            </Box>
                        )
                            :
                            userProgress === 'tahap_2' ?
                                (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            gap: '10px'
                                        }}
                                    >
                                        <MarkEmailReadIcon
                                            style={{
                                                color: mainColor,
                                                fontSize: '100px'
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 'bolder',
                                                color: mainColor,
                                                marginTop: '-10px',
                                                marginBottom: '5px',
                                                fontSize: '16px',
                                                fontFamily: 'Plus Jakarta Sans'
                                            }}
                                        >
                                            Email Sudah Terdaftar
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                lineHeight: '21px',
                                            }}
                                        >
                                            Email yang Anda masukkan sudah terdaftar pada sistem kami,
                                            tapi baru menyelesaikan pendaftaran {' '}
                                            <span style={{ fontWeight: 'bolder', color: mainColor }}>Tahap 1</span>.{' '}
                                            Silahkan melanjutkan proses pendaftaran ke {' '}
                                            <span style={{ fontWeight: 'bolder', color: mainColor }}>Tahap 2</span>{' '}
                                        </Typography>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            size='medium'
                                            onClick={handleSubmit}
                                            sx={{
                                                textTransform: 'none',
                                                backgroundColor: btnBgColor,
                                                color: mainColor,
                                                marginTop: '20px',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    color: mainColor, // Change text color to white on hover
                                                    backgroundColor: btnBgColor,
                                                },
                                            }}
                                            disabled={isLoadingLogin ? true : false}
                                        >
                                            Simpan Data & Lanjut Tahap 2
                                        </Button>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                color: TextGray,
                                                margin: '5px 0px'
                                            }}
                                        >
                                            Atau
                                        </Typography>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            size='medium'
                                            onClick={close}
                                            sx={{
                                                textTransform: 'none',
                                                backgroundColor: mainColor,
                                                color: White,
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    color: 'white', // Change text color to white on hover
                                                    backgroundColor: mainColor,
                                                },
                                            }}
                                            disabled={isLoadingResetPassword ? true : false}
                                        >
                                            Kembali & Ubah Data
                                        </Button>

                                    </Box>
                                )
                                :
                                userProgress === 'tahap_3' ?
                                    (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                gap: '10px'
                                            }}
                                        >
                                            <MarkEmailReadIcon
                                                style={{
                                                    color: mainColor,
                                                    fontSize: '100px'
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bolder',
                                                    color: mainColor,
                                                    marginTop: '-10px',
                                                    marginBottom: '5px',
                                                    fontSize: '16px',
                                                    fontFamily: 'Plus Jakarta Sans'
                                                }}
                                            >
                                                Email Sudah Terdaftar
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                    lineHeight: '21px',
                                                }}
                                            >
                                                Email yang Anda masukkan sudah terdaftar pada sistem kami,
                                                tapi baru menyelesaikan pendaftaran {' '}
                                                <span style={{ fontWeight: 'bolder', color: mainColor }}>Tahap 1</span>.{' '}
                                                &{' '}
                                                <span style={{ fontWeight: 'bolder', color: mainColor }}>Tahap 2</span>.{' '}
                                                Silahkan melanjutkan proses pendaftaran ke {' '}
                                                <span style={{ fontWeight: 'bolder', color: mainColor }}>Tahap 3</span>{' '}
                                            </Typography>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                size='medium'
                                                onClick={handleSubmit}
                                                sx={{
                                                    textTransform: 'none',
                                                    backgroundColor: btnBgColor,
                                                    color: mainColor,
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                    '&:hover': {
                                                        color: mainColor, // Change text color to white on hover
                                                        backgroundColor: btnBgColor,
                                                    },
                                                }}
                                                disabled={isLoadingLogin ? true : false}
                                            >
                                                Simpan Data & Lanjut Tahap 3
                                            </Button>
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    color: TextGray,
                                                    margin: '5px 0px'
                                                }}
                                            >
                                                Atau
                                            </Typography>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                size='medium'
                                                onClick={close}
                                                sx={{
                                                    textTransform: 'none',
                                                    backgroundColor: mainColor,
                                                    color: White,
                                                    fontWeight: 'bold',
                                                    '&:hover': {
                                                        color: 'white', // Change text color to white on hover
                                                        backgroundColor: mainColor,
                                                    },
                                                }}
                                                disabled={isLoadingResetPassword ? true : false}
                                            >
                                                Kembali & Ubah Data
                                            </Button>

                                        </Box>
                                    )
                                    :
                                    undefined
                        :
                        undefined

                    }
                </Box>
            </Modal>
        </div>
    );
}

export default UserRegisterProgressModal