const FileToBase64 = (file) => {
    if (file) {
        console.log('FileToBase64', file);
        
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }
};

export default FileToBase64