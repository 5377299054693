import { Box, Grid, Modal, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { bgColor, mainColor } from '../Config/Color'
import Logo from '../../assets/Logo/Logo.png'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'

const LoadingData = ({
    open,
    close
}) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isMobile ? '330px' : '400px',
        height:'100px',
        // minWidth: isMobile ? '330px' : '600px',
        width: '100%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // gap: '5px',
        p: 2,
    };

    return (
        <div>
            <Modal
                open={open}
            // onClose={close}
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '15px'
                            // backgroundColor:' orange'
                        }}
                    >
                        <Icon icon='svg-spinners:bars-rotate-fade' style={{ fontSize: '35px', color: mainColor }} />
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: mainColor,
                                fontSize: '18px',
                                fonStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                letterSpacing: '1px',
                            }}
                        >
                            Loading...
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default LoadingData