import React, { useEffect, useState } from 'react'
import TopMenu from '../../components/Header/TopMenu'
import { Box, Button, Card, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { Dark, DividerColor, bgColor, mainColor } from '../../components/Config/Color';
import { Icon } from '@iconify/react';
import QRCode from 'react-qr-code';
import moment from 'moment';
import LoginSessionModal from '../../components/Session/LoginSessionModal';
import axios from 'axios';
import { agreementQuestion, getDataDashboardApplicant, url } from '../../components/Config/Api';
import { useNavigate } from 'react-router-dom';
import { red } from '@mui/material/colors';
import UbahDataAgreement from '../../components/Modal/UbahDataAgreement/UbahDataAgreement';
import { Spin } from 'antd';
import NotificationAgreement from '../../components/Modal/UbahDataAgreement/NotificationAgreement';

const Agreement = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const navigate = useNavigate()

    const [completeData, setCompleteData] = useState(null)
    const [listAgreement, setListAgreement] = useState(null)
    const [categoryBeasiswa, setCategoryBeasiswa] = useState(null)
    const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)
    const [openUbahDataModal, setOpenUbahDataModal] = useState(false)
    const [OpenNotifModal, setOpenNotifModal] = useState(false)
    const [filterLoading, setFilterLoading] = useState(false)
    const [fileType, setFileType] = useState('')

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    // console.log(response.data?.data);
                    setCompleteData(response.data?.data)
                    let signatureUserType = response?.data?.data?.signature

                    // Cari posisi terakhir dari titik
                    const lastDotIndex = signatureUserType.lastIndexOf('.');
                    // console.log('lastDotIndex',lastDotIndex);

                    // Ambil bagian string setelah titik terakhir
                    const extension = signatureUserType.substring(lastDotIndex + 1);
                    // console.log('123',extension);    
                    setFileType(extension)
                    setFilterLoading(false)
                }).catch((error) => {
                    console.log(error.response);
                    setFilterLoading(false)
                })
        }

    }

    const handleLoginSession = () => {
        const loginSessionExp = localStorage.getItem('LogInfo')
        const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
        if (res) {
            setFilterLoading(false)
            setOpenLoginSessionModal(true)
            localStorage.removeItem('LogInfo')
        }
    }

    const handleCloseLoginSessionModal = () => {
        setOpenLoginSessionModal(false)
        navigate('/login');
    }

    const getDataAgreement = async () => {
        await axios.get(agreementQuestion)
            .then((response) => {
                // console.log(response.data);
                setCategoryBeasiswa(response.data[0].category_name)
                setListAgreement(response.data[0].descriptions)
            }).catch((error) => {
                console.log(error.response);
            })
    }

    useEffect(() => {
        const dataLogin = localStorage.getItem('LogInfo')
        setFilterLoading(true)
        if (dataLogin) {
            handleLoginSession()
            getData()
            getDataAgreement()
        } else if (!dataLogin) {
            setOpenLoginSessionModal(true)
            setTimeout(() => {
                navigate('/login');
            }, 9000);
        }

    }, [])

    // const handleShowPDF = (currentFile, imageUrl) => {
    //     const fileExtension = currentFile ? currentFile.type.split('/').pop().toLowerCase() : ''
    //     console.log('handleShowPDF', currentFile);

    //     return (
    //         // Display PDF and download button
    //         fileExtension === 'pdf' ?
    //             <Button
    //                 sx={{
    //                     display: 'flex',
    //                     flexDirection: 'column',
    //                     justifyContent: 'center',
    //                     alignItems: 'center',
    //                     textTransform: 'none',
    //                     gap: '10px',

    //                     // backgroundColor:'orange'
    //                 }}
    //             >
    //                 <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
    //                 <Typography
    //                     sx={{
    //                         fontFamily: 'Plus Jakarta Sans',
    //                         color: mainColor,
    //                         fontSize: '11px',
    //                         fonStyle: 'normal',
    //                         fontWeight: 'bold',
    //                         lineHeight: 'normal',
    //                         cursor: 'pointer',
    //                         textAlign: 'center',
    //                         marginTop: '-10px'
    //                     }}
    //                 >
    //                     {currentFile.name}
    //                 </Typography>
    //             </Button>
    //             :
    //             <img
    //                 src={imageUrl}
    //                 alt="selected-image"
    //                 style={{
    //                     width: '100%',
    //                     height: '100%',
    //                     objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
    //                     borderRadius: '4px',
    //                 }}
    //             />

    //     )
    // }


    const handleShowImage = (currentImage) => {
        // console.log('currentImage show image', currentImage);

        var image = `${url}${currentImage}`
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''
        // console.log('tesadsad', fileExtension);

        // Extract filename from the URL
        const filename = currentImage ? currentImage.split('/').pop() : ''
        // console.log('tes', filename);

        let desiredString = ''
        if (fileExtension === 'pdf') {
            // Extract everything after the last underscore in the filename
            const lastUnderscoreIndex = filename.lastIndexOf('_');
            desiredString = lastUnderscoreIndex !== -1 ? filename.slice(lastUnderscoreIndex + 1) : '';
        }

        return (
            fileExtension === 'pdf' ?
                // Display PDF and download button
                <>
                    <a href={image} download={image}>
                        <Icon icon='bxs:file-pdf' style={{ fontSize: '80px', color: mainColor, marginTop: '-10px', cursor: 'pointer' }} />
                        {/* <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: mainColor,
                                fontSize: '11px',
                                fonStyle: 'normal',
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                                cursor: 'pointer',
                                textAlign: 'center',
                                marginTop: '-10px',
                                textDecoration: 'none'
                            }}
                        >
                            {desiredString ? desiredString : ''}
                        </Typography> */}
                    </a>
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: 'black',
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            textAlign: 'center',
                            marginTop: '-10px',
                        }}
                    >
                        {completeData.full_name ? completeData.full_name : ''}
                    </Typography>
                </>
                :
                <>
                    <img
                        src={image}
                        alt="show-image"
                        style={{
                            width: '100%',
                            height: '80px',
                            objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                            borderRadius: '4px',
                        }}
                    />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: 'black',
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            textAlign: 'center',
                            // marginTop: '5px'
                        }}
                    >
                        {completeData.full_name ? completeData.full_name : ''}
                    </Typography>
                </>
        )
    }

    return (
        <>
            <TopMenu />
            <Grid
                container
                spacing={2}
                sx={{
                    padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
                    backgroundColor: bgColor,
                    paddingBottom: '52px',
                    height: '100%'
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        margin: isMobile || isTablet ? '10px 0px' : '10px 0px 0px 0px',
                        marginBottom: '-10px'
                    }}
                >
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            if (completeData?.answer_1 && completeData?.answer_2) {
                                setOpenUbahDataModal(true)
                            } else {
                                setOpenNotifModal(true)
                            }
                        }}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: 'white',
                            backgroundColor: mainColor,
                            textTransform: 'capitalize',
                            '&:hover': {
                                backgroundColor: mainColor,
                            }
                        }}
                    >
                        Ubah Data
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // justifyContent: isMobile || isTablet ? 'center' : 'flex-start',
                        // alignItems: 'center',
                        margin: isMobile || isTablet ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Card
                        sx={{

                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                padding: '16px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '14px',
                                    textAlign: 'justify',
                                    lineHeight: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    marginBottom: '5px',
                                }}
                            >
                                Kenapa saudara(i) adalah kandidat yang tepat untuk menerima beasiswa atau bantuan ini? {<span style={{ color: red[700] }}>*</span>}
                            </Typography>
                            <Box
                                sx={{
                                    // backgroundColor: 'orange',
                                    maxWidth: isMobile ? '100%' : '600px',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#1E222F',
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '14px',
                                        textAlign: 'justify',
                                        lineHeight: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        wordBreak: 'break-word', // Menambahkan word break agar kata yang panjang bisa dipotong ke baris baru
                                        whiteSpace: 'normal', // Memastikan teks membungkus sesuai kebutuhan
                                        border: `${DividerColor} 1px solid`,
                                        borderRadius: '5px',
                                        padding: '5px 8px',
                                    }}
                                >
                                    {completeData && completeData.answer_1 ? completeData.answer_1 : ''}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                padding: '16px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginBottom: '5px',
                                    fontSize: '14px',
                                    textAlign: 'justify',
                                    lineHeight: '20px',
                                }}
                            >
                                Apa yang hendak saudara(i) kontribusikan untuk Papua Tengah setelah menyelesaikan kewajiban studi saudara(i) jika terpilih? {<span style={{ color: red[700] }}>*</span>}
                            </Typography>
                            <Box
                                sx={{
                                    // backgroundColor: 'orange',
                                    maxWidth: isMobile ? '100%' : '600px',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#1E222F',
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '14px',
                                        textAlign: 'justify',
                                        lineHeight: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        wordBreak: 'break-word', // Menambahkan word break agar kata yang panjang bisa dipotong ke baris baru
                                        whiteSpace: 'normal', // Memastikan teks membungkus sesuai kebutuhan
                                        border: `${DividerColor} 1px solid`,
                                        borderRadius: '5px',
                                        padding: '5px 8px',
                                    }}
                                >
                                    {completeData && completeData.answer_2 ? completeData.answer_2 : ''}
                                </Typography>
                            </Box>

                        </Box>
                        <Divider
                            variant="string"
                            style={{
                                backgroundColor: "#fff",
                                borderBottomWidth: 0.5,
                                // marginTop: "5px",
                            }}
                        />
                        <Box
                            sx={{
                                padding: '16px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    marginBottom: '10px'
                                }}
                            >
                                Dengan ini saya menyetujui, menyatakan dan menerima beberapa poin berikut ini:
                            </Typography>
                            <Box
                                sx={{
                                    marginTop: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: '20px'
                                }}
                            >
                                {listAgreement && listAgreement.map((data, index) => {
                                    // console.log('tes', data);
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '22px',
                                                    marginBottom: '5px',
                                                    textAlign: 'justify'
                                                }}
                                            >
                                                {data.number}.
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '22px',
                                                    marginBottom: '5px',
                                                    textAlign: 'justify'
                                                }}
                                            >
                                                {data.description}
                                            </Typography>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Divider
                                variant="string"
                                style={{
                                    backgroundColor: "#fff",
                                    borderBottomWidth: 0.5,
                                    // marginTop: "2px",
                                }}
                            />
                        </Box>

                        {/* Signature */}
                        <Grid
                            container
                            // spacing={2}
                            xs={12}
                            md={12}
                            sm={12}
                            sx={{
                                padding: isMobile ? '15px 30px' : '20px 30px',
                                // backgroundColor:'orange'
                            }}
                        >
                            <Grid item xs={12} md={12} sm={12}>
                                <Typography
                                    sx={{
                                        maxWidth: isMobile ? '100%' : '286px',
                                        width: isMobile ? '100%' : '85%',
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        marginBottom: '5px'
                                        // backgroundColor:'blue'
                                    }}
                                >
                                    Tanda Tangan Anda
                                </Typography>
                                <Box
                                    sx={{
                                        maxWidth: isMobile ? '100%' : '286px',
                                        width: isMobile ? '100%' : '85%',
                                        overflow: 'hidden',
                                        height: '110px',
                                        backgroundColor: '#fff',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        position: 'relative',
                                        // border: `solid 2px   ${DividerColor}`,
                                        // borderStyle: 'dashed',
                                        // backgroundColor: 'orange'
                                    }}
                                >
                                    {completeData && completeData.signature ? (
                                        handleShowImage(completeData.signature)
                                    ) : (
                                        undefined
                                    )}
                                </Box>
                            </Grid>

                            {/* <Grid item xs={12} md={6} sm={12}
                                sx={{
                                    // backgroundColor: 'blue',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    margin: isMobile ? '50px 0px 20px 0px' : undefined
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: isMobile ? '100%' : '286px',
                                        width: isMobile ? '100%' : '85%',
                                        backgroundColor: '#fff',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '2px'
                                        // backgroundColor:'orange'
                                    }}
                                >
                                    <QRCode
                                        value='testing'
                                        size={200}
                                        style={{
                                            height: "95px",
                                            maxWidth: "95px",
                                            width: "95px",
                                            marginBottom: '5px'
                                            // padding: '10px',
                                            // border: 'solid 2px #D9D9D9',
                                            // backgroundColor: brandColor,
                                            // borderRadius: '10px',
                                        }}
                                        viewBox={`0 0 256 256`}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            color: Dark,
                                        }}
                                    >
                                        Darrent Watts
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            color: Dark
                                        }}
                                    >
                                        {moment(new Date()).format('DD-MMMM-YYYY')}
                                    </Typography>
                                </Box>
                            </Grid> */}
                        </Grid>

                        {/* <Box
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Divider
                                variant="string"
                                style={{
                                    backgroundColor: "#fff",
                                    borderBottomWidth: 0.5,
                                    // marginTop: "2px",
                                }}
                            />
                        </Box> */}

                        {/* <Box
                            sx={{
                                padding: '5px 10px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '22px',
                                    textAlign: 'justify',
                                    color: 'green'
                                }}
                            >
                                Telah Disetujui
                            </Typography>
                        </Box> */}
                    </Card>
                </Grid>
            </Grid>
            {
                filterLoading && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 2000
                        }}
                    >
                        <Spin size="large" />
                    </Box>
                )
            }
            <LoginSessionModal
                open={openLoginSessionModal}
                close={handleCloseLoginSessionModal}
            />
            <UbahDataAgreement
                open={openUbahDataModal}
                close={() => setOpenUbahDataModal(false)}
                getData={() => getData()}
                completeData={completeData}
            />
            <NotificationAgreement
                open={OpenNotifModal}
                close={() => setOpenNotifModal(false)}
                pageLoadingTrue={() => setFilterLoading(true)}
                pageLoadingFalse={() => setFilterLoading(false)}
            />
        </>
    )
}

export default Agreement