import React, { useEffect, useState } from 'react'
import TopMenu from '../../components/Header/TopMenu'
import { Card, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { Dark, DividerColor, Maroon, TextGray, White, bgColor, mainColor } from '../../components/Config/Color';
import fileEmpty from '../../assets/Icon/file_empty.png'
import LoginSessionModal from '../../components/Session/LoginSessionModal';
import { useNavigate } from 'react-router-dom';
import { getDataDashboardApplicant, getDataRiwayat } from '../../components/Config/Api';
import axios from 'axios';
import { FormatCurrency } from '../../components/FormatCurrency/FormatCurrency';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../components/UppercaseCharacter/CapitalizeFirstLetter';
import { Icon } from '@iconify/react/dist/iconify.js';
import PassportAndVisa from '../../components/Dasboard/BeasiswaSUP/PassportAndVisa';
import AdmissionDocument from '../../components/Dasboard/BeasiswaSUP/AdmissionDocument';
import DataAkademik from '../../components/Dasboard/BeasiswaSUP/DataAkademik';
import BiayaPendidikan from '../../components/Dasboard/BeasiswaSUP/BiayaPendidikan';

const BeasiswaSUP = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const navigate = useNavigate()

  const [dataRiwayatTransaksi, setDataRiwayatTransaksi] = useState([])
  const [completeData, setCompleteData] = useState(null)
  const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)

  const [dataCompletenessPassportAndVisa, setDataCompletenessPassportAndVisa] = useState(0)
  const [dataCompletenessAdmissionDocument, setDataCompletenessAdmissionDocument] = useState(0)
  const [dataCompletenessDataAkademik, setDataCompletenessDataAkademik] = useState(0)
  const [dataCompletenessBiayaPendidikanAndHidup, setDataCompletenessBiayaPendidikanAndHidup] = useState(0)

  const getData = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email


      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {
          // console.log('complete data', response.data?.data);

          setCompleteData(response.data?.data)

          //Validasi data passport & visa
          var PassportAndVisa =
          {
            no_passport: response.data?.data?.no_passport,
            no_visa: response.data?.data?.no_visa,
            scan_passport: response.data?.data?.scan_passport,
            scan_visa: response.data?.data?.scan_visa,
            scan_study_permit: response.data?.data?.scan_study_permit,
          }

          const passportAndVisaKeys = [
            'no_passport',
            'no_visa',
            'scan_passport',
            'scan_visa',
            'scan_study_permit',
          ];
          const passportAndVisaCompleteness = calculateCompleteness(PassportAndVisa, passportAndVisaKeys);
          setDataCompletenessPassportAndVisa(passportAndVisaCompleteness);


          //Validasi data admission document
          var AdmissionDocument =
          {
            LOA: response.data?.data?.LOA,
          }

          const AdmissionDocumentKeys = [
            'LOA',
          ];
          const AdmissionDocumentCompleteness = calculateCompleteness(AdmissionDocument, AdmissionDocumentKeys);
          setDataCompletenessAdmissionDocument(AdmissionDocumentCompleteness);


          var dataAkademik =
          {
            gpa: response.data?.data?.gpa,
            gpa_scale: response.data?.data?.gpa_scale,
            khs: response.data?.data?.khs,
            krs: response.data?.data?.krs,
          }

          const dataAkademikKeys = [
            'gpa',
            'gpa_scale',
            'khs',
            'krs',
          ];
          const dataAkademikCompleteness = calculateCompleteness(dataAkademik, dataAkademikKeys);
          setDataCompletenessDataAkademik(dataAkademikCompleteness);
          
          //Validasi data biaya pendidikan & hidup
          var biayaPendidikanAndHidup =
          {
            no_passport: response.data?.data?.no_passport,
            no_visa: response.data?.data?.no_visa,
            scan_passport: response.data?.data?.scan_passport,
            scan_visa: response.data?.data?.scan_visa,
            scan_study_permit: response.data?.data?.scan_study_permit,
          }

          const biayaPendidikanAndHidupKeys = [
            'no_passport',
            'no_visa',
            'scan_passport',
            'scan_visa',
            'scan_study_permit',
          ];
          const biayaPendidikanAndHidupCompleteness = calculateCompleteness(biayaPendidikanAndHidup, biayaPendidikanAndHidupKeys);
          setDataCompletenessBiayaPendidikanAndHidup(biayaPendidikanAndHidupCompleteness);

        }).catch((error) => {
          console.log(error.response);
        })
    }
  }

  const handleLoginSession = () => {
    const loginSessionExp = localStorage.getItem('LogInfo')
    const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
    if (res) {
      setOpenLoginSessionModal(true)
      localStorage.removeItem('LogInfo')
    }
  }

  const handleCloseLoginSessionModal = () => {
    setOpenLoginSessionModal(false)
    navigate('/login');
  }

  useEffect(() => {
    const dataLogin = localStorage.getItem('LogInfo')
    if (dataLogin) {
      handleLoginSession()
      getData()
    } else if (!dataLogin) {
      setOpenLoginSessionModal(true)
      setTimeout(() => {
        navigate('/login');
      }, 9000);
    }

  }, [])

  const calculateCompleteness = (data, keys) => {
    // console.log('keys', keys);
    // console.log('data', data);
    const totalDataPoints = keys.length;
    const filledDataPoints = keys.filter(key => data[key] !== null && data[key] !== undefined).length;
    const completenessPercentage = (filledDataPoints / totalDataPoints) * 100;
    return completenessPercentage.toFixed(4);
  };

  return (
    <>
      <TopMenu />
      <Grid
        container
        spacing={2}
        sx={{
          padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
          backgroundColor: 'bgColor',
          paddingBottom: '52px',
          height: '100%',
          // display: 'flex',
          // justifyContent: 'center', // Mengatur konten secara horizontal ke tengah
          // alignItems: 'center',
          // textAlign:'center'
        }}
      >
        {/* 
        {
          Math.round(dataCompletenessPekerjaanOrangTua) &&
            Math.round(dataCompleteness) &&
            Math.round(dataCompletenessKontak) &&
            Math.round(dataCompletenessKontak) >= 100 ?
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
                // alignItems: 'center',
                margin: isMobile ? '0px' : '10px 0px 0px 0px',
              }}
            >

              <Card
                elevation={5}
                sx={{
                  display: 'none',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                  alignItems: 'center',
                  backgroundColor: Maroon,
                  padding: '10px',
                  gap: '10px',
                  maxWidth: '872px',
                  maxHeight: isMobile ? 'auto' : '44px',
                  // width:'100%'
                  // margin:'10px 0px'
                }}
              >
                <Icon icon="ph:x-circle-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: White,
                    fontSize: isMobile ? '13px' : '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '22px',
                    textAlign: isMobile ? 'center' : 'unset'
                  }}
                >

                </Typography>
              </Card>
            </Grid>
            : */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '15px 0px 10px 0px' : '10px 0px 0px 0px',
          }}
        >

          {/* Notif Berkas Belum Lengkap */}
          <Card
            elevation={5}
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: isMobile ? 'center' : 'flex-start',
              alignItems: 'center',
              backgroundColor: Maroon,
              padding: '10px',
              gap: '10px',
              maxWidth: '872px',
              maxHeight: isMobile ? 'auto' : '44px',
              // width:'100%'
              // margin:'10px 0px'
            }}
          >
            <Icon icon="ph:x-circle-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
            <Typography
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                color: White,
                fontSize: isMobile ? '13px' : '14px',
                fonStyle: 'normal',
                fontWeight: '500',
                lineHeight: '22px',
                textAlign: isMobile ? 'center' : 'unset'
              }}
            >
              Berkas persyaratan Beasiswa SUP anda belum lengkap, segera lengkapi sebelum 1 November 2023
            </Typography>
          </Card>
        </Grid>
        {/* } */}

        {/* Column 1 */}
        {/* Passport & Visa */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            // backgroundColor: 'orange'
          }}
        >
          <PassportAndVisa
            completeData={completeData}
            handleExpired={() => handleLoginSession()}
            dataCompletenessPassportAndVisa={dataCompletenessPassportAndVisa}
          />
        </Grid>

        {/* Column 2*/}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            // backgroundColor: 'orange'
          }}
        >

          {/* Admission Document */}
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              // backgroundColor: 'blue'
              marginBottom: '20px'
            }}
          >
            <AdmissionDocument
              completeData={completeData}
              handleExpired={() => handleLoginSession()}
              dataCompletenessAdmissionDocument={dataCompletenessAdmissionDocument}
            />
          </Grid>

          {/* Admission Document */}
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              // backgroundColor: 'blue'
            }}
          >
            <DataAkademik
              completeData={completeData}
              handleExpired={() => handleLoginSession()}
              dataCompletenessDataAkademik={dataCompletenessDataAkademik}
            />
          </Grid>
        </Grid>

        {/* Biaya Pendidikan & Hidup */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            // backgroundColor: 'blue'
          }}
        >
          <BiayaPendidikan
            completeData={completeData}
            handleExpired={() => handleLoginSession()}
            dataCompletenessBiayaPendidikanAndHidup={dataCompletenessBiayaPendidikanAndHidup}
          />
        </Grid>
      </Grid >
      <LoginSessionModal
        open={openLoginSessionModal}
        close={handleCloseLoginSessionModal}
      />
    </>
  )
}

export default BeasiswaSUP