import React, { useEffect, useRef, useState } from 'react';
import { Grid, useMediaQuery, Typography, Box, Button, Card, TextField, Tooltip, IconButton, ClickAwayListener, InputAdornment, Popper, Paper, Grow } from '@mui/material';
import { Icon } from '@iconify/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import LogoGoogle from '../../assets/Logo/Google_Logo.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserPersonalInformation, setNewPassword, userLogin } from '../../components/Config/Api';
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert';
import Top from '../../components/Register/Top/Top';
import Footer from '../../components/Register/Footer/Footer';
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert';
import Notification from '../../components/Modal/Notification/Notification';

const ResetPassword = () => {

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingLoginPage, setIsLoadingLoginPage] = useState(false)
    const [newData, setNewData] = useState({
        password: '',
        konfirmasiPassword: ''
    })
    const [showPassword, setShowPassword] = useState(false);
    const [showKonfimasiPassword, setShowKonfirmasiPassword] = useState(false);
    const [statusEmail, setStatusEmail] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [konfirmasiPassword, setKonfirmasiPassword] = useState('');

    const hasUpperCase = /[A-Z]/
    const hasLowerCase = /[a-z]/
    const hasSymbol = /\W/

    const handleClickLoginPage = () => {
        setIsLoadingLoginPage(true)
        setTimeout(() => {
            setIsLoadingLoginPage(false)
            navigate('/login')
        }, 1500);
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const dataResetPassword = localStorage.getItem('rp')

        if (
            dataResetPassword &&
            newData.password === newData.konfirmasiPassword &&
            hasUpperCase.test(newData.password) &&
            hasLowerCase.test(newData.password) &&
            hasSymbol.test(newData.password)
        ) {
            const verfication_password_id = JSON.parse(dataResetPassword).verfication_password_id
            await axios.put(`${setNewPassword}/${verfication_password_id}`, {
                password: newData.password,
                password2: newData.konfirmasiPassword
            }).then((response) => {
                // console.log(response.data);
                SuccessAlert({
                    message: 'Berhasil mengubah password',
                    position: 'top-center'
                })
                localStorage.removeItem('rp')
                setTimeout(() => {
                    navigate('/login')
                    setIsLoading(false)
                }, 3000);
            }).catch((error) => {
                console.log(error.response);
                if (error?.response?.data?.messages?.password) {
                    ErrorAlert({
                        message: 'Password minimal 8 karakter',
                        position: 'top-center'
                    })
                }
                setIsLoading(false)
            })

        } else if (!hasUpperCase.test(newData.password)) {
            ErrorAlert({
                message: 'Kata sandi harus mengandung setidaknya satu huruf besar.',
                position: 'top-center',
            });
            setIsLoading(false)
        } else if (!hasLowerCase.test(newData.password)) {
            ErrorAlert({
                message: 'Kata sandi harus mengandung setidaknya satu huruf kecil.',
                position: 'top-center',
            });
            setIsLoading(false)
        } else if (!hasSymbol.test(newData.password)) {
            ErrorAlert({
                message: 'Kata sandi harus mengandung setidaknya satu simbol.',
                position: 'top-center',
            });
            setIsLoading(false)
        } else if (newData.password !== newData.konfirmasiPassword) {
            ErrorAlert({
                message: 'Konfirmasi password tidak sesuai',
                position: 'top-center',
            });
            setIsLoading(false)
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });

        if (name === 'password') {
            // Validasi password
            if (value !== '') {
                // Cek apakah password memiliki huruf besar, huruf kecil, dan simbol

                // Jika password tidak memenuhi semua syarat, set error
                if (!hasUpperCase.test(value)) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu huruf besar');
                    if (newData.konfirmasiPassword !== '' && newData.password !== value) {
                        setKonfirmasiPassword('Konfirmasi password tidak sesuai.');
                    }
                } else if (!hasLowerCase.test(value)) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu huruf kecil');
                    if (newData.konfirmasiPassword !== '' && newData.password !== value) {
                        setKonfirmasiPassword('Konfirmasi password tidak sesuai.');
                    }
                } else if (!hasSymbol.test(value)) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu simbol');
                    if (newData.konfirmasiPassword !== '' && newData.password !== value) {
                        setKonfirmasiPassword('Konfirmasi password tidak sesuai.');
                    }
                } else {
                    // Jika password memenuhi semua syarat, hapus error
                    setPasswordError('');
                    setKonfirmasiPassword('');
                }
            } else {
                // Jika password kosong, hapus error
                setPasswordError('');
                if (value !== '' && newData.password !== value) {
                    setKonfirmasiPassword('Konfirmasi password tidak sesuai.');
                } else {
                    setKonfirmasiPassword('');
                }
            }
        } else if (name === 'konfirmasiPassword' || name === 'password') {
            if (value !== '' && newData.password !== value) {
                setKonfirmasiPassword('Konfirmasi password tidak sesuai.');
            } else {
                setKonfirmasiPassword('');
            }
        }

    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowKonfirmasiPassword = () => {
        setShowKonfirmasiPassword(!showKonfimasiPassword);
    };

    return (
        <>
            <Top />
            <Grid
                container
                sx={{
                    backgroundColor: bgColor,
                    padding: isMobile ? '7px 15px' : isTablet ? '7px 20px' : '7px 83px',
                    height: 'auto', // Change height to auto
                    minHeight: 'calc(100vh - 300px)', // Ensure content takes at least the full viewport height minus header/footer
                    overflow: 'hidden',
                    // backgroundColor:' orange',
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: isMobile ? '8%' : '10%', // Adjust height based on screen size
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: isMobile ? '13px' : isTablet ? '13px' : '23px',
                        // backgroundColor:' orange'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // gap: '10px'
                        }}
                    >
                        <Button
                            onClick={() => {
                                window.location.href = 'https://beasiswapapuatengah.id'
                            }}
                            sx={{
                                textTransform: 'none',
                                color: 'black',
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: bgColor
                                },
                            }}
                        >
                            <Icon icon='material-symbols:arrow-back' />
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '13px',
                                    fontWeight: '400',
                                    lineHeight: '20.16px',
                                    cursor: 'pointer',
                                    marginLeft: '10px'
                                }}
                            >
                                Kembali ke Beranda
                            </Typography>
                        </Button>
                    </Box>
                    <Button
                        onClick={handleClickLoginPage}
                        variant='contained'
                        size='small'
                        sx={{
                            minWidth: '150px',
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            padding: isMobile ? '0x 10px' : undefined,
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor
                            },
                        }}
                    >
                        {isLoadingLoginPage ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px', color: White }} />
                            :
                            'Masuk Akun'
                        }
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        padding: isMobile ? '15px' : undefined,
                    }}
                >
                    <form onSubmit={handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '58px',
                            width: '100%',
                            //    backgroundColor:'orange'
                        }}
                    >
                        <Paper
                            elevation={2}
                            sx={{
                                maxWidth: '500px',
                                width: '100%',
                                marginBottom: isMobile ? '27px' : '50px',
                                padding: '20px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '22px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    Buat Password Baru
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // maxWidth: '400px',
                                    // backgroundColor: 'orange',
                                    width: isMobile ? '100%' : isTablet ? '50%' : '30%',
                                    width: '100%',
                                    marginTop: '30px',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type={showPassword ? 'text' : 'password'}
                                    label="Kata Sandi"
                                    placeholder='Masukkan kata sandi'
                                    value={newData.password}
                                    name="password"
                                    onChange={(e) => handleChange(e)}
                                    autoComplete="off"
                                    error={newData.password === '' ? undefined : !!passwordError}
                                    helperText={passwordError}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon
                                                    onClick={handleClickShowPassword}
                                                    icon={showPassword ? 'streamline:eye-optic' : 'humbleicons:eye-close'}
                                                    // onClick={() => setOpenPopperNIK(true)}
                                                    style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                // ref={anchorRefNIK}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        marginBottom: isMobile ? '24px' : '30px',
                                        fontSize: isMobile ? '14px' : '13px',
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "white",
                                        },
                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                            "& fieldset": {
                                                borderBottomColor: mainColor,
                                            },
                                        },
                                        "& .MuiInputLabel-root.Mui-disabled": {
                                            color: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: '#B3B3D4',
                                            fontWeight: 'bold',
                                            fontSize: '13px'
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: mainColor,
                                        },
                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                            color: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:after": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:after": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                            "-webkit-appearance": "none",
                                            margin: 0,
                                        },
                                        "& input[type=number]": {
                                            "-moz-appearance": "textfield",
                                        },
                                    }}
                                    inputProps={{
                                        maxLength: 20, // maksimal 20 karakter
                                        minLength: 4, // minimal 8 karakter
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type={showKonfimasiPassword ? 'text' : 'password'}
                                    label="Konfimasi Kata Sandi"
                                    placeholder='Masukkan kata sandi'
                                    value={newData.konfirmasiPassword}
                                    name="konfirmasiPassword"
                                    onChange={(e) => handleChange(e)}
                                    autoComplete="off"
                                    error={newData.konfirmasiPassword === '' ? undefined : !!konfirmasiPassword}
                                    helperText={konfirmasiPassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon
                                                    onClick={handleClickShowKonfirmasiPassword}
                                                    icon={showKonfimasiPassword ? 'streamline:eye-optic' : 'humbleicons:eye-close'}
                                                    // onClick={() => setOpenPopperNIK(true)}
                                                    style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                // ref={anchorRefNIK}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                    sx={{
                                        marginBottom: isMobile ? '10px' : '30px',
                                        fontSize: isMobile ? '14px' : '13px',
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "white",
                                        },
                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                            "& fieldset": {
                                                borderBottomColor: mainColor,
                                            },
                                        },
                                        "& .MuiInputLabel-root.Mui-disabled": {
                                            color: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: '#B3B3D4',
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: mainColor,
                                        },
                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                            color: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:after": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:after": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                            "-webkit-appearance": "none",
                                            margin: 0,
                                        },
                                        "& input[type=number]": {
                                            "-moz-appearance": "textfield",
                                        },
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: '14px',
                                    // marginBottom: 'px',
                                }}
                            >
                                <Button
                                    fullWidth
                                    variant='contained'
                                    size='medium'
                                    type='submit'
                                    // onClick={() => handleSubmit()}
                                    sx={{
                                        fontSize: isMobile ? '12px' : undefined,
                                        backgroundColor: btnBgColor,
                                        color: mainColor,
                                        padding: '5px 0px',
                                        textTransform: 'none',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            color: 'white', // Change text color to white on hover
                                            backgroundColor: mainColor,
                                            // fontWeight:'bold',
                                        },
                                    }}
                                    disabled={isLoading ? true : false}
                                >
                                    {isLoading ?
                                        <Icon icon='svg-spinners:tadpole' style={{ fontSize: isMobile ? '21px' : '24.5px' }} />
                                        :
                                        'Simpan Data'
                                    }
                                </Button>
                            </Box>
                        </Paper>
                    </form>
                </Grid>
            </Grid >
            <Footer />
            <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
        </>
    )
}

export default ResetPassword