const Base64ToFile = (base64String, fileName) => {
    if (base64String && fileName) {
        const [header, data] = base64String.split(',');
        const mime = header.match(/:(.*?);/)[1];
        const binary = atob(data);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        const uint8Array = new Uint8Array(array);
        return new File([uint8Array], fileName, { type: mime });
    };
}

export default Base64ToFile